<template>
  <!--轉帳匯款：虛擬帳號  結果-->
  <div>
      <div class="payment grey-bg" style="padding-top: 1px; position: relative; z-index: 9;" ref="content" v-loading="mainLoading">
        <div class="container box">
          <el-row class="txt-cen">
            <el-col>
              <el-image class="top-logo" :src="successIconImgUrl"></el-image>
            </el-col>
          </el-row>
          <el-row class="m-top-20 txt-cen">
            <el-col>
              <span class="top-txt">訂單開立成功</span>
            </el-col>
          </el-row>
          <el-row  class="m-top-15 txt-cen">
            <el-col>
              <span class="top-txt">Order Success!</span>
            </el-col>
          </el-row>
          <el-row  class="m-top-25 txt-cen">
            <el-col>
              <span class="transactionAmount">NT <span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></span>
            </el-col>
          </el-row>
          <el-row class="m-top-20 txt-cen">
            <el-col>
              <el-image class="line" :src="lineImgUrl"></el-image>
            </el-col>
          </el-row>
            <div class="account-box">
              <div class="account-title">
                  銀行名稱<span>Bank Name</span>
              </div>
              <div class="account-value">{{dataForm.bankName}}</div>
            </div>
            <div class="account-box">
              <div class="account-title">
                  銀行代碼<span>Bank Code</span>
              </div>
              <div class="account-value">{{dataForm.bankCode}}</div>
            </div>
            <div class="account-box">
              <div class="account-title">
                  虛擬帳號<span>Transfer Account No.</span>
              </div>
              <div class="account-value">{{dataForm.accountNo}}</div>
            </div>
            <div class="account-box">
              <div class="account-title">
                  繳款截止時間<span>Expiry Time</span>
              </div>
              <div class="account-value" style="color: #121212">{{dataForm.date}}</div>
            </div>



          <el-row class="txt-cen m-top-20">
            <el-col>
              <el-image  class="line" :src="lineImgUrl"></el-image>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between" class="m-top-20">
            <el-col :span="12">
              <span class="bot-txt-g">特店名稱 Merchant Name</span>
            </el-col>
            <el-col :span="12" class="txt-end">
              <span class="bot-txt-b">{{dataForm.businessName}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between" class="m-top-20" >
            <el-col :span="12">
              <span class="bot-txt-g">紅陽交易編號 Sunpay Transaction No.</span>
            </el-col>
            <el-col :span="12" class="txt-end">
              <span class="bot-txt-b">{{dataForm.orderId}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between" class="m-top-20" >
            <el-col :span="12">
              <span class="bot-txt-g">特店訂單編號 Merchant Order No.</span>
            </el-col>
            <el-col :span="12" class="txt-end">
              <span class="bot-txt-b">{{dataForm.merchantOrderId}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between" class="m-top-20" >
            <el-col :span="12">
              <span class="bot-txt-g">交易時間 Payment Time</span>
            </el-col>
            <el-col :span="12" class="txt-end">
              <span class="bot-txt-b">{{dataForm.createOrderTime}}</span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between" class="m-top-20">
            <el-col :span="12">
              <span class="bot-txt-g">交易金額 Payment Amount</span>
            </el-col>
            <el-col :span="12" class="txt-end">
              <span class="bot-txt-b">NT<span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></span>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between" class="m-top-20" v-if = 'dataForm.storeAddress'>
            <el-col :span="12">
              <span class="bot-txt-g">取貨門市地址 Store Address</span>
            </el-col>
            <el-col :span="12" class="txt-end">
              <span class="bot-txt-b">{{dataForm.storeAddress}}</span>
            </el-col>
          </el-row>
<!--          <el-row type="flex" justify="space-between" class="m-top-20" >-->
<!--            <el-col :span="12">-->
<!--              <span class="bot-txt-g">寄件代碼 Shipping code</span>-->
<!--            </el-col>-->
<!--            <el-col :span="12" class="txt-end">-->
<!--              <span class="bot-txt-b">{{dataForm.shippingCode}}</span>-->
<!--            </el-col>-->
<!--          </el-row>-->

          <el-row class="txt-cen m-top-20">
            <el-col>
              <el-image class="line" :src="sLineImgUrl"></el-image>
            </el-col>
          </el-row>
          <!-- 服務條款 -->
          <div class="notice">
            <div class="box-txt-bl-s">注意事項 Notice</div>
            <div class="box-txt-bl-s">一、請紀錄您的繳費資訊，並於繳費期限內完成繳費，超過繳費時間該虛擬帳號將會失效</div>
            <div class="box-txt-bl-w">Please remember your payment information and complete the payment within the expiry time, otherwise, the transfer account number will become invalid.</div>
            <div class="box-txt-bl-s">二、您可至全台任一ATM自動櫃員機或透過銀行之網路ATM進行交易轉帳</div>
            <div class="box-txt-bl-w">You can use ATM machines or Online Banking to complete the transfer.</div>
            <div class="box-txt-rd">請主動求證付款資訊來源，切勿將個人資料提供給來路不明的第三方，若發現有不明管道之訊息可撥打165反詐騙諮詢專線。</div>
            <div class="box-txt-rd-w">Please proactively verify the source of payment information and refrain from providing personal information to unknown third parties. If you suspect you could be victim of a scam, protect your rights by calling the “165 Anti-Scam hotline”.</div>
          </div>


          <div class="txt-cen">
            <el-button type="danger"  class="next-btn" @click="showDoc">
              <template>
                <!-- <el-image class="img" :src="showDoc"></el-image> -->
                <el-image class="img" :src="imageDownImgUrl"></el-image>
                <span class="txt" >下載繳款單 Payment Slip</span>
              </template>
            </el-button>
          </div>

          <!-- <div class="back-btn" @click="goPage()" style="width: 140px; margin: 20px auto;">回到商店</div> -->

          <el-row style="text-align: center;" class="m-top-40">
            <el-col>
              <el-image style="width: 200px;" :src="sLogImgUrl"></el-image>
            </el-col>
          </el-row>



          <div v-if="false">
            <div class="show-body-inner">
              <div class="show-body-title">虛擬帳號繳費</div>
              <div class="show-body-amount">交易金額(NT$)/Amount：<span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></div>
              <div class="show-body-business">商店名稱/Business Name：{{dataForm.businessName}}</div>
            </div>
            <el-divider class="line"></el-divider>
            <div class="show-body-inner body-sec">
              <div class="show-body-bottom mb-2">繳款期限/Due Date：{{dataForm.date}}</div>
      <!--        <div class="show-body-bottom">交易內容/Trading Details：{{dataForm.orderInfo}}</div>-->
            </div>
            <!--銀行-->
            <div class="show-body-inner body-sec">
              <div class="show-body-bottom mb-2">銀行（分行）名稱/Bank Name：{{dataForm.bankName}}</div>
              <div class="show-body-bottom mb-2">銀行（分行）代碼/Bank Code：{{dataForm.bankCode}}</div>
              <div class="show-body-bottom">繳款帳號/Account No.：{{dataForm.accountNo}}</div>
            </div>
            <div class="show-body-inner show-body-btn mb-6">
              <!--下載-->
              <el-button type="info" @click="showDoc">下載繳款單/Payment Slip</el-button>
              <div class="red font-size-18 mt-3">
                請主動求證付款資訊來源，切勿將個人資料提供給來路不明的第三方，若發現有不明管道之訊息可諮詢165反詐騙專線。
              </div>
            </div>
            <div class="show-body-inner">
              <div class="show-body-back" @click="goPage()" v-if="!global.isEmpty(webSiteUrl)">回到商店</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import storLogoImg from '../../assets/img/new-payment/store-logo.png'
import successIconImg from '../../assets/img/new-payment/success-icon.png'
import lineImg from '../../assets/img/new-payment/line.png'
import sLogImg from '../../assets/img/new-payment/s-logo.png'
import imageDownImg from '../../assets/img/new-payment/image-down.png'
import sLineImg from '../../assets/img/new-payment/s-line.png'

  export default {
    name: 'virtual-account-info',
    data () {
      return {
        mainLoading: false,
        titleIcon: require('../../assets/img/payment/pay-icon-3.png'),
        dataForm: {
          // 交易金額
          transactionAmount: this.$route.query.transactionAmount,
          // 商店名稱
          businessName: '',
          // 繳款期限
          date: '',
          // 交易內容
          orderInfo: this.$route.query.orderInfo,
          // 銀行名稱
          bankName: '',
          // 銀行代碼
          bankCode: '',
          // 繳款帳號
          accountNo: '',
          masterId: this.$route.query.masterId,
		  //取貨門市地址
		  storeAddress:'',
		  //寄件代碼
		  shippingCode:'',
        },
        webSiteUrl: '',
        storLogoImgUrl: storLogoImg,
        successIconImgUrl: successIconImg,
        lineImgUrl: lineImg,
        sLogImgUrl: sLogImg,
        imageDownImgUrl: imageDownImg,
        sLineImgUrl: sLineImg,
      }
    },
    computed: {
      // 跳轉頁面
      routerName: {
        get () {
          return this.$store.state.credit.routerName
        }
      }
    },
    mounted () {
      this.showInfo()
      this.getWebSiteUrl();
    },
    methods: {
      showInfo () {
        this.mainLoading = true
        let newForm = {
          masterId: this.$route.query.masterId
        }
        this.$http({
          url: this.$http.adornUrl(`/order/getOrderByVirtualAccount`),
          method: 'post',
          data: this.$http.adornData(newForm)
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.dataForm.businessName = data.body.merchantName
            this.dataForm.date = data.body.paymentDeadLine
            this.dataForm.bankCode = data.body.bankCode
            this.dataForm.bankName = data.body.accountName
            this.dataForm.accountNo = data.body.virtualAccount
            this.dataForm.merchantOrderId = data.body.merchantOrderId
            this.dataForm.orderId = data.body.orderId
            this.dataForm.createOrderTime = data.body.createOrderTime
			this.dataForm.storeAddress = data.body.storeAddress
			this.dataForm.shippingCode = data.body.shippingCode
          }
          this.mainLoading = false
        })
      },
      showDoc () {
        this.$router.push({
          name: 'transfer-remittance-doc',
          query: {
            masterId: this.dataForm.masterId,
            // 金額
            transactionAmount: this.$route.query.transactionAmount,
            // 商店名稱
            merchantName: this.dataForm.businessName,
            // 繳款期限
            createOrderTime: this.dataForm.date,
            // 付款人
            creditCardUserName: this.$route.query.creditCardUserName,
            // 交易内容
            orderInfo: this.dataForm.orderInfo,
            bankCode: this.dataForm.bankCode,
            virtualAccount: this.dataForm.accountNo
          }
        })
      },
      getWebSiteUrl(){
        this.$http({
          url: this.$http.adornUrl(`/order/getReturnStoreUrl`),
          method: 'get',
          params: this.$http.adornParams({
            orderId: this.$route.query.masterId
          })
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.webSiteUrl = data.body.webSite
          }
        })
      },
      goPage (page) {
        window.location.href = this.webSiteUrl
      },
      captrue() {
        const ref = this.$refs.content
        html2canvas(ref, {
          backgroundColor: '#e8f4ff',
          useCORS: true
        }).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png')
          const creatDom = document.createElement('a')
          document.body.appendChild(creatDom)
          creatDom.href = dataURL
          creatDom.download = 'image'
          creatDom.click()
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/payment.scss";
  .show-body{
    text-align: left!important;
    &-inner{
      width: 700px;
      margin:0 auto;
    }
    &-title{
      font-size:32px;
      color: #8e0303;
      margin-bottom:32px;
    }
    &-amount{
      font-size:32px;
      color: #707070;
      margin-bottom:14px;
      font-weight: bold;
    }
    &-business{
      font-size: 20px;
      color: #707070;
    }
    &-bottom{
      font-size:21px;
      color: #030303;
      margin-bottom:31px;
    }
    &-pcode{
      font-size: 32px;
      color: #030303;
    }
    &-img-box{
      margin-bottom:63px;
    }
    &-img{
      width:40px;
      height:40px;
    }
    &-back{
      font-size: 23px;
      color: #8e0303;
      font-weight: bold;
      border-bottom:2px solid #8e0303;
      width: 97px;
      padding: 2px;
      text-align: center;
      cursor: pointer;
    }
    &-btn{
      .el-button{
        padding: 17px 20px!important;
      }
    }
    .line.el-divider--horizontal {
      margin: 23px 0  53px 0 !important;
    }
    .body-sec{
      margin-bottom:48.7px;
      font-weight:bold;
    }
  }

  .txt-end {
   text-align: end;
  }
  .back-btn {
    font-size: 26px;
    color: #8F0304;
    font-weight: bold;
    border-bottom: 2px solid #8F0304;
    padding: 2px;
    cursor: pointer;
    text-align: center;
  }

  .next-btn {
    background: #8F0304;
    width: 400px;
    max-width: 100%;
    .img {
      width: 30px;
      top: 5px;
    }

    .txt {
      font-size: 26px;
      font-weight: 600;
      line-height: 45px;
      margin-left: 10px;
    }
  }

  .box-txt-bl-s {
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    line-height: 20px;
  }

  .box-txt-bl-w {
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 25px;
  }

  .box-txt-rd {
    font-weight: 600;
    color: #8F0304;
    font-size: 12px;
    line-height: 20px;
  }

  .box-txt-rd-w {
    font-weight: 400;
    color: #8F0304;
    font-size: 12px;
    line-height: 20px;
  }

  .bot-txt-g {
    font-weight: 400;
    font-size: 18px;
    color: #707070;
  }

  .bot-txt-b {
    font-weight: 400;
    font-size: 18px;
    color: #121212;
  }

  .m-top-15 {
    margin-top: 15px;
  }
  .m-top-20 {
    margin-top: 20px;
  }
  .m-top-25 {
    margin-top: 25px;
  }
  .m-top-40 {
    margin-top: 40px;
  }

  .txt-cen {
    text-align: center;
  }

  .left-txt-g {
    font-weight: 600;
    font-size: 26px;
    color: #707070;
  }

  .left-txt-r {
    font-weight: 600;
    font-size: 26px;
    color: #8F0304;
  }

  .left-txt-b {
    font-weight: 600;
    font-size: 26px;
    color: #121212;
  }

  .line {
    width: 100%;
    height: 2px;
    // max-width: 100%;
  }

  .notice {
    border-radius:10px;
    background: #FAF0CA;
    margin: 30px 0;
    padding: 20px;
  }

  .transactionAmount {
    font-weight: 600;
    font-size: 36px;
  }
  .top-txt {
    font-weight: 400;
    font-size: 22px;
  }
  .top-logo {
    width: 100px;
  }
  .w-b {
    padding: 60px 140px !important;
    margin-top: 20px;
    border-radius: 38px;
  }
</style>
