<template>
  <!--超商取貨-->
  <div>
    <div class="payment grey-bg" style="padding-top: 1px;position: relative; z-index: 9;" ref="content" v-loading="mainLoading">
      <div class="container box">
        <!--成功圖示-->
        <el-row style="text-align: center;">
          <el-col>
            <el-image class="top-logo" :src="successIconImgUrl"></el-image>
          </el-col>
        </el-row>
        <!--訂單開立成功-->
        <el-row class="m-top-20 txt-cen">
          <el-col>
            <span class="top-txt">訂單開立成功</span>
          </el-col>
        </el-row>
        <!--Order Success!-->
        <el-row class="txt-cen m-top-15">
          <el-col>
            <span class="top-txt">Order Success!</span>
          </el-col>
        </el-row>
        <!-- 金額 -->
        <el-row class="txt-cen m-top-25">
          <el-col>
            <span class="transactionAmount">NT <span v-format="'$#,##0'">{{ dataForm.transactionAmount }}</span></span>
          </el-col>
        </el-row>
        <!-- 切線 -->
        <el-row class="txt-cen m-top-20">
          <el-col>
            <el-image class="line" :src="lineImgUrl"></el-image>
          </el-col>
        </el-row>
        <!-- 特店名稱 -->
        <el-row type="flex" justify="space-between" class="m-top-20">
          <el-col :span="12">
            <span class="bot-txt-g">特店名稱 Merchant Name</span>
          </el-col>
          <el-col :span="12" class="txt-end">
            <span class="bot-txt-b">{{ dataForm.merchantName }}</span>
          </el-col>
        </el-row>
        <!-- 紅陽交易編號 -->
        <el-row type="flex" justify="space-between" class="m-top-20">
          <el-col :span="12">
            <span class="bot-txt-g">紅陽交易編號 Sunpay Transaction No.</span>
          </el-col>
          <el-col :span="12" class="txt-end">
            <span class="bot-txt-b">{{ dataForm.orderId }}</span>
          </el-col>
        </el-row>
        <!-- 特店訂單編號 -->
        <el-row type="flex" justify="space-between" class="m-top-20">
          <el-col :span="12">
            <span class="bot-txt-g">特店訂單編號 Merchant Order No.</span>
          </el-col>
          <el-col :span="12" class="txt-end">
            <span class="bot-txt-b">{{ dataForm.merchantOrderId }}</span>
          </el-col>
        </el-row>
        <!-- 交易時間 -->
        <el-row type="flex" justify="space-between" class="m-top-20">
          <el-col :span="12">
            <span class="bot-txt-g">交易時間 Payment Time</span>
          </el-col>
          <el-col :span="12" class="txt-end">
            <span class="bot-txt-b">{{ dataForm.createOrderTime }}</span>
          </el-col>
        </el-row>
        <!-- 交易金額 -->
        <el-row type="flex" justify="space-between" class="m-top-20">
          <el-col :span="12">
            <span class="bot-txt-g">交易金額 Payment Amount</span>
          </el-col>
          <el-col :span="12" class="txt-end">
            <span class="bot-txt-b">NT<span v-format="'$#,##0'">{{ dataForm.transactionAmount }}</span></span>
          </el-col>
        </el-row>
        <!-- 取貨門市地址 -->
        <el-row type="flex" justify="space-between" class="m-top-20" v-if='dataForm.storeAddress'>
          <el-col :span="12">
            <span class="bot-txt-g">取貨門市地址 Store Address</span>
          </el-col>
          <el-col :span="12" class="txt-end">
            <span class="bot-txt-b">{{ dataForm.storeAddress }}</span>
          </el-col>
        </el-row>
        <!-- 切線 -->
        <el-row style="text-align: center;" class="m-top-20">
          <el-col>
            <el-image class="line" :src="sLineImgUrl"></el-image>
          </el-col>
        </el-row>

        <!-- 服務條款 -->
        <div class="notice">
          <div class="box-txt-bl-s">注意事項 Notice</div>
          <div class="box-txt-bl-s">一、請於取貨時使用現金付款</div>
          <div class="box-txt-bl-s">Please pay by cash at the time of pick up.</div>
          <div class="box-txt-rd">【請留意來路不明包裹，領取時請再三確認個人訂貨資料，避免受騙上當。】</div>
          <div class="box-txt-rd">Please be cautious of packages from unknown sources. When picking them up, make sure to double-check your personal order information to avoid being scammed.</div>
          <div class="box-txt-rd">【請主動求證付款資訊來源，切勿將個人資料提供給來路不明的第三方，若發現有不明管道之訊息可撥打165反詐騙諮詢專線。】</div>
          <div class="box-txt-rd">Please proactively verify the source of payment information and refrain from providing personal information to unknown third parties. If you suspect you could be victim of a scam, protect your rights by calling the "165 Anti-Scam hotline".</div>
        </div>
        <!-- 儲存畫面 -->
        <div style="text-align: center;">
          <el-button type="danger" class="next-btn" @click="captrue">
            <template>
              <el-image class="img" :src="imageDownImgUrl"></el-image>
              <span class="txt">儲存畫面 Save Screen</span>
            </template>
          </el-button>
        </div>

        <!-- <div class="back-btn" @click="goPage()" style="width: 140px; margin: 20px auto;">回到商店</div> -->
        <!-- 紅陽支付圖示 -->
        <el-row style="text-align: center;" class="m-top-40">
          <el-col>
            <el-image style="width: 200px;" :src="sLogImgUrl"></el-image>
          </el-col>
        </el-row>
        <!-- 超商代碼繳費 -->
        <div v-if="false">
          <div class="show-body-inner">
            <div class="show-body-title">超商代碼繳費</div>
            <div class="show-body-amount">交易金額(NT$)/Amount：<span v-format="'$#,##0'">{{ dataForm.transactionAmount }}</span></div>
            <div class="show-body-business">商店名稱/Business Name：{{ dataForm.merchantName }}</div>
          </div>
          <el-divider class="line"></el-divider>
          <div class="show-body-inner body-sec">
            <div class="show-body-bottom mb-2">繳款期限/Due Date：{{ dataForm.duedate }}</div>
            <!--        <div class="show-body-bottom">交易內容/Trading Details：{{dataForm.orderInfo}}</div>-->
            <div class="show-body-pcode">超商代碼／PayCode： {{ dataForm.superBarCode }}</div>
          </div>
          <div class="show-body-inner show-body-img-box">
            <img :src="bottomIcon1" alt="" class="show-body-img mr-2">
            <img :src="bottomIcon2" alt="" class="show-body-img mr-2">
            <img :src="bottomIcon3" alt="" class="show-body-img mr-2">
            <img :src="bottomIcon4" alt="" class="show-body-img">
            <div class="red font-size-18 mt-3">
              請主動求證付款資訊來源，切勿將個人資料提供給來路不明的第三方，若發現有不明管道之訊息可諮詢165反詐騙專線。
            </div>
          </div>

          <div class="show-body-inner">
            <div class="show-body-back" @click="goPage()" v-if="!global.isEmpty(webSiteUrl)">回到商店</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import storLogoImg from '../../assets/img/new-payment/store-logo.png'
import successIconImg from '../../assets/img/new-payment/success-icon.png'
import lineImg from '../../assets/img/new-payment/line.png'
import sLogImg from '../../assets/img/new-payment/s-logo.png'
import imageDownImg from '../../assets/img/new-payment/image-down.png'
import sLineImg from '../../assets/img/new-payment/s-line.png'

export default {
  name: 'super-code-info',
  data() {
    return {
      mainLoading: false,
      titleIcon: require('../../assets/img/payment/pay-icon-3.png'),
      bottomIcon1: require('../../assets/img/info/info-icon-1.png'),
      bottomIcon2: require('../../assets/img/info/info-icon-2.png'),
      bottomIcon3: require('../../assets/img/info/info-icon-3.png'),
      bottomIcon4: require('../../assets/img/info/info-icon-4.png'),
      dataForm: {
        transactionAmount: '',// 交易金額
        merchantName: '',     // 商店名稱
        duedate: '',          // 繳款期限
        orderInfo: '',        // 交易內容
        superBarCode: '',     // 超商代碼
        masterId: '',
        merchantOrderId: '',
        createOrderTime: '',
        orderId: '',
        storeAddress: '',     //取貨門市地址
        shippingCode: '',     //寄件代碼
      },
      dataList: [],
      webSiteUrl: '',
      storLogoImgUrl: storLogoImg,
      successIconImgUrl: successIconImg,
      lineImgUrl: lineImg,
      sLogImgUrl: sLogImg,
      imageDownImgUrl: imageDownImg,
      sLineImgUrl: sLineImg,
    }
  },
  computed: {
    // 跳轉頁面
    routerName: {
      get() {
        return this.$store.state.credit.routerName
      }
    }
  },
  mounted() {
    this.showInfo()
    this.getWebSiteUrl();
    // 確認支付 接口入參
    this.dataForm.masterId = this.$route.query.masterId
  },
  methods: {
    getWebSiteUrl() {
      this.$http({
        url: this.$http.adornUrl(`/order/getReturnStoreUrl`),
        method: 'get',
        params: this.$http.adornParams({
          orderId: this.$route.query.masterId
        })
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          console.log(data.body.webSite)
          this.webSiteUrl = data.body.webSite
        }
      })
    },
    showInfo() {
      this.mainLoading = true
      this.$http({
        url: this.$http.adornUrl(`/order/paySuperPickup`),
        method: 'get',
        params: this.$http.adornParams({
          masterId: this.$route.query.masterId
        })
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          this.dataForm.masterId = this.$route.query.masterId
          this.dataForm.transactionAmount = this.$route.query.transactionAmount
          this.dataForm.orderInfo = this.$route.query.orderInfo
          this.dataForm.superBarCode = data.body.superBarCode
          this.dataForm.merchantName = data.body.merchantName
          this.dataForm.duedate = data.body.duedate
          this.dataForm.createOrderTime = data.body.createOrderTime
          this.dataForm.merchantOrderId = data.body.merchantOrderId
          this.dataForm.orderId = data.body.orderId
          this.dataForm.storeAddress = data.body.storeAddress
          this.dataForm.shippingCode = data.body.shippingCode
        }
        this.mainLoading = false
      })
    },
    goPage() {
      window.location.href = this.webSiteUrl
    },
    captrue() {
      const ref = this.$refs.content
      html2canvas(ref, {
        backgroundColor: '#e8f4ff',
        useCORS: true
      }).then((canvas) => {
        const dataURL = canvas.toDataURL('image/png')
        const creatDom = document.createElement('a')
        document.body.appendChild(creatDom)
        creatDom.href = dataURL
        creatDom.download = 'image'
        creatDom.click()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/payment.scss";

.show-body {
  text-align: left !important;

  &-inner {
    width: 700px;
    margin: 0 auto;
  }

  &-title {
    font-size: 32px;
    color: #8e0303;
    margin-bottom: 32px;
  }

  &-amount {
    font-size: 32px;
    color: #707070;
    margin-bottom: 14px;
    font-weight: bold;
  }

  &-business {
    font-size: 20px;
    color: #707070;
  }

  &-bottom {
    font-size: 21px;
    color: #030303;
    margin-bottom: 31px;
  }

  &-pcode {
    font-size: 32px;
    color: #030303;
  }

  &-img-box {
    margin-bottom: 63px;
  }

  &-img {
    width: 40px;
    height: 40px;
  }

  &-back {
    font-size: 23px;
    color: #8e0303;
    font-weight: bold;
    border-bottom: 2px solid #8e0303;
    width: 97px;
    padding: 2px;
    text-align: center;
    cursor: pointer;
  }

  .line.el-divider--horizontal {
    margin: 23px 0 53px 0 !important;
  }

  .body-sec {
    margin-bottom: 48.7px;
  }
}

.txt-end {
  text-align: end;
}

.back-btn {
  font-size: 26px;
  color: #8F0304;
  font-weight: bold;
  border-bottom: 2px solid #8F0304;
  padding: 2px;
  cursor: pointer;
  text-align: center;
}

.next-btn {
  background: #8F0304;
  width: 400px;
  max-width: 100%;

  .img {
    width: 30px;
    top: 5px;
  }

  .txt {
    font-size: 26px;
    font-weight: 600;
    line-height: 45px;
    margin-left: 10px;
  }
}

.box-txt-bl-s {
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  line-height: 20px;
}

.box-txt-bl-w {
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  line-height: 25px;
}

.box-txt-rd {
  font-weight: 600;
  color: #8F0304;
  font-size: 12px;
  line-height: 20px;
}

.box-txt-rd-w {
  font-weight: 400;
  color: #8F0304;
  font-size: 12px;
  line-height: 20px;
}

.bot-txt-g {
  font-weight: 400;
  font-size: 18px;
  color: #707070;
}

.bot-txt-b {
  font-weight: 400;
  font-size: 18px;
  color: #121212;
}

.m-top-15 {
  margin-top: 15px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-25 {
  margin-top: 25px;
}

.m-top-40 {
  margin-top: 40px;
}

.txt-cen {
  text-align: center;
}

.left-txt-g {
  font-weight: 600;
  font-size: 26px;
  color: #707070;
}

.left-txt-r {
  font-weight: 600;
  font-size: 26px;
  color: #8F0304;
}

.left-txt-b {
  font-weight: 600;
  font-size: 26px;
  color: #121212;
}

.line {
  width: 600px;
  height: 2px;
  max-width: 100%;
}

.notice {
  border-radius: 10px;
  background: #FAF0CA;
  margin: 30px 0;
  padding: 20px;
}

.transactionAmount {
  font-weight: 600;
  font-size: 36px;
}

.top-txt {
  font-weight: 400;
  font-size: 22px;
}

.top-logo {
  width: 100px;
}

.w-b {
  padding: 60px 140px !important;
  margin-top: 20px;
  border-radius: 38px;
}

.account-title {
  padding-left: 180px;
}

@media(max-width: 1200px) {
  .account-title {
    width: 60%;
    padding-left: 100px;
  }
  .account-value {
    width: 40%;
  }
}

@media(max-width: 991px) {
  .account-title {
    padding-left: 50px;
  }
}

@media(max-width: 767px) {
  .account-title {
    width: 60%;
    font-size: 20px;
    padding-left: 0;
  }
  .account-title span {
    display: inline-block;
    margin-left: 10px;
  }
  .account-value {
    font-size: 20px;
    width: 40%;
  }
}

@media(max-width: 450px) {
  .account-title {
    width: 170px;
    font-size: 20px;
    padding-left: 0;
  }
  .account-title span {
    display: inline-block;
    margin-left: 0px;
  }
  .account-value {
    font-size: 20px;
    width: calc(100% - 170px);
  }
}
</style>
