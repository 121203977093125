<head>
<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
</head>
<template>
  <!--一開始進入畫面 無物流-->
  <div class="payment grey-bg g-b" v-loading="mainLoading">
    <!--白色區域 -->
    <el-form ref="dataForm" :model="dataForm" :rules="rules" label-position="top">
      <div class="container box w-b" style="border-bottom-left-radius:0;border-bottom-right-radius:0;">
        <div class="top-logo">
          <el-image style="width: 80%; max-width:400px;  height: auto;" :src="logoImgUrl"></el-image>
        </div>
        <!-- 訂單資訊 -->
        <div class="or-information">
          <div style="display: flex;">
            <div class="title-24">訂單資訊</div>
            <div class="title-16">Order Information</div>
          </div>
          <el-image class="bk-img" :src="rectangleOiUrl"></el-image>

          <div v-if="slideImgList.length>0" class="slide-box">
            <imgSlide :dataList="slideImgList" ref="slideImg"></imgSlide>
          </div>

          <div style="position: relative; height: 320px;">
            <!-- 商品背景圖 -->
            <el-image :src="commodityBgImgUrl" style="height: 100%; width: 100%;"></el-image>
            <!-- 商品文字敘述區 -->
            <div class="order-txt">
              <div class="order-txt-center">
                <div class="mer-name-l"><span class="order-amount-r">商店名稱</span> Merchant Name：</div>
                <div class="mer-name-r" v-if="payOrderInfo.merchantDataDto.merchantName">{{ payOrderInfo.merchantDataDto.merchantName }}</div>
                <div class="order-name">{{ payOrderInfo.masterAndResourceDto.goodsName }}</div>
                <div class="mer-name-l" style="margin-top: 10px; width: 100%"><span class="order-amount-r">商品介紹</span> Product Info</div>
                <div class="mer-name-l goods-desc" style="width: 80%; margin-top: 10px; margin-bottom: 10px;" v-html="payOrderInfo.masterAndResourceDto.goodsDesc"></div>
                <!-- <div class="order-number-box">
                      <div class="order-number-title"><span class="redText">*</span><span class="order-amount-r">訂購數量</span> Order Quantity</div>
                      <div class="order-number-input">
                          <img class="num-sub" @click="goodsNumSub()" src="../assets/img/icon01.png"/>
                          <img class="num-add" @click="goodsNumAdd()" src="../assets/img/icon02.png"/>
                          <el-input v-model="dataForm.productQuantity" readonly></el-input>
                      </div>
                      <div class="inventory-text"><span class="order-amount-r">商品庫存數量</span> Stock：9999999999</div>
                  </div> -->
                <el-row class="order-amount-l"><span class="order-amount-r">交易金額 </span>Payment Amount</el-row>
                <!-- 金額輸入區 -->
                <el-row class="pay-amount">NT $
                  <!-- 固定金額區 -->
                  <span v-format="'#,##0'" v-if="tradeAmountFlg != '0'">{{ payOrderInfo.merchantOrderDataDto.tradeAmount }}</span>
                  <!-- 自訂金額區 -->
                  <el-form-item
                      v-else
                      class="priceInput"
                      style="width: 200px;"
                      label=""
                      prop="transactionAmount"
                      :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]">
                    <div style="display: flex;">
                      <!-- 金額輸入 -->
                      <el-input
                          v-model="dataForm.transactionAmount"
                          type="text"
                          @input="changeTradeAmount($event,'transactionAmount') , checkAmount()"
                          size="small"
                      />
                    </div>
                    <span slot="error">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ transactionAmountMsg }}</span>
                          </div>
                        </span>
                  </el-form-item>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <!-- 輸入金額後才會顯示的部分 -->
        <transition name="fade">
          <div v-if="paymentModeShow || tradeAmountFlg=='1'">
            <!--          <div>-->
            <!-- 付款方式 -->
            <div v-if="payForm.payMethods =='DOMESTIC_CREDIT_CARD'||(payForm.payMethods =='SUPER_MARKET_CODE' || payForm.payMethods =='SUPER_MARKET_BAR' || payForm.payMethods =='VIRTUAL_ACCOUNT' || payForm.payMethods =='UNION_CARD'|| payForm.payMethods =='SUPER_PAY_PICKUP') && needPayer=='1'">
              <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 1 of 4</div>
              <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity">Step. 1 of 2</div>
              <div class="tit-no" v-else>Step. 1 of 3</div>
            </div>
            <div v-else>
              <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 1 of 3</div>
              <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity">Step. 1 of 1</div>
              <div class="tit-no" v-else>Step. 1 of 2</div>
            </div>

            <!-- 選擇付款方式 -->
            <div class="pay-method">
              <div class="tit-area">
                <div class="tit-cn">選擇付款方式</div>
                <div class="tit-en">Payment Method</div>
              </div>
              <el-image class="tit-bk" :src="rectangleOiUrl"></el-image>
              <div class="pay-form-radio">
                <el-radio-group v-model="payForm.payMethods" @change="changePayMethods(),changeLogisticsMode()" style="width: 100%;">
                  <!-- <el-row :gutter="50"> -->
                  <!-- 左侧付款方式 -->
                  <div class="paymentMethod">
                    <el-row v-for="(item, index) in modeCodeList" :key="index">
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'DOMESTIC_CREDIT_CARD'">
                        信用卡 Credit Card
                        <el-image class="cre-card-img-1" :src="masterImgUrl"></el-image>
                        <el-image class="cre-card-img-2" :src="visaImgUrl"></el-image>
                        <el-image class="cre-card-img-3" :src="jcbImgUrl"></el-image>
                      </el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'UNION_CARD'">
                        銀聯卡 Union Pay
                        <el-image class="un-card" :src="unionPayImgUrl"></el-image>
                      </el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt " v-if="item.modeCode == 'GOOGLE_PAY'">
                        <el-image class="gg-img" :src="googlePayImgUrl"></el-image>
                      </el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt " v-if="item.modeCode == 'APPLE_PAY'">
                        <el-image class="ap-img" :src="applePayImgUrl"></el-image>
                      </el-radio>
                    </el-row>
                  </div>
                  <!-- 右侧付款方式 -->
                  <div class="paymentMethod">
                    <el-row v-for="(item, index) in modeCodeList" :key="index">
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'SUPER_MARKET_CODE'">超商代碼 Billing Code</el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'SUPER_MARKET_BAR'">超商條碼 Barcode</el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'VIRTUAL_ACCOUNT'">虛擬帳號 ATM Transfer</el-radio>
                      <el-radio :label="item.modeCode" class="pay-rad-txt  self-font" v-if="item.modeCode == 'SUPER_PAY_PICKUP'">超商取貨付款 Pay At Pickup</el-radio>
                    </el-row>
                  </div>
                  <!-- </el-row> -->
                </el-radio-group>
              </div>
            </div>
            <!-- 付款資訊 -->
            <div v-if="payForm.payMethods =='DOMESTIC_CREDIT_CARD' ||(payForm.payMethods =='SUPER_MARKET_CODE' || payForm.payMethods =='SUPER_MARKET_BAR' ||payForm.payMethods =='VIRTUAL_ACCOUNT' || payForm.payMethods =='UNION_CARD' || payForm.payMethods =='SUPER_PAY_PICKUP' ||payForm.payMethods =='APPLE_PAY' || payForm.payMethods =='GOOGLE_PAY') && needPayer=='1'">
              <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity" style="margin-top: 30px;">Step. 2 of 4</div>
              <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity" style="margin-top: 30px;">Step. 2 of 2</div>
              <div class="tit-no" v-else style="margin-top: 30px;">Step. 2 of 3</div>
            </div>
            <div class="pay-information" v-if="payForm.payMethods">

              <div class="tit-area" v-if="payForm.payMethods =='DOMESTIC_CREDIT_CARD' ||(payForm.payMethods =='SUPER_MARKET_CODE' || payForm.payMethods =='SUPER_MARKET_BAR' ||
			              payForm.payMethods =='VIRTUAL_ACCOUNT' || payForm.payMethods =='UNION_CARD' || payForm.payMethods =='SUPER_PAY_PICKUP' ||
			              payForm.payMethods =='APPLE_PAY' || payForm.payMethods =='GOOGLE_PAY') && needPayer=='1'">
                <div class="tit-cn">付款資訊</div>
                <div class="tit-en">Payment Information</div>

                <el-image class="tit-bg" :src="rectangleOiUrl"></el-image>
              </div>
              <div style="margin-top: 10px;">
                <!-- <el-form ref="dataForm" :model="dataForm" :rules="rules" label-position="top"> -->
                <!-- <el-row :gutter="50" v-if="payForm.payMethods == '1'">
                  <el-col :span="12"> -->
                <div v-if="payForm.payMethods == 'DOMESTIC_CREDIT_CARD'">
                  <div class="paymentFormItem">
                    <!-- 信用卡卡號 -->
                    <el-row>
                      <el-form-item
                          class="" style="font-weight: 400;" label="信用卡卡號 Card Number" prop="creditCardNumber"
                          :rules="[
                            { required: true, message: '必填欄位不得為空白X Field required', trigger: 'blur' },
                            { validator: validateCreditCardNumber}
                          ]"
                      >
                        <div style="display: flex;">
                          <el-input
                              v-model="creditCardNumber1"
                              maxlength="4" type="text"
                              ref="creditCardNumber1"
                              @input="changecreditCardNumber($event,'creditCardNumber1')"
                              style="margin-right: 5px;"
                              size="small"
                          />
                          -
                          <el-input
                              v-model="creditCardNumber2"
                              maxlength="4"
                              :type="eyeShow ? 'text' : 'password'"
                              ref="creditCardNumber2"
                              @input="changecreditCardNumber($event,'creditCardNumber2')"
                              style="margin: 0px 5px;" size="small"
                          />
                          -
                          <el-input
                              v-model="creditCardNumber3"
                              maxlength="4"
                              :type="eyeShow ? 'text' : 'password'"
                              ref="creditCardNumber3"
                              @input="changecreditCardNumber($event,'creditCardNumber3')"
                              style="margin: 0px 5px;" size="small"
                          />
                          -
                          <el-input
                              v-model="creditCardNumber4"
                              maxlength="4" type="text"
                              ref="creditCardNumber4"
                              @input="changecreditCardNumber($event,'creditCardNumber4')"
                              style="margin-left: 5px;"
                              size="small"
                          />
                          <!-- 眼睛睜眼圖 -->
                          <el-image v-show="eyeShow" @click="changeEyeShow" :src="eye" class="card-image"/>
                          <!-- 眼睛閉眼圖 -->
                          <el-image v-show="!eyeShow" @click="changeEyeShow" :src="eyeSlash" class="card-image"/>
                          <!--                      <el-image :src="cardRImgUrl" class="card-image"/>-->
                        </div>
                        <!-- 錯誤訊息區 -->
                        <span slot="error">
                          <div class="error-msg">
                            <!-- 驚嘆號圖片 -->
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <!-- 錯誤訊息 -->
                            <span class="txt">{{ creditCardNumberMsg }}</span>
                          </div>
                        </span>
                      </el-form-item>
                    </el-row><!-- termsList.length > 0 -->
                    <!-- 分期期數 -->
                    <el-row v-if="dataForm.installmentsList.length>0">
                      <el-form-item
                          style="font-weight: 400;"
                          label="分期期數 Number of Installments"
                          prop="creditCardInstallment"
                          :rules="payForm.payMethods == 'DOMESTIC_CREDIT_CARD' && dataForm.installmentsList.length>0?[
					                  { validator: validateInstallment},
                          ]:''"
                      >
                        <div style="display: flex;">
                          <el-select class="right-image-input selet-drop" v-model="dataForm.creditCardInstallment"
                                     style="width:100%" placeholder="" @change="changeInstallments()" clearable>
                            <el-option label="不分期" value=""></el-option>
                            <el-option v-for="(item,index) in dataForm.installmentsList" :key="item.installments"
                                       :label="item.installmentsName"
                                       :value="item.installments"></el-option>
                          </el-select>
                          <el-image :src="arrowDownImgUrl" class="right-image"/>
                        </div>
                        <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ validateInstallmentErr }}</span>
                        </div>
                      </span>
                      </el-form-item>
                    </el-row>
                    <!-- 有效月年/末三碼 -->
                    <el-row :gutter="30">
                      <!-- 有效月年 -->
                      <el-col :sm="12" :xs="24">
                        <el-form-item
                            style="font-weight: 400;"
                            label="有效月年 Expry Date"
                            prop="effectiveDate"
                            :rules="[
                              { required: true, message: EffectiveDateErMsg, trigger: 'blur' },
                              { validator: validateEffectiveDate}
                            ]"
                        >
                          <!-- <div style="display: flex;">
                              <el-cascader
                                placeholder=""
                                class="right-image-input"
                                v-model="dataForm.effectiveDate"
                                :options="optMYList"
                                @change="handleChange"></el-cascader>
                              <el-image :src="calendarTickImgUrl" class="right-image"/>
                          </div> -->
                          <div style="display: flex;">
                            <el-input
                                placeholder="mm / yy"
                                v-model="dataForm.effectiveDate"
                                maxlength="5"
                                @input="effectiveDateCheck"
                                size="small"
                                class="right-image-input"/>
                            <!--                        <el-image :src="dataForm.effectiveDate ? calendarTickImgUrl : calendarTickRImgUrl" class="right-image"/>-->
                          </div>

                          <span slot="error">
                      <div class="error-msg">
                        <el-image :src="annotationAlertImgUrl" class="img"/>
                        <span class="txt">{{ EffectiveDateErMsg }}</span>
                      </div>
                    </span>
                        </el-form-item>
                      </el-col>
                      <!-- 末三碼 -->
                      <el-col :sm="12" :xs="24">
                        <el-form-item
                            style="font-weight: 400;"
                            label="末三碼 CVV/CVC"
                            prop="creditCardSecurityCode"
                            :rules="[
                              { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                            ]">
                          <div style="display: flex;">
                            <el-input
                                v-model="dataForm.creditCardSecurityCode"
                                maxlength="3"
                                @input="(v) => (dataForm.creditCardSecurityCode = v.replace(/[^\d]/g,''))"
                                class="right-image-input"
                                size="small"
                            />
                          </div>
                          <span slot="error">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ formErrorMsg }}</span>
                          </div>
                        </span>
                        </el-form-item>
                        <el-col :span="24" class="box-payForm-card" v-if="dataForm.merchantSaveCard == '1'">
                          <el-checkbox v-model="dataForm.saveCard" false-label="0" true-label="1" tabindex="0">
                            <div class="txt">同意記住此結帳資訊，以利下次支付</div>
                            <el-image class="img" :src="rememberTxtImgUrl"></el-image>
                          </el-checkbox>
                        </el-col>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- 付款人資訊-信用卡專用 -->
                  <div class="paymentFormItem" v-if="needPayer=='1'">
                    <!-- 付款人姓名 -->
                    <el-row>
                      <el-form-item
                          style="font-weight: 400;"
                          label="付款人姓名 Payer Name"
                          prop="creditCardUserName"
                          :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                      ]">
                        <div style="display: flex;">
                          <el-input v-model="dataForm.creditCardUserName" size="small" class="right-image-input"/>
                          <!--                      <el-image :src="dataForm.creditCardUserName ? profileTickImgUrl : profileTickRImgUrl" class="right-image"/>-->
                        </div>
                        <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                      </el-form-item>
                    </el-row>
                    <!-- 行動電話 -->
                    <el-row>
                      <el-form-item
                          style="font-weight: 400;"
                          label="行動電話 Mobile Number"
                          prop="creditCardPhone"
                      >
                        <div style="display: flex;">
                          <el-input
                              @input="(v) => (dataForm.creditCardPhone = v.replace(/[^\d]/g,''))"
                              v-model="dataForm.creditCardPhone"
                              @change="validatePhoneNumber()"
                              maxlength="10"
                              class="right-image-input"
                              size="small"
                          >
                            <!--							<template slot="prepend">+886</template>-->
                          </el-input>
                          <!--                      <el-image :src="dataForm.creditCardPhone ? componentImgUrl : componentRImgUrl" class="right-image"/>-->

                        </div>
                        <span v-if="phoneError">
					    <div class="error-msg">
					      <el-image :src="annotationAlertImgUrl" class="img"/>
					      <span class="txt">請輸入正確的電話號碼</span>
					    </div>
					  </span>
                      </el-form-item>
                    </el-row>
                    <!-- 信箱 -->
                    <el-row>
                      <el-form-item
                          style="font-weight: 400;"
                          label="信箱 Email"
                          prop="creditCardEmail"
                          :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                        { type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }
                      ]">
                        <div style="position: absolute; top: -25px; right: 0px;">
                          <div style="display: flex; flex-direction: row; line-height: 12px; position: absolute; right: 0px;">
                            <el-image style="width: 14px; height: 14px;" :src="annotationCheckImgUrl"/>
                            <!-- <el-image style="width: 230px;" :src="emailTxtCNImgUrl"/> -->
                            <div style="color: #1DB145; font-size: 0.6rem; font-weight: 400; margin-left: 2px; ">紅陽科技將會寄發交易結果通知信至此Email</div>
                          </div>

                          <div style="line-height: 8px; text-align: end; margin-top: 14px;">
                            <div style="color: #1DB145; font-size: 0.6rem; font-weight: 400; margin-left: 2px;">SUNPAY will Send a Payment Notification to this mail</div>
                          </div>
                        </div>
                        <div style="display: flex;">
                          <el-input v-model="dataForm.creditCardEmail" size="small" class="right-image-input"/>
                          <!--                      <el-image :src="dataForm.creditCardEmail ? mailImgUrl : mailRImgUrl" class="right-image"/>-->
                        </div>
                        <span slot="error" v-if="!dataForm.creditCardEmail">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                        <span slot="error" v-else>
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">請輸入正確的信箱</span>
                        </div>
                      </span>
                      </el-form-item>
                    </el-row>
                  </div>
                </div>
                <!-- 信用卡付款人資訊-其餘付款方式- -->
                <div v-if="(payForm.payMethods =='SUPER_MARKET_CODE' || payForm.payMethods =='SUPER_MARKET_BAR' || payForm.payMethods =='VIRTUAL_ACCOUNT' || payForm.payMethods =='UNION_CARD' || payForm.payMethods =='SUPER_PAY_PICKUP' ||payForm.payMethods =='APPLE_PAY' || payForm.payMethods =='GOOGLE_PAY') && needPayer=='1'">
                  <div class="paymentFormItem">
                    <!-- 付款人姓名 -->
                    <el-row>
                      <el-form-item
                          style="font-weight: 400;"
                          label="付款人姓名 Payer Name"
                          prop="creditCardUserName"
                          :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]">
                        <div style="display: flex;">
                          <el-input v-model="dataForm.creditCardUserName" size="small" class="right-image-input"/>
                          <!--                      <el-image :src="dataForm.creditCardUserName ? profileTickImgUrl : profileTickRImgUrl" class="right-image"/>-->
                        </div>
                        <span slot="error">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ formErrorMsg }}</span>
                          </div>
                        </span>
                      </el-form-item>
                    </el-row>
                    <!-- 行動電話 -->
                    <el-row style="margin-top: 20px">
                      <el-form-item
                          style="font-weight: 400;"
                          label="行動電話 Mobile Number  "
                          prop="creditCardPhone"
                      >
                        <div style="display: flex;">
                          <el-input
                              @input="(v) => (dataForm.creditCardPhone = v.replace(/[^\d]/g,''))"
                              @change="validatePhoneNumber()"
                              v-model="dataForm.creditCardPhone"
                              maxlength="10"
                              class="right-image-input"
                              size="small"
                          >
                            <!--							<template slot="prepend">+886</template>-->
                          </el-input>
                          <!--                      <el-image :src="dataForm.creditCardPhone ? componentImgUrl : componentRImgUrl" class="right-image"/>-->
                        </div>
                        <span v-if="phoneError">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">請輸入正確的電話號碼</span>
                          </div>
                        </span>
                      </el-form-item>
                    </el-row>
                    <!-- 信箱 -->
                    <el-row style="margin-top: 20px">
                      <el-form-item
                          style="font-weight: 400;"
                          label="信箱 Email"
                          prop="creditCardEmail"
                          :rules="[
                          { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                          { type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }
                       ]">
                        <div style="position: absolute; top: -25px; right: 0px;">
                          <div style="display: flex; flex-direction: row; line-height: 12px;">
                            <el-image style="width: 14px; height: 14px;" :src="annotationCheckImgUrl"/>
                            <!-- <el-image style="width: 230px;" :src="emailTxtCNImgUrl"/> -->
                            <div style="color: #1DB145; font-size: 0.6rem; font-weight: 400; margin-left: 2px;">紅陽科技將會寄發交易結果通知信至此Email</div>
                          </div>
                          <div style="line-height: 8px; text-align: end;">
                            <el-image style="width: 230px;" :src="emailTxtImgUrl"/>
                          </div>
                        </div>
                        <div style="display: flex;">
                          <el-input v-model="dataForm.creditCardEmail" size="small" class="right-image-input"/>
                          <!--                      <el-image :src="dataForm.creditCardEmail ? mailImgUrl : mailRImgUrl" class="right-image"/>-->
                        </div>
                        <span slot="error" v-if="!dataForm.creditCardEmail">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ formErrorMsg }}</span>
                          </div>
                        </span>
                        <span slot="error" v-else>
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">請輸入正確的信箱</span>
                          </div>
                        </span>
                      </el-form-item>
                    </el-row>
                  </div>
                  <!-- </el-col>
                    </el-row> -->
                </div>

                <!-- 分割綫 -->
                <!-- invoiceShowFlg  0隐藏 1显示舊版 2顯示新版-->
                <el-row v-if="dataForm.invoiceShowFlg == 1 || dataForm.invoiceShowFlg == 2" :gutter="50" style="margin-top: 30px; margin-left: -25px;">
                  <el-col :span="24">
                    <el-image :src="dividerImgUrl"></el-image>
                  </el-col>
                </el-row>
                <!-- 1显示舊版發票 -->
                <div v-if="dataForm.invoiceShowFlg == 1">
                  <el-row :gutter="50" style="top: -10px;">
                    <el-col :span="24">
                      <el-form-item
                          style="font-weight: 400;"
                          label="發票 Invoice"
                          prop="payMethods"
                      >
                        <div class="pay-form-radio">
                          <el-radio-group style="width: 100%;" v-model="payForm.invoice" @change="changeInvoiceStatus">
                            <el-radio label="0" :checked="payForm.invoice == 0" class="pay-rad-txt ">個人發票</el-radio>
                            <!-- <el-radio label="1" class="pay-rad-txt ">會員載具</el-radio> -->
                            <el-radio label="2" :checked="payForm.invoice == 2" class="pay-rad-txt ">手機條碼</el-radio>
                            <el-radio label="3" :checked="payForm.invoice == 3" class="pay-rad-txt ">公司統編</el-radio>
                            <el-radio label="4" :checked="payForm.invoice == 4" class="pay-rad-txt ">捐贈</el-radio>
                          </el-radio-group>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <div v-if="payForm.invoice">
                    <div style="margin-top: 0px;" v-if="payForm.invoice == 3">
                      <el-form-item style="font-weight: 400;" label="紅陽科技將寄發電子發票開立通知至您的Mail，可點擊信件内明細連結執行發票列印">
                      </el-form-item>
                      <div style="display: flex; flex-direction: row;">
                        <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                          <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>發票抬頭</div>
                        </el-form-item>
                        <el-form-item prop="invoiceHead" style="width:420px;margin-top: 20px!important;">
                          <el-input class="ol-el-input" style="max-width: 420px" maxlength="60" v-model="dataForm.invoiceHead"></el-input>
                          <span slot="error" v-if="!dataForm.invoiceHead">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                        </el-form-item>
                      </div>
                      <div style="display: flex; flex-direction: row;">
                        <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                          <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>買受人統編</div>
                        </el-form-item>
                        <el-form-item prop="buyerIdentifier" style="width:420px;margin-top: 20px!important;">
                          <el-input class="ol-el-input" style="max-width: 420px" maxlength="8" v-model="dataForm.buyerIdentifier"></el-input>
                          <span slot="error" v-if="!dataForm.buyerIdentifier">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                          <span slot="error" v-else>
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">請輸入正確買受人統編格式</span>
                              </div>
                            </span>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 2顯示新版發票 -->
                <div v-if="dataForm.invoiceShowFlg == 2">
                  <el-row :gutter="50" style="top: -10px;">
                    <el-col :span="24">
                      <el-form-item style="font-weight: 400;" label="發票 Invoice" prop="payMethods">
                        <div class="pay-form-radio">
                          <el-radio-group style="width: 100%;" v-model="payForm.invoice" @change="changeInvoiceStatus">
                            <el-radio label="0" :checked="payForm.invoice == 0" class="pay-rad-txt ">個人發票</el-radio>
                            <el-radio label="2" :checked="payForm.invoice == 2" class="pay-rad-txt ">手機條碼</el-radio>
                            <el-radio label="1" :checked="payForm.invoice == 1" class="pay-rad-txt ">自然人憑證條碼</el-radio>
                            <el-radio label="3" :checked="payForm.invoice == 3" class="pay-rad-txt ">公司統編</el-radio>
                            <el-radio label="4" :checked="payForm.invoice == 4" class="pay-rad-txt ">捐贈</el-radio>
                          </el-radio-group>
                        </div>
                        <div v-if="payForm.invoice == 0 || payForm.invoice == 3" class="pay-form-check">
                          <el-form-item label="" v-if="invoicePaperShow == '1'">
                            <el-checkbox v-model="invoicePaperChecked" @change="changePaperCheck()" style="font-weight: 400;">索取紙本發票</el-checkbox>
                          </el-form-item>
                          <div style="margin-top: -10px;" v-if="payForm.invoice == 0 && dataForm.invoiceShowFlg == 2">
                            <el-form-item style="font-weight: 400;" label="若無勾選索取紙本發票，個人發票預設存入會員載具，若中獎將另行通知">
                            </el-form-item>
                          </div>

                          <div style="margin-top: 0px;" v-if="payForm.invoice == 3">
                            <el-form-item style="font-weight: 400;" label="紅陽科技將寄發電子發票開立通知至您的Mail，可點擊信件内明細連結執行發票列印">
                            </el-form-item>
                            <div style="display: flex; flex-direction: row;">
                              <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                                <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>發票抬頭</div>
                              </el-form-item>
                              <el-form-item prop="invoiceHead" style="width:420px;margin-top: 20px!important;">
                                <el-input class="ol-el-input" style="max-width: 420px" maxlength="60" v-model="dataForm.invoiceHead"></el-input>
                                <span slot="error" v-if="!dataForm.invoiceHead">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                              </el-form-item>
                            </div>
                            <div style="display: flex; flex-direction: row;">
                              <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                                <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>買受人統編</div>
                              </el-form-item>
                              <el-form-item prop="buyerIdentifier" style="width:420px;margin-top: 20px!important;">
                                <el-input class="ol-el-input" style="max-width: 420px" maxlength="8" v-model="dataForm.buyerIdentifier"></el-input>
                                <span slot="error" v-if="!dataForm.buyerIdentifier">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                                <span slot="error" v-else>
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">請輸入正確買受人統編格式</span>
                              </div>
                            </span>
                              </el-form-item>
                            </div>
                          </div>

                          <div style="display: flex; flex-direction: row;" v-if="invoicePaperChecked && invoicePaperShow == '1'">
                            <el-form-item style="font-weight: 400; width: 140px; margin-top: 12px;" label="" label-position="left">
                              <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>發票地址</div>
                              <span v-if="!dataForm.city || !dataForm.area || !dataForm.address">
                              <div class="error-msg">
                                <!-- <el-image :src="annotationAlertImgUrl" class="img"/> -->
                                <span class="txt">必填欄位不能為空</span>
                              </div>
                            </span>
                            </el-form-item>
                            <div style="width: 100%; max-width: 420px;margin-top: 20px;">
                              <div class="invoiceItem">
                                <el-select v-model="dataForm.city" clearable placeholder="選擇城市" filterable @clear="clearSelect('dataForm','area')" @change="choseCity($event)">
                                  <el-option v-for="(item,index) in cityList" :key="item.cityId" :label="item.cityName" :value="item.cityId"></el-option>
                                </el-select>
                              </div>
                              <div class="invoiceItem" style="margin-left: 10px;">
                                <el-select v-model="dataForm.area" clearable filterable placeholder="選擇地區">
                                  <el-option v-for="(item,index) in areaList" :key="item.areaId" :label="item.areaName" :value="item.areaId"></el-option>
                                </el-select>
                              </div>
                              <el-input v-model="dataForm.address" type="text" style="margin-top: 20px;"></el-input>
                            </div>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>


                <!-- 載具號碼 -->
                <div v-if="payForm.invoice">
                  <div style="margin-top: 0px;" v-if="payForm.invoice == 1 || payForm.invoice == 2">
                    <!-- <el-col :span="12"> -->
                    <div style="display: flex; flex-direction: row;">
                      <el-form-item style="font-weight: 400; width: 140px;" label="" prop="payMethods" label-position="left">
                        <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>載具號碼</div>
                      </el-form-item>
                      <el-form-item prop="carrierId" style="margin-top: 0!important;width:420px;">
                        <el-input class="ol-el-input" style="max-width: 420px;" :maxlength="payForm.invoice == 1?16:8" v-model="dataForm.carrierId"></el-input>
                        <span slot="error" v-if="!dataForm.carrierId">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                        <span slot="error" v-else>
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">請輸入正確載具號碼格式</span>
                        </div>
                      </span>
                      </el-form-item>
                    </div>
                  </div>

                  <div style="margin-top: 0px;" v-if="payForm.invoice == 4">
                    <div style="display: flex; flex-direction: row;">
                      <el-form-item style="font-weight: 400; width: 140px;" label="" label-position="left">
                        <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>捐贈號碼</div>
                      </el-form-item>
                      <el-form-item prop="poban" style="width: 420px;">
                        <el-select clearable filterable v-model="dataForm.poban" style="width:420px;">
                          <el-option :key="item.poban" :label='item.poban+"-"+item.pobanName'
                                     :value=item.poban
                                     v-for="(item,index) in pobanList"></el-option>
                        </el-select>
                        <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <!-- 分割綫 -->
                <div v-if="false">
                  <el-row :gutter="50" style="margin-top: 30px; margin-left: -25px;">
                    <el-col :span="24">
                      <el-image :src="dividerImgUrl"></el-image>
                    </el-col>
                  </el-row>
                </div>

                <!-- 寄送方式 -->
                <div v-if="false">
                  <el-row :gutter="50">
                    <el-col :span="24">
                      <el-form-item style="font-weight: 400;" label="寄送方式 Delivery" prop="payMethods">
                        <div class="pay-form-radio">
                          <el-radio-group style="width: 100%;">
                            <el-radio label="1" class="pay-rad-txt ">超商取貨 In-store Pickup</el-radio>
                            <el-radio label="1" class="pay-rad-txt ">宅配物流 Home Delivery</el-radio>
                          </el-radio-group>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <!-- 備註 Memo -->
                <div style="margin-top: 20px;">
                  <div style="display: flex; flex-direction: row;">
                    <el-form-item style="font-weight: 400; width: 140px; margin-top: 12px;" label="備註 Memo" prop="payMethods" label-position="left">
                    </el-form-item>
                    <el-input class="le-el-input" type="textarea" :rows="2" style="text-align: left; max-width: calc(100% - 200px);" v-model="dataForm.desc"></el-input>
                  </div>
                </div>
                <!-- 備註 Memo -->

              </div>
            </div>

            <!-- 收件人咨询 -->
            <div v-if="matchingLogistics=='1'">
              <div>
                <el-row :gutter="50" style="margin-top: 30px; margin-left: -25px;">
                  <el-col :span="24">
                    <el-image :src="dividerImgUrl"></el-image>
                  </el-col>
                </el-row>
              </div>
              <!-- 寄送方式 Delivery -->
              <div>
                <el-row :gutter="50">
                  <el-col :span="24">
                    <el-form-item style="font-weight: 400;" label="寄送方式 Delivery" prop="shippingMethod">
                      <div class="pay-form-radio">
                        <el-radio-group v-model="dataForm.shippingMethod" style="width: 100%;">
                          <el-radio label="10" class="pay-rad-txt ">超商取貨 In-store Pickup</el-radio>
                          <!-- <el-radio label="20" class="pay-rad-txt ">宅配物流 Home Delivery</el-radio> -->
                        </el-radio-group>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>


              <div style="display: flex;flex-direction: column; margin-top: 20px;">
                <div v-if="payForm.payMethods =='DOMESTIC_CREDIT_CARD'||(payForm.payMethods =='SUPER_MARKET_CODE' || payForm.payMethods =='SUPER_MARKET_BAR' ||payForm.payMethods =='VIRTUAL_ACCOUNT' || payForm.payMethods =='UNION_CARD'|| payForm.payMethods =='SUPER_PAY_PICKUP') && needPayer=='1'">
                  <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 3 of 4</div>
                  <div class="tit-no" v-else>Step. 3 of 3</div>
                </div>
                <div v-else>
                  <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 2 of 3</div>
                  <div class="tit-no" v-else>Step. 2 of 2</div>
                </div>
                <div class="pay-information">
                  <div class="tit-area">
                    <div class="tit-cn">收件人資訊</div>
                    <div class="tit-en">Receiver Information</div>
                    <el-image class="tit-bg" :src="rectangleOiUrl"></el-image>
                  </div>
                  <div style="margin-top: 10px;">
                    <div>
                      <div class="recipientInfoChecked" v-if="needPayer=='1'" style="text-align: right; margin-top: -50px;">
                        <div style="line-height: 12px;">
                          <div style="color: #1DB145; font-size: 0.6rem; font-weight: 400; margin-left: 2px;">
                            <el-image style="width: 14px; height: 14px;display: inline-block; vertical-align: top;" :src="annotationCheckImgUrl"/>
                            請務必正確輸入收件人資訊確保商品正確送達
                          </div>
                        </div>
                        <div style="margin-top: 2px;">
                          <el-image style="height: 12px;" :src="productTxtImgUrl"/>
                        </div>
                        <div class="pay-form-check">
                          <el-checkbox v-model="recipientInfoChecked" @change="recipientInfoCheckChange"><span style="margin-left: -6px">同付款人資訊</span></el-checkbox>
                        </div>
                      </div>
                      <div class="paymentFormItem">
                        <el-row>
                          <div class="pay-form-radio">
                            <el-radio-group @change="logisticsModeCodeChange" v-model="dataForm.logisticsModeCode" vertical style="width: 100%;">
                              <el-radio :label="item.modeCode" v-for="item in logisticsModeList" class="pay-rad-txt " style="font-weight: 400;width: 100%;">{{ item.modeName }}</el-radio>
                            </el-radio-group>
                          </div>
                          <div>
                            <el-form-item style="font-weight: 400;" label="取貨門市 Convenience Store" prop="pickUpStorePosition" :rules="matchingLogistics=='1'?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:[]">
                              <div style="display: flex;">
                                <el-input v-model="dataForm.pickUpStorePosition" disabled class="right-image-input"/>
                                <el-image :src="dataForm.pickUpStorePosition ? carImgUrl : carRImgUrl" style="margin-top: 8px" class="right-image"/>
                              </div>
                              <span slot="error">
										  <div class="error-msg">
											<el-image :src="annotationAlertImgUrl" class="img"/>
											<span class="txt">{{ formErrorMsg }}</span>
										  </div>
										</span>
                            </el-form-item>
                          </div>
                          <div>
                            <el-button type="danger" style="width: 100%; padding: 15px; background: #8F0304;margin-top: 20px;" @click="handleOpen">選擇取貨門市 Choose Store</el-button>
                            <div class="error-msg" v-if="modeCodeisEmpty">
                              <el-image :src="annotationAlertImgUrl" class="img"/>
                              <span class="txt">請選擇取貨超商Please choose store</span>
                            </div>
                          </div>
                        </el-row>
                      </div>
                      <div class="paymentFormItem">
                        <el-row>
                          <div>
                            <el-form-item style="font-weight: 400;" label="收件人姓名 Receiver Name" prop="recipientName" :rules="matchingLogistics=='1'?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:[]">
                              <div style="display: flex;">
                                <el-input v-model="dataForm.recipientName" class="right-image-input"/>
                                <el-image :src="dataForm.recipientName ? profileTickImgUrl : profileTickRImgUrl" class="right-image"/>
                              </div>
                              <span slot="error">
									  <div class="error-msg">
										<el-image :src="annotationAlertImgUrl" class="img"/>
										<span class="txt">{{ formErrorMsg }}</span>
									  </div>
									</span>
                            </el-form-item>
                          </div>
                          <div>
                            <el-form-item style="font-weight: 400;" label="收件人手機 Mobile Number" prop="recipientPhoneNumber" :rules="matchingLogistics=='1'?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]:[]">
                              <div style="display: flex;">
                                <el-input v-model="dataForm.recipientPhoneNumber" maxlength="10" minlength="10" @input="(v) => (dataForm.recipientPhoneNumber = v.replace(/[^\d]/g,''))" @change="checkRecipientPhone" class="right-image-input">
                                  <!--						  <template slot="prepend">+886</template>-->
                                </el-input>
                                <el-image :src="dataForm.recipientPhoneNumber ? componentImgUrl : componentRImgUrl" class="right-image"/>
                              </div>
                              <span slot="error" v-if="!dataForm.recipientPhoneNumber">
                            <div class="error-msg">
                              <el-image :src="annotationAlertImgUrl" class="img"/>
                              <span class="txt">{{ recipientPhoneMsg }}</span>
                            </div>
                          </span>
                              <span v-if="recipientPhoneErr">
									  <div class="error-msg">
										<el-image :src="annotationAlertImgUrl" class="img"/>
										<span class="txt">請輸入正確的電話號碼！</span>
									  </div>
									</span>
                            </el-form-item>
                          </div>
                          <div>
                            <el-form-item style="font-weight: 400;" label="信箱 Email" prop="recipientEmail" :rules="matchingLogistics=='1'?[{ type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }]:[]">
                              <div style="display: flex;">
                                <el-input v-model="dataForm.recipientEmail" class="right-image-input"/>
                                <el-image :src="dataForm.recipientEmail ? mailImgUrl : mailRImgUrl" class="right-image"/>
                              </div>
                              <span slot="error">
									  <div class="error-msg">
										<el-image :src="annotationAlertImgUrl" class="img"/>
										<span class="txt">請輸入正確的信箱</span>
									  </div>
									</span>
                            </el-form-item>
                          </div>
                        </el-row>
                      </div>
                    </div>
                  </div>
                </div>


                <div>
                  <!-- <el-form :model="testObj"  label-position="top"> -->

                  <div v-if="false">
                    <el-row :gutter="50">
                      <el-col :span="12">
                        <el-form-item style="font-weight: 400;" label="收件人姓名 Receiver Name" prop="payMethods" :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]">
                          <div style="display: flex;">
                            <el-input v-model="payMethods" class="right-image-input"/>
                            <el-image :src="payMethods ? profileTickImgUrl : profileTickRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
						<div class="error-msg">
						  <el-image :src="annotationAlertImgUrl" class="img"/>
						  <span class="txt">{{ formErrorMsg }}</span>
						</div>
					  </span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item style="font-weight: 400;" label="收件人手機 Mobile Number" prop="payMethods" :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]">
                          <div style="display: flex;">
                            <el-input v-model="payMethods" class="right-image-input" maxlength="10" minlength="10" @change="checkRecipientPhone()" @input="(v) => (dataForm.recipientPhoneNumber = v.replace(/[^\d]/g,''))">
                              <!--						  <template slot="prepend">+886</template>-->
                            </el-input>
                            <el-image :src="dataForm.payMethods ? componentImgUrl : componentRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
						<div class="error-msg">
						  <el-image :src="annotationAlertImgUrl" class="img"/>
						  <span class="txt">{{ formErrorMsg }}</span>
						</div>
					  </span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row :gutter="50">
                      <el-col :span="12">
                        <el-form-item style="font-weight: 400;" label="宅配地址 Receiver Addressr" prop="payMethods" :rules="[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]">
                          <div style="display: flex;">
                            <el-input v-model="payMethods" class="right-image-input"/>
                            <el-image :src="payMethods ? mapImgUrl : mapRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
						<div class="error-msg">
						  <el-image :src="annotationAlertImgUrl" class="img"/>
						  <span class="txt">{{ formErrorMsg }}</span>
						</div>
					  </span>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item style="font-weight: 400;" label="信箱 Email" prop="creditCardEmail" :rules="[{ type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }]">
                          <div style="display: flex;">
                            <el-input v-model="dataForm.creditCardEmail" class="right-image-input"/>
                            <el-image :src="dataForm.creditCardEmail ? mailImgUrl : mailRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
						<div class="error-msg">
						  <el-image :src="annotationAlertImgUrl" class="img"/>
						  <span class="txt">請輸入正確的信箱</span>
						</div>
					  </span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- </el-form> -->
                </div>
              </div>
            </div>

            <div v-if="isExistIdentity">
              <el-row :gutter="50" style="margin-top: 30px; margin-left: -25px;">
                <el-col :span="24">
                  <el-image :src="dividerImgUrl"></el-image>
                </el-col>
              </el-row>
            </div>
            <!-- 身分驗證 -->
            <div v-if="isExistIdentity" style="display: flex;flex-direction: column; margin-top: 30px;">
              <div v-if="payForm.payMethods =='DOMESTIC_CREDIT_CARD'||(payForm.payMethods =='SUPER_MARKET_CODE' || payForm.payMethods =='SUPER_MARKET_BAR' ||payForm.payMethods =='VIRTUAL_ACCOUNT' || payForm.payMethods =='UNION_CARD'|| payForm.payMethods =='SUPER_PAY_PICKUP') && needPayer=='1'">
                <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 4 of 4</div>
                <div class="tit-no" v-else>Step. 3 of 3</div>
              </div>
              <div v-else>
                <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 3 of 3</div>
                <div class="tit-no" v-else>Step. 2 of 2</div>
              </div>
              <div class="pay-method">
                <div class="tit-area" style="display: flex;">
                  <div style="font-size: 24px; font-weight: 600; color: #03328D;">身分驗證資訊</div>
                  <div style="font-size: 16px; font-weight: 600; color: #03328D; line-height: 42px; margin-left: 10px;">Identity Verification</div>
                </div>
                <el-image style="width: 296px; height: 24px; margin-top: 16px; margin-left: 12px; position: absolute;" :src="rectangleOiUrl"></el-image>
                <div>
                  <div class="identity-tips">依「第三方支付服務業防制洗錢及打擊資恐辦法」之規定，訂單已達法定金額，請完成身分驗證 In accordance with the “Regulations Governing Anti-Money Laundering and Countering the Financing of Terrorism for the Third-Party Payment Enterprises", the order has reached the legal amount, please complete the identity verification.</div>
                  <div class="identity-tips">您的身分證資料僅用於內政部戶役司之查驗作業，不會用於其他目的或提供給第三方 Your ID information will only be used for verification purposes by the Ministry of the Interior, Household Registration Office, and will not be used for any other purposes or provided to third parties.</div>
                  <div class="identity-title">手機號碼驗證(OTP)　Cellphone Number Verification</div>
                  <div style="margin-bottom: 20px;" class="paymentFormItem">
                    <el-row>
                      <el-form-item style="font-weight: 400;" label="持卡人手機 Cardholder Cellphone Number" prop="cellphoneNumber" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },{ validator: validateCellphoneNumber}]:''">
                        <div style="display: flex;">
                          <el-input v-model="dataForm.cellphoneNumber" @input="changeCellphoneNumber($event)" maxlength="10" class="right-image-input"/>
                          <el-image :src="dataForm.cellphoneNumber.length==10 ? componentImgUrl : componentRImgUrl" class="right-image"/>
                        </div>
                        <span slot="error">
                         <div class="error-msg">
                           <el-image :src="annotationAlertImgUrl" class="img"/>
                           <span class="txt">{{ cellphoneNumberMsg }}</span>
                         </div>
                       </span>
                      </el-form-item>
                      <div style="text-align: end;" v-if="verificationType == 1">
                        <!-- sendCodeStatus:0默認可點 1xx秒後不可點  2重發-可點 3次數已過-->
                        <el-button style="background: #8F0304;padding: 14px 10px;border-radius: 10px;color:white;" @click="getVerificationCode()" v-if="sendCodeStatus!='3'" :disabled="dataForm.cellphoneNumber==''|| checkPhone(dataForm.cellphoneNumber) || sendCodeStatus=='1' || cellphoneNumberErr">{{ sendCodeStatus == '0' ? '發送驗證碼 Send Verification Code' : sendCodeStatus == '1' ? count + '秒後重新發送 Resend in ' + count + ' Sec' : '重新發送驗證碼 Resend verification Code' }}</el-button>
                        <el-button v-else style="color: #8F0304;background: transparent;padding: 14px 10px;border: 0px;font-weight: 600;" disabled>發送次數已滿 Verification limit reached</el-button>
                      </div>
                    </el-row>
                  </div>
                  <template v-if="verificationType == 1">
                    <div style="margin-bottom: 20px;" class="paymentFormItem">
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="簡訊驗證碼 SMS Verification Code" prop="verificationCode" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },{ validator: validateVerificationCode}]:''">
                          <div style="display: flex;">
                            <el-input @input="verificationCodeCheck($event)" maxlength="6" v-model="dataForm.verificationCode" class="right-image-input"/>
                            <el-image :src="dataForm.verificationCode.length==6 ? smsVerificationImgUrl : smsNoVerificationImgUrl" class="right-image"/>
                          </div>
                          <span v-if="verificationCodeErr">
                             <div class="error-msg">
                               <el-image :src="annotationAlertImgUrl" class="img"/>
                               <span class="txt">驗證碼錯誤{{ errCount }}次，累計錯誤3次此筆交易將無效</br>
                                 Verification failed once, if there are 3 times in total, the transaction will be invalid.</span>
                             </div>
                           </span>
                          <span slot="error" v-else>
                             <div class="error-msg">
                               <el-image :src="annotationAlertImgUrl" class="img"/>
                               <span class="txt">{{ verificationCodeMsg }}</span>
                             </div>
                           </span>
                        </el-form-item>
                      </el-row>
                    </div>
                    <div class="identity-title2">身分驗證　ID Verification</div>
                    <div class="error-msg" v-if="verificationIDErr">
                      <el-image :src="annotationAlertImgUrl" class="img"/>
                      <span class="txt">身份驗證失敗，請重新確認 Identity verification failed, please reconfirm.</span>
                    </div>
                    <div class="paymentFormItem2">
                      <el-row>
                        <el-form-item class="" style="font-weight: 400;" label="身分證字號 ID Card Number" prop="idCardNumber" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },{ validator: validateIdCardNumber}]:''">
                          <div style="display: flex;">
                            <el-input v-model="dataForm.idCardNumber" maxlength="10" @input="idCardNumberCheck($event)" class="right-image-input"/>
                            <el-image :src="dataForm.idCardNumber.length==10 ? cardImgUrl : cardRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ idCardNumberMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="發證日期 Issuance Date" prop="issuanceDate" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]:''">
                          <div style="display: flex;">
                            <el-select v-model="selectedYear" placeholder="年Year" @change="changeYear()">
                              <el-option v-for="(item,index) in yearOptions" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                            <span>/</span>
                            <el-select v-model="selectedMonth" clearable placeholder="月Month" @change="mothChange(dataForm.selectedMonth)">
                              <el-option v-for="(item,index) in monthList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disable"></el-option>
                            </el-select>
                            <span>/</span>
                            <el-select v-model="selectedDay" clearable placeholder="日Day">
                              <el-option v-for="(item,index) in dayList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disable"></el-option>
                            </el-select>
                          </div>
                          <span v-if="issuanceDateIsEmpty==''">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="身分證正反面照片 ID Card Photo" prop="idCardPhotoFrontResourceId" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]:''">
                          <div style="font-size: 16px;font-weight: 400;letter-spacing: 0.9px;">正面 Front</div>
                          <div style="background-color: #EDEDED; border-radius: 8px; width: 100%; text-align: center;">
                            <el-image :src="idCard1" style="width: 80%; margin: 0 auto;" v-if="idCard1"></el-image>
                            <el-upload class="upload-demo" :action="$http.adornUrl('/common/resource/uploadResource')" :on-success="icCardFrontSuccess" accept=".jpg,.png,.jpeg" multiple>
                              <el-button size="small" type="danger" style="background-color: #8F0304" v-if="idCard1">變更 Change</el-button>
                              <el-button size="small" type="danger" style="background-color: #8F0304; margin: 100px 0 90px 0;" v-else>上傳 Upload</el-button>
                              <div slot="file" slot-scope="{file}"></div>
                            </el-upload>
                          </div>
                          <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                    </div>
                    <div class="paymentFormItem" style="margin-top: 12px;">
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="發證地點 Issuance Location" prop="issuanceLocation" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:''">
                          <div style="display: flex;">
                            <el-select v-model="dataForm.issuanceLocation" placeholder="" style="width: 100%;">
                              <el-option
                                  v-for="item in issuanceLocationList"
                                  :key="item.code"
                                  :label="item.name"
                                  :value="item.code">
                              </el-option>
                            </el-select>
                          </div>
                          <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="領補換類別 Issuance Type" prop="issuanceType" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:''">
                          <div style="display: flex;">
                            <el-select v-model="dataForm.issuanceType" placeholder="" style="width: 100%;">
                              <el-option
                                  v-for="item in issuanceTypeList"
                                  :key="item.code"
                                  :label="item.name"
                                  :value="item.code">
                              </el-option>
                            </el-select>
                          </div>
                          <span slot="error">
                         <div class="error-msg">
                           <el-image :src="annotationAlertImgUrl" class="img"/>
                           <span class="txt">{{ formErrorMsg }}</span>
                         </div>
                       </span>
                        </el-form-item>
                      </el-row>
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="" prop="idCardPhotoBackResourceId" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }]:''">
                          <div style="font-size: 16px;font-weight: 400;letter-spacing: 0.9px; margin-top: 28px;">反面 Back</div>
                          <div style="background-color: #EDEDED; border-radius: 8px; width: 100%; text-align: center;">
                            <el-image :src="idCard2" style="width: 80%; margin: 0 auto;" v-if="idCard2"></el-image>
                            <el-upload class="upload-demo" :action="$http.adornUrl('/common/resource/uploadResource')" :on-success="icCardBackSuccess" accept=".jpg,.png,.jpeg" multiple>
                              <el-button size="small" type="danger" style="background-color: #8F0304" v-if="idCard2">變更 Change</el-button>
                              <el-button size="small" type="danger" style="background-color: #8F0304; margin: 100px 0 90px 0;" v-else>上傳 Upload</el-button>
                              <div slot="file" slot-scope="{file}"></div>
                            </el-upload>
                          </div>
                          <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                        </el-form-item>
                      </el-row>
                    </div>
                  </template>
                  <template v-if="verificationType == 2">
                    <div style="margin-bottom: 20px;" class="paymentFormItem">
                      <el-row>
                        <el-form-item style="font-weight: 400;" label="持卡人身分證字號末三碼 Last three digits of ID" prop="idCardNumberSecurityCode" :rules="isExistIdentity?[{ required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },]:''">
                          <div style="display: flex;">
                            <el-input @input="verificationCodeCheck($event)" maxlength="3" v-model="dataForm.idCardNumberSecurityCode" class="right-image-input"/>
                            <el-image :src="dataForm.creditCardNumber ? cardImgUrl : cardRImgUrl" class="right-image"/>
                          </div>
                          <span slot="error">
                             <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                             </div>
                         </span>
                          <div class="error-msg" v-if="verificationIDThreeErr">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">身分證資訊錯誤 ID information incorrect</span>
                          </div>
                        </el-form-item>
                      </el-row>
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <!-- 服務條款 -->
            <div v-if="payForm.payMethods">
              <div style="border-radius:10px; background: #FAF0CA;margin: 30px 0;padding: 20px;">
                <div type="text" @click="()=>{ agreement1Box = !agreement1Box}" class="box-txt-bl-s">一、個人資料提供同意書(Notification Letter of Collection, Processing and User of Personal Information)</div>
                <agreement1 v-if="agreement1Box"></agreement1>
                <div type="text" @click="()=>{ agreement2Box = !agreement2Box}" class="box-txt-bl-s" style="margin-top: 10px ;margin-left: 0px !important;">二、紅陽科技網路代理收付服務契約(SunTech Online Payment Processing Service Agreement)</div>
                <agreement2 v-if="agreement2Box"></agreement2>

                <div type="text" @click="()=>{ agreement3Box = !agreement3Box}" class="box-txt-bl-s" style="margin-top: 10px ;margin-left: 0px !important;">三、消費者個資移轉商店使用同意書(Consumer Personal Informations Transfer Store Use Consent Form)</div>
                <agreement3 v-if="agreement3Box"></agreement3>

                <div class="box-txt-rd" style="margin-top: 10px;">請主動求證付款資訊來源，切勿將個人資料提供給來路不明的第三方，若發現有不明管道之訊息可撥打165反詐騙諮詢專線。</div>
                <div class="box-txt-rd">Please proactively verify the source of payment information and refrain from providing personal information to unknown third parties. If you suspect you could be victim of a scam, protect your rights by calling the “165 Anti-Scam hotline”.</div>
                <div class="information-form-check">
                  <el-checkbox v-model="informationCk" style="margin-top: 20px;">
                    <div class="box-txt-bl-b" style="">本人已理解上揭個資法、服務條款及個資轉移使用，並同意其內容。</div>
                  </el-checkbox>
                </div>
                <div class="box-txt-bl-b" style="margin-left: 25px">I agree to the terms of Privacy Statment and Service Terms and Conditions.</div>
              </div>
              <div style="text-align: center;">
                <el-button type="danger" :disabled="!informationCk" :class="informationCk ? 'next-btn' : 'next-btn-dis'" @click="nextStep">確認送出 Confirm</el-button>
              </div>
            </div>
          </div>

        </transition>

        <!-- 底部圖片 -->
        <div>
          <div style="text-align: center;">
            <el-image style="max-width: 700px; margin-top: 20px;" :src="bannerImgUrl"></el-image>
          </div>
          <div style="text-align: center;">
            <el-image style="max-width: 900px; width: 100%; margin: 20px -30px" :src="footerImgUrl"></el-image>
          </div>
        </div>
      </div>

      <div style="width: 100%; height: 40px; background: #8F0304;"></div>

      <!--填寫驗證碼-->
      <new-ver-code-dialog ref="verCodeDialog" :modeCode="payForm.payMethods" :masterId="dataForm.masterId" :orderAmount="dataForm.transactionAmount" @goPay="goPay" @dialogClosed="dialogClosed"></new-ver-code-dialog>

    </el-form>
  </div>
</template>

<script>
import newVerCodeDialog from './compontents/new-ver-code-dialog'
import imgSlide from './compontents/img-slide'
import agreement1 from './agreement/agreement1'
import agreement2 from './agreement/agreement2'
import agreement3 from './agreement/agreement3'

import {
  getCurrentMonth,
  getCurrentYear
} from '@/api/credit'

import logoImg from '../assets/img/new-payment/logo-sunpay.png'
import rectangleOi from '../assets/img/new-payment/rectangle-oi.png'
import clockImg from '../assets/img/new-payment/clock-01.png'
import commodityBgImg from '../assets/img/new-payment/commodity-bg.png'
import dividerImg from '../assets/img/new-payment/divider.png'
import bannerImg from '../assets/img/new-payment/banner.png'
import masterImg from '../assets/img/new-payment/master.png'
import visaImg from '../assets/img/new-payment/visa.png'
import jcbImg from '../assets/img/new-payment/jcb.png'
import unionPayImg from '../assets/img/new-payment/unionPay.png'
import piluPayImg from '../assets/img/new-payment/piluPay.png'
import monthlPayImg from '../assets/img/new-payment/monthlPay.png'
import googlePayImg from '../assets/img/new-payment/google-pay.png'
import applePayImg from '../assets/img/new-payment/apple-pay.png'
import sumsungPayImg from '../assets/img/new-payment/sumsung-pay.png'

import inputProfileTickImg from '../assets/img/new-payment/input-profile-tick.png'
import annotationCheckImg from '../assets/img/new-payment/annotation-check.png'
import emailTxtImg from '../assets/img/new-payment/email-txt.png'
import emailTxtCNImg from '../assets/img/new-payment/email-txt-cn.png'
import productTxtImg from '../assets/img/new-payment/product-txt.png'
import annotationAlertImg from '../assets/img/new-payment/annotation-alert.png'

import footerImg from '../assets/img/new-payment/footer.png'
import footerFtImg from '../assets/img/new-payment/footer-first.png'
import footerKgiImg from '../assets/img/new-payment/footer-kgi.png'
import footerTsImg from '../assets/img/new-payment/footer-ts.png'
import footerUbImg from '../assets/img/new-payment/footer-ub.png'
import rememberTxtImg from '../assets/img/new-payment/remember-txt.png'

import arrowDownImg from '../assets/img/new-payment/input/arrow-down.png'
import arrowDownRImg from '../assets/img/new-payment/input/arrow-down-r.png'
import calendarTickImg from '../assets/img/new-payment/input/calendar-tick.png'
import calendarTickRImg from '../assets/img/new-payment/input/calendar-tick-r.png'
import componentImg from '../assets/img/new-payment/input/component.png'
import componentRImg from '../assets/img/new-payment/input/component-r.png'
import frameImg from '../assets/img/new-payment/input/frame.png'
import frameRImg from '../assets/img/new-payment/input/frame-r.png'
import mailImg from '../assets/img/new-payment/input/mail.png'
import mailRImg from '../assets/img/new-payment/input/mail-r.png'
import mapImg from '../assets/img/new-payment/input/map.png'
import mapRImg from '../assets/img/new-payment/input/map-r.png'
import profileTickImg from '../assets/img/new-payment/input/profile-tick.png'
import profileTickRImg from '../assets/img/new-payment/input/profile-tick-r.png'
import cardRImg from '../assets/img/new-payment/input/card-r.png'
import cardImg from '../assets/img/new-payment/input/card.png'
import carRImg from '../assets/img/new-payment/input/car-r.png'
import carImg from '../assets/img/new-payment/input/car.png'

import arrowLeftImg from '../assets/img/new-payment/arrow-left.png'
import arrowRightImg from '../assets/img/new-payment/arrow-right.png'
import refreshImg from '../assets/img/new-payment/refresh.png'

import avatar from '../assets/img/avatar.png'
import smsNoVerificationImg from '../assets/img/new-payment/input/shield-check.png'
import smsVerificationImg from '../assets/img/new-payment/input/shieldVerificatin-check.png'
// 眼睛圖示 睜眼/閉眼
import eye from '../assets/img/new-payment/eye.png'
import eyeSlash from '../assets/img/new-payment/eye_slash.png'

export default {
  name: 'init-no-logistics',
  components: {
    newVerCodeDialog,
    imgSlide,
    agreement1,
    agreement2,
    agreement3
  },
  data() {
    var validateCreditCardNumber = (rule, value, callback) => {
      if (this.global.isEmpty(this.dataForm.cardNumber) || this.dataForm.changeCard == '1') {
        if (value == '' || value == undefined || value == null) {
          callback(new Error('此欄位為必填！'))
        } else {
          let val = value.replace(/\s*/g, '');
          // 信用卡類別為信用卡
          if (this.dataForm.cardCategory == '0' && val.length !== 16) {
            callback(new Error('請輸入16位卡號'))
          } else if (this.dataForm.cardCategory == '1' && (val.length > 19 || val.length < 16)) {
            callback(new Error('請輸入16位-19位卡號'))
          }
        }
      }
      callback()
    }
    const checkCarrierId = (rule, value, callback) => {
      if (this.payForm.invoice == 2) { //手機載具
        let rg = /^\/[0-9A-Z.\-\+]{7}$/;
        if (rg.test(value)) {
          callback()
        } else {
          callback(new Error('"請輸入正確載具號碼格式！'))
        }
      } else {
        let rg = /[A-Z]{2}[0-9]{14}$/;
        if (rg.test(value)) {
          callback()
        } else {
          callback(new Error('"請輸入正確載具號碼格式！'))
        }
      }
    }
    return {
      radio: 3,
      phoneError: false,
      needPayer: '',
      paymentModeShow: false, // 是否顯示付款方式
      creditCardNumber1: '',  // 信用卡號第一組
      creditCardNumber2: '',  // 信用卡號第二組
      creditCardNumber3: '',  // 信用卡號第三組
      creditCardNumber4: '',  // 信用卡號第四組
      eyeShow: '',            // 顯示眼睛閉眼或是睜眼

      //物流
      logisticsList: [],
      //是否開啓物流 搭配物流1.是 0.否
      matchingLogistics: 0,
      logisticsModeList: [],
      //超商取貨付款的超商list
      logisticsModePaymentList: [],
      //超商取貨 不付款的超商list
      logisticsModeNoList: [],
      recipientInfoChecked: false,
      //捐贈號碼列表
      pobanList: [],
      tradeAmountFlg: 1, // 是否為固定交易金額 0:否 1:是
      payOrderInfo: {
        merchantDataDto: {},
        masterAndResourceDto: {},
        merchantOrderDataDto: {},
        deliverySetupDataDto: {},
        codeDataDto: {},
        payerDataDto: {},
        invoiceDataDto: {},
        logisticsDataDto: {}
      },
      slideImgList: [],
      modeCodeList: [],
      payMethodsCode: '',
      avatar: avatar,
      // slideImgList: [arrowLeftImg,refreshImg],
      mainLoading: false,
      cardNo: ['', '', '', ''],
      payHeaderIcon: require('../assets/img/payment/pay-icon-5.png'),
      twIcon: require('../assets/img/payment/pay-icon-4.png'),
      cardIcon1: require('../assets/img/payment/pay-icon-2.jpg'),
      cardIcon2: require('../assets/img/payment/pay-icon-1.jpg'),
      titleIcon: require('../assets/img/payment/pay-icon-3.png'),
      labelPosition: 'top',
      termsList: [], //信用卡分期設定期數
      errTerms: false,
      payForm: {
        // 付款方式
        payMethods: '',
        invoice: '0'
      },
      successAllPath: '',
      failAllPath: '',
      //身分驗證類型
      verificationType: 0,
      dataForm: {
        quickpayId: '',
        quickPayUrl: '',
        quickPayPathCode: '',
        amount: 1,
        masterId: '', //  訂單ID
        productName: '',
        productPrice: 0,
        productQuantity: 1,
        cardCategory: '',
        merchantCode: '',
        installmentsFlag: '',
        installmentsList: [],//分期list
        creditCardInstallment: '',
        // 信用卡卡號
        creditCardNumber: '',
        // 超過6位獲取卡型信息
        bank: '',
        cardType: '',
        // 有效期
        effectiveDate1: '',
        effectiveDate2: '',
        // 背面末三碼
        creditCardSecurityCode: '',
        // 持卡人姓名
        creditCardUserName: '',
        // 持卡人電話
        creditCardPhone: '',
        // 持卡人Email
        creditCardEmail: '',
        // 發卡銀行
        creditCardIssueBank: '',
        // 交易金額
        transactionAmount: '',
        mn: '',
        web: '',
        memo: '',
        orderInfo: '',
        agree: 0,
        uuid: '',// 驗證碼uuid
        captcha: '',// 驗證碼
        td: '',
        saveCard: '0',    // 是否保存卡號
        saveCardToken: '',// 信用卡token
        cardNumber: '',   // 已保存信用卡
        number: '',       // 信用卡
        merchantSaveCard: '', // 是否展示保存卡號
        changeCard: '0',      // 輸入卡號
        invoiceShowFlg: '',   // 發票顯示標識
        city: '',           // 城市
        area: '',           // 區域
        address: '',        // 地址
        buyerIdentifier: '',// 買受人統編
        carrierId: '',      // 手機條碼
        poban: '',          // 捐贈碼
        invoiceStatus: '',  // 發票狀態radio選項
        desc: '',
        invoiceHead: '',        // 發票抬頭
        invoicePaper: '0',      // 是否列印發票
        recipientName: '',      // 收件人
        recipientPhoneNumber: '',
        recipientEmail: '',
        shippingMethod: '10',   // 寄送方式 10.超商取貨
        logisticsModeCode: '',  // 物流編碼
        pickUpStoreName: '',    // 取货地址-門市店名
        pickUpAddress: '',      // 取货地址-門市地址
        pickUpStoreCode: '',    // 取货地址-門市店號
        pickUpStorePosition: '',
        // 身份驗證資訊
        cellphoneNumber: '',  // 持卡人手機
        verificationCode: '', // 驗證碼
        idCardNumber: '',     // 身份證
        issuanceLocation: '', // 發證地點
        issuanceDate: '',     // 發證日期
        issuanceType: '',     // 補換類別
        verificationFlag: '',
        idCardPhotoFrontResourceId: '',
        idCardPhotoBackResourceId: '',
        idCardNumberSecurityCode: ''
      },
      issuanceDateIsEmpty: '-',
      modeCodeisEmpty: false,
      invoicePaperShow: '0',
      invoicePaperChecked: false, //紙本發票選中狀態
      rules: {
        // city:[
        //   {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
        // ],
        // area:[
        //   {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
        // ],
        // address:[
        //   {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
        // ],
        carrierId: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {validator: checkCarrierId,}
        ],
        buyerIdentifier: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {
            pattern: /^\d{8}$/,
            message: "請輸入正確買受人統編格式",
          }
        ],
        poban: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
        ],
        invoiceHead: [
          {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
          {pattern: /^[^\s]+/, message: "請輸入正確發票抬頭格式"}
        ],
        // orderInfo: [
        //   {required: true, message: '此欄位不能為空', trigger: 'blur'}
        // ],
        // creditCardIssueBank: [
        //   {required: true, message: '此欄位不能為空', trigger: 'blur'},
        // ],
        // creditCardUserName: [
        //   {required: true, message: '此欄位不能為空', trigger: 'blur'}
        // ],
        // creditCardPhone: [
        // {required: true, message: '此欄位不能為空', trigger: 'blur'}
        // ],
        // payMethods: [
        //   {required: true, message: '此欄位不能為空', trigger: 'blur'}
        // ],
        // effectiveDate1: [
        //   {required: true, message: '此欄位不能為空', trigger: 'change'}
        // ],
        // effectiveDate2: [
        //   {required: true, message: '此欄位不能為空', trigger: 'change'}
        // ],
        // creditCardSecurityCode: [
        //   {required: true, message: '此欄位不能為空', trigger: ['blur','change']},
        //   // {type: 'number', message: '驗證碼必須為數字', trigger: ['blur','change']}
        // ],
        // cardCategory: [
        //   {required: true, message: '此欄位不能為空', trigger: 'change'}
        // ],
        // creditCardNumber: [
        //   {validator: validateCreditCardNumber, trigger: 'blur'}
        // ],
      },
      preventSubmitFlag: false,
      // 信用卡號是否顯示驗證信息
      showCardNoErrorText: false,
      // 校驗是否通過
      validatePass: false,
      binCode: '',
      agreeList: [false, false, false],
      agreeComponents: ['agreement1', 'agreement2', 'agreement3'],
      addCardType: '1',
      clockImgUrl: clockImg,
      logoImgUrl: logoImg,
      rectangleOiUrl: rectangleOi,
      commodityBgImgUrl: commodityBgImg, // 商品背景圖
      dividerImgUrl: dividerImg,
      bannerImgUrl: bannerImg,
      masterImgUrl: masterImg,
      visaImgUrl: visaImg,
      jcbImgUrl: jcbImg,
      unionPayImgUrl: unionPayImg,
      piluPayImgUrl: piluPayImg,
      monthlPayImgUrl: monthlPayImg,
      googlePayImgUrl: googlePayImg,
      applePayImgUrl: applePayImg,
      sumsungPayImgUrl: sumsungPayImg,
      idCard1: '',
      idCard2: '',

      arrowDownImgUrl: arrowDownImg,
      arrowDownRImgUrl: arrowDownRImg,
      calendarTickImgUrl: calendarTickImg,
      calendarTickRImgUrl: calendarTickRImg,
      componentImgUrl: componentImg,
      componentRImgUrl: componentRImg,
      frameImgUrl: frameImg,
      frameRImgUrl: frameRImg,
      mailImgUrl: mailImg,
      mailRImgUrl: mailRImg,
      mapImgUrl: mapImg,
      mapRImgUrl: mapRImg,
      profileTickImgUrl: profileTickImg,
      profileTickRImgUrl: profileTickRImg,
      cardRImgUrl: cardRImg,
      cardImgUrl: cardImg,
      carRImgUrl: carRImg,
      carImgUrl: carImg,
      smsNoVerificationImgUrl: smsNoVerificationImg,
      smsVerificationImgUrl: smsVerificationImg,
      eye: eye, // 眼睛圖示-睜眼
      eyeSlash: eyeSlash,// 眼睛圖示-閉眼

      // inputCardImgUrl: inputCardImg,
      // arrowDownImgUrl: arrowDownImg,
      // componentImgUrl: componentImg,
      // mailImgUrl: mailImg,
      // mapImgUrl: mapImg,
      // iconImgUrl: iconImg,
      inputProfileTickImgUrl: inputProfileTickImg,
      // calendarTickImgUrl: calendarTickImg,
      // frameImgUrl: frameImg,
      annotationCheckImgUrl: annotationCheckImg,
      emailTxtCNImgUrl: emailTxtCNImg,
      emailTxtImgUrl: emailTxtImg,
      productTxtImgUrl: productTxtImg,
      annotationAlertImgUrl: annotationAlertImg,

      footerImgUrl: footerImg,
      footerFtImgUrl: footerFtImg,
      footerKgiImgUrl: footerKgiImg,
      footerTsImgUrl: footerTsImg,
      footerUbImgUrl: footerUbImg,
      rememberTxtImgUrl: rememberTxtImg,

      formErrorMsg: '必填欄位不得為空白 Field required',
      validateInstallmentErr: '必填欄位不得為空白 Field required',

      checked: false,
      informationCk: false,

      dueDate: '', // 付款有效時間
      merchantName: '', //商店名稱
      orderName: '', //訂單名稱

      optMYList: [],

      validateEffectiveDate: undefined,
      validateCreditCardNumber: undefined,
      validateCellphoneNumber: undefined,
      validateVerificationCode: undefined,
      validateIdCardNumber: undefined,
      validateInstallment: undefined,
      EffectiveDateErMsg: '必填欄位不得為空白 Field required',
      creditCardNumberMsg: '必填欄位不得為空白 Field required',
      transactionAmountMsg: '必填欄位不得為空白 Field required',
      recipientPhoneMsg: '必填欄位不得為空白 Field required',
      idCardNumberMsg: '必填欄位不得為空白 Field required',
      cellphoneNumberMsg: '必填欄位不得為空白 Field required',
      verificationCodeMsg: '必填欄位不得為空白 Field required',
      recipientPhoneErr: false,

      agreement1Box: false,
      agreement2Box: false,
      agreement3Box: false,

      productNameList: [],

      arrowLeftImgUrl: arrowLeftImg,
      arrowRightImgUrl: arrowRightImg,
      refreshImgUrl: refreshImg,

      cityList: [],
      areaList: [],
      //是否存在身份驗證
      isExistIdentity: false,
      selectedYear: '',
      selectedMonth: '',
      selectedDay: '',
      yearOptions: [],
      monthList: [],
      dayList: [],
      issuanceLocationList: [],
      issuanceTypeList: [],
      //驗證碼倒計時
      count: '',
      //發送驗證碼的狀態 0默認可點 1xx秒後不可點  2重發-可點 3次數已過
      sendCodeStatus: '0',
      clickCount: 0,
      verificationCodeErr: false,
      verificationIDErr: false,
      verificationIDThreeErr: false,
      errCount: 0,
      cellphoneNumberErr: false,
    }
  },
  computed: {
    // 跳轉頁面
    routerName: {
      get() {
        return this.$store.state.credit.routerName
      },
      set(val) {
        this.$store.commit('credit/updateRouterName', val)
      }
    },
    payMethods: {
      get() {
        return this.$store.state.credit.payMethods
      },
      set(val) {
        this.$store.commit('credit/updatePayMethods', val)
      }
    },
    // 計算遮蔽的卡號部分
    maskedPart2() {
      return this.creditCardNumber2 ? '****' : '';
    },
  },
  mounted() {

    // this.mainLoading = true
    // 信用卡號是否顯示驗證信息
    this.showCardNoErrorText = false
    // 校驗是否通過
    this.validatePass = false

    this.dataForm.quickPayUrl = this.$route.params.code1;
    this.dataForm.quickPayPathCode = this.$route.params.code2;

    // 獲取全部支付方式
    this.getModeCode();
    //  獲取網址付全部內容
    this.getInfo();
    // 獲取城市下拉選
    this.getCityCodeName();
    // 獲取捐贈碼列表
    this.getPobanDataList();
    this.initSelectYearMonth();
    this.getLocationList();
    this.getIssuanceTypeList();
    //金額的支付方式判斷 &身份驗證判斷
    this.checkAmount();
  },
  methods: {
    // 變更信用卡顯示模式
    changeEyeShow() {
      this.eyeShow = !this.eyeShow
    },
    logisticsModeCodeChange() {
      this.dataForm.pickUpStorePosition = "";
    },
    initSelectYearMonth() {
      let year = new Date().getFullYear();
      year = year - 1911;
      for (var i = year; i >= 0; i--) {
        if (i < 10) {
          i = '00' + i
        } else if (i < 100) {
          i = '0' + i
        }
        this.yearOptions.push(i);
      }
      for (var i = 1; i <= 12; i++) {
        if (i < 10) {
          this.monthList.push({value: '0' + i, lable: '0' + i, disable: false});
        } else {
          this.monthList.push({value: i, lable: i, disable: false});
        }
      }
    },
    changeYear() {
      this.selectedMonth = '';
      this.selectedDay = '';
      let nowYear = new Date().getFullYear();
      let nowMonth = new Date().getMonth() + 1;
      let nowDay = new Date().getDate();
      if (this.selectedYear && parseInt(this.selectedYear) == nowYear - 1911) {
        this.monthList.forEach((item) => {
          item.disable = false;
          if (parseInt(item.value) > nowMonth) {
            item.disable = true;
          } else {
            item.disable = false;
          }
        })
      } else {
        this.monthList.forEach((item) => {
          item.disable = false;
        })
      }
    },
    mothChange(moth) {
      let daysInMonth = new Date(parseInt(this.selectedYear) + 1911, this.selectedMonth, 0).getDate();
      let days = [];
      for (var i = 1; i <= daysInMonth; i++) {
        if (i < 10) {
          days.push({value: '0' + i, lable: '0' + i, disable: false});
        } else {
          days.push({value: i, lable: i, disable: false});
        }
      }
      this.selectedDay = '';
      this.dayList = days;
      let nowYear = new Date().getFullYear();
      let nowMonth = new Date().getMonth() + 1;
      let nowDay = new Date().getDate();
      if (parseInt(this.selectedYear) + 1911 == nowYear && parseInt(this.selectedMonth) == nowMonth) {
        this.dayList.forEach((item) => {
          item.disable = false;
          if (parseInt(item.value) > nowDay) {
            item.disable = true;
          } else {
            item.disable = false;
          }
        })
      } else {
        this.dayList.forEach((item) => {
          item.disable = false;
        })
      }
    },
    getLocationList() {
      this.$http({
        url: this.$http.adornUrl('verify/issuanceLocationList'),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.issuanceLocationList = data.body;
        }
      })
    },
    getIssuanceTypeList() {
      this.$http({
        url: this.$http.adornUrl('verify/issuanceTypeList'),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.issuanceTypeList = data.body;
        }
      })
    },
    //獲取短信驗證碼
    getVerificationCode() {
      this.$http({
        url: this.$http.adornUrl('verify/code/phone'),
        method: 'get',
        params: this.$http.adornParams({
          account: this.dataForm.cellphoneNumber,
          merchantCode: this.dataForm.merchantCode,
          merchantOrderId: this.dataForm.orderId,
          amount: this.dataForm.transactionAmount
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.sendCodeStatus = '1';
          let TIME_COUNT = 60;
          this.clickCount++;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count > 1 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                if (this.clickCount == 1) {
                  this.sendCodeStatus = '2';
                } else {
                  this.sendCodeStatus = '3';
                }
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      })
    },
    changeCellphoneNumber(val) {
      this.dataForm.verificationCode = '';
      this.cellphoneNumberCheck(this.dataForm.cellphoneNumber);
    },
    getVerificationType() {
      this.$http({
        url: this.$http.adornUrl('order/verificationInfo'),
        method: 'post',
        data: this.$http.adornData({
          phoneNumber: this.dataForm.cellphoneNumber
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.verificationType = data.body.verificationType
        }
      })
    },
    checkPhone(phone) {
      let regex = /^09\d{8}$/;
      if (!regex.test(phone)) {
        return true
      } else {
        return false
      }
    },
    cellphoneNumberCheck(val) {
      let rg = /^09\d{8}$/;
      if (this.global.isEmpty(val)) {
        this.validateCellphoneNumber = (rule, value, callback) => {
          this.cellphoneNumberMsg = '必填欄位不得為空白 Field required';
          callback(new Error())
        }
      } else {
        this.dataForm.cellphoneNumber = val.replace(/[^\d]/g, '');
        if (!rg.test(val)) {
          this.validateCellphoneNumber = (rule, value, callback) => {
            this.cellphoneNumberMsg = '手機號碼格式錯誤 Wrong mobile number';
            this.cellphoneNumberErr = true;
            callback(new Error())
          }
        } else {
          this.validateCellphoneNumber = (rule, value, callback) => {
            this.cellphoneNumberErr = false;
            this.getVerificationType();
            callback()
          }
        }
      }
      return
    },
    verificationCodeCheck(val) {
      if (this.global.isEmpty(val)) {
        this.validateVerificationCode = (rule, value, callback) => {
          this.verificationCodeMsg = '必填欄位不得為空白 Field required';
          callback(new Error())
        }
      } else {
        this.dataForm.verificationCode = val.replace(/[^\d]/g, '');
        if (val.length != 6) {
          this.validateVerificationCode = (rule, value, callback) => {
            this.verificationCodeMsg = '請輸入完整驗證碼 Please enter correct Verification Code';
            callback(new Error())
          }
        } else {
          this.validateVerificationCode = (rule, value, callback) => {
            callback()
          }
        }
      }
      return
    },
    idCardNumberCheck(val) {
      let reg = /^[A-Z]\d{9}$/;
      if (this.global.isEmpty(val)) {
        this.validateIdCardNumber = (rule, value, callback) => {
          this.idCardNumberMsg = '必填欄位不得為空白 Field required';
          callback(new Error())
        }
      } else {
        if (!reg.test(val)) {
          this.validateIdCardNumber = (rule, value, callback) => {
            this.idCardNumberMsg = '身分證字號錯誤 ID card number is incorrect';
            callback(new Error())
          }
        } else {
          this.validateIdCardNumber = (rule, value, callback) => {
            callback()
          }
        }
      }
    },
    //身份驗證 持卡人手機同步付款人電話
    validatePhoneNumber() {
      if (this.dataForm.creditCardPhone && this.isExistIdentity) {
        this.dataForm.cellphoneNumber = this.dataForm.creditCardPhone;
        this.dataForm.verificationCode = '';
        this.getVerificationType();
      }
      if (!this.global.isEmpty(this.dataForm.creditCardPhone)) {
        // 台灣手機號碼正則表達式
        const regex = /^09\d{8}$/;
        if (!regex.test(this.dataForm.creditCardPhone)) {
          this.phoneError = true;
        } else {
          this.phoneError = false;
        }
      } else {
        this.phoneError = false;
      }
    },
    //信用卡和現金（取貨不付款）；超商取貨（取貨付款） 則可以選物流廠商
    // logisticsModeDisable(item){
    // 	if((this.payForm.payMethods =='DOMESTIC_CREDIT_CARD'|| this.payForm.payMethods =='UNION_CARD'
    // 	||this.payForm.payMethods =='SUPER_MARKET_CODE'||this.payForm.payMethods =='SUPER_MARKET_BAR'
    // 	||this.payForm.payMethods =='VIRTUAL_ACCOUNT') && item.shipmentType.indexOf('1')>-1 ||
    // 	this.payForm.payMethods =='SUPER_PAY_PICKUP' && item.shipmentType.indexOf('0')>-1
    // 	){
    // 		return false
    // 	}
    // 	return true
    // },
    recipientInfoCheckChange(val) {
      if (val) {
        this.dataForm.recipientEmail = this.dataForm.creditCardEmail;
        this.dataForm.recipientName = this.dataForm.creditCardUserName;
        this.dataForm.recipientPhoneNumber = this.dataForm.creditCardPhone;
      } else {
        this.dataForm.recipientEmail = '';
        this.dataForm.recipientName = '';
        this.dataForm.recipientPhoneNumber = '';
      }
    },
    changeAgree(index) {
      // 协议子组件默认初始化显示中文
      this.agreeComponents.forEach((agreeItemRef, aIndex) => {
        if (this.$refs[agreeItemRef]) {
          this.$refs[agreeItemRef].init()
        }
      })
      // 第几个协议展开
      this.agreeList[index] = !this.agreeList[index]
      // 当前未点击的协议收起
      this.agreeList.forEach((item, i) => {
        if (index !== i) {
          this.agreeList[i] = false
        }
      })
      this.$forceUpdate()
    },
    // 取得商店信用卡分期設定期數
    getMerchantInstallments() {
      this.loading = true
      this.$http({
        url: this.$http.adornUrl('/quickpay/quickpay/getMerchantInstallments'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          quickPayPathCode: this.dataForm.quickPayPathCode,
          creditCardNumber: this.binCode.replace(/\s*/g, ''),
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.termsList = data.body
        } else {
          this.$message.error(data.resultMessage)
        }
        this.loading = false
      })
    },
    // ????
    setBinCode() {
      let binCode = this.dataForm.creditCardNumber
      this.binCode = binCode.replace(/\s*/g, '').replace(/-/g, '')
      if (this.binCode.length > 10) {
        this.binCode = this.binCode.substr(0, 9)
      }
      if ((this.binCode.length == 6 || this.binCode.length == 8 || this.binCode.length == 9) && this.termsList.length == 0) {
        // 取得商店信用卡分期設定期數
        this.getMerchantInstallments()
      }
    },
    // 四碼卡號輸入時驗證
    changecreditCardNumber(number, modelKey) {
      let newNumber = number.replace(/\s/g, '').replace(/[^\d]/g, '')
      // 塞入信用卡號(creditCardNumber1~4)
      this[modelKey] = newNumber
      // 四組卡號的列表，依序查詢
      let CardNumberList = ['creditCardNumber1', 'creditCardNumber2', 'creditCardNumber3', 'creditCardNumber4']
      // 如果輸入的卡號>=4 ，開始依照順序檢查檢查其餘卡號是否輸入完成，假如未輸入完成，則跳至下一個未完成之輸入框
      if (newNumber.length >= 4) {
        CardNumberList.forEach((val, key, array) => {
          // 設定下一個key
          let selectKey = key < 3 ? (key + 1) : 0
          if (modelKey === val) {
            // 檢查下一個輸入框是否有值，假如現在的key是3，那就切換回第一個
            // 如果下一個輸入框是空值，或是輸入長度未達四位，將就將焦點切換到下一個輸入框
            if (this[CardNumberList[selectKey]] === '' || this[CardNumberList[selectKey]].length < 4) {
              this.$refs[CardNumberList[selectKey]].focus();
            }
          }
        });
      }
      // 如果四組卡號都輸入完成
      // 將卡號同步道
      this.changeCreditCardNumber(this.creditCardNumber1 + this.creditCardNumber2 + this.creditCardNumber3 + this.creditCardNumber4)
    },
    // 格式化信用卡卡號
    changeCreditCardNumber(val) {
      // 驗證卡號
      this.creditCardNumberCheck(val)
      // 1.將所有空格移除 2.去除非數字的字元 3.信用卡號超過 19 位數時加上空格。
      let cardNumber = val.replace(/\s/g, '').replace(/[^\d]/g, '').replace(/(\d{19})(?=\d)/g, '$1 ')
      // 1.移除非數字字元。 2.每 4 位數字之後插入短橫線（-）。 3.確保最後一位的短橫線不會多餘地出現。
      cardNumber = cardNumber.replace(/\D+/g, '').replace(/(\d{4})/g, '$1-').replace(/-$/, '')

      this.dataForm['creditCardNumber'] = cardNumber
      // 信用卡類別 選擇信用卡時 調接口獲取分期列表
      if (this.dataForm.cardCategory == '0') {
        this.setBinCode()
      } else {
        this.termsList = []
      }
    },
    //切換分期數
    changeInstallments() {
      if (this.dataForm.creditCardNumber && this.termsList) {
        let list = this.global.getAry_forProp(this.termsList, 'installments'); //提取數組
        if (list.indexOf(this.dataForm.creditCardInstallment) == -1) {
          if (this.dataForm.creditCardInstallment == '') { //不分期
            this.errTerms = false;
          } else {
            this.$message.error('此信用卡不支援該分期');
            this.errTerms = true;
          }
        } else {
          this.errTerms = false;
        }
        this.validateInstallment = (rule, value, callback) => {
          if (value == null || value == undefined) {
            this.validateInstallmentErr = '必填欄位不得為空白 Field required';
            callback(new Error())
          } else if (this.errTerms) {
            this.validateInstallmentErr = '此信用卡不支援該分期';
            callback(new Error())
          }
          callback()
        }
      } else {
        this.dataForm.creditCardInstallment == ''
      }
    },
    //金額的支付方式判斷 &身份驗證判斷
    checkAmount() {
      // 關閉顯示付款方式
      this.paymentModeShow = false;
      if (this.dataForm.transactionAmount != null && this.dataForm.transactionAmount !== '' && this.dataForm.transactionAmount > 0) {
        console.log('金額的支付方式判斷 &身份驗證判斷2')
        //取得支付方式-根據輸入的金額
        this.checkAmountPaymentMode();

      }
    },
    //取得支付方式-根據輸入的金額
    checkAmountPaymentMode() {
      this.loading = true;
      // this.mainLoading = true;
      this.$http({
        url: this.$http.adornUrl('/quickpay/quickpay/checkAmountPaymentMode'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          quickPayPathCode: this.dataForm.quickPayPathCode,
          amount: this.dataForm.transactionAmount
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          // 獲取可用付款方式
          this.modeCodeList = data.body.modeCodeList;
          if (this.modeCodeList.find(item => item.modeCode === 'DOMESTIC_CREDIT_CARD')) {
            this.payForm.payMethods = 'DOMESTIC_CREDIT_CARD';
            this.dataForm.cardCategory = '0'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_MARKET_CODE')) {
            this.payForm.payMethods = 'SUPER_MARKET_CODE'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_MARKET_BAR')) {
            this.payForm.payMethods = 'SUPER_MARKET_BAR'
          } else if (this.modeCodeList.find(item => item.modeCode === 'VIRTUAL_ACCOUNT')) {
            this.payForm.payMethods = 'VIRTUAL_ACCOUNT'
          } else if (this.modeCodeList.find(item => item.modeCode === 'UNION_CARD')) {
            this.payForm.payMethods = 'UNION_CARD'
            this.dataForm.cardCategory = '1'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_PAY_PICKUP')) {
            this.payForm.payMethods = 'SUPER_PAY_PICKUP'
          }

          // 顯示付款畫面
          this.paymentModeShow = true;
          // 判斷是否需要身分驗證(網址付用)
          this.checkAmountShowOtp();
        } else {
          // 關閉付款畫面
          this.paymentModeShow = false;

          // this.$router.push({name: 'orderfinish', query: {msg: data.resultMessage}})
          // this.$message.error(data.resultMessage);
          this.modeCodeList = [];
          this.$message.error(data.resultMessage);
        }
        // this.loading = false;
        // this.mainLoading = false;
      })
    },
    //判斷是否需要身分驗證(網址付用)
    checkAmountShowOtp() {
      this.$http({
        url: this.$http.adornUrl('/verify/verificationFlag'),
        method: 'get',
        params: this.$http.adornParams({
          amount: this.dataForm.transactionAmount
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          //是否需要身分驗證【0 不需要】【1 需要】
          this.dataForm.verificationFlag = data.body.verificationFlag;
          if (this.dataForm.verificationFlag == '1') {
            this.isExistIdentity = true;
          } else {
            this.isExistIdentity = false;
          }
        }
      })
    },

    changeTradeAmount(val, model) {
      val = val.replace(/\s/g, '').replace(/[^\d.]/g, '');
      // 轉換為整數

      console.log("測試" + val)
      if (val) {
        val = parseInt(val, 10);
      }


      this.dataForm.transactionAmount = val;
      this.payOrderInfo.merchantOrderDataDto.tradeAmount = val;
    },
    inputCard(text, num) {
      if ((this.cardNo.length < 4 || this.cardNo[1].length < 4 || this.cardNo[2].length < 4 || this.cardNo[3].length < 4)) {
        this.showCardNoErrorText = true
      }
      this.$nextTick(() => {
        if (text.length == 4 && num <= 3) {
          if (this.$refs['cardNo' + (num + 1)]) {
            this.$refs['cardNo' + (num + 1)].focus()
          }
        }
        if (text.length == 0 && num !== 0) {
          if (this.$refs['cardNo' + (num - 1)]) {
            this.$refs['cardNo' + (num - 1)].focus()
          }
        }
      })
      // 取得商店信用卡分期設定期數
      this.getMerchantInstallments()
    },
    goodsNumSub() {
      if (this.dataForm.productQuantity > 1) {
        this.dataForm.productQuantity -= 1;
        this.dataForm.transactionAmount = (this.payOrderInfo.merchantOrderDataDto.tradeAmount * this.dataForm.productQuantity).toString();
      }
    },
    goodsNumAdd() {
      this.dataForm.productQuantity += 1;
      this.dataForm.transactionAmount = (this.payOrderInfo.merchantOrderDataDto.tradeAmount * this.dataForm.productQuantity).toString();
    },
    getModeCode() {
      this.$http({
        url: this.$http.adornUrl('/quickPay/quickPayOrder/getModeCode'),
        method: 'get',
        params: {}
      }).then(({data}) => {
        if (data && data.resultCode == 200) {

        }
      })
    },
    //去重
    filterRepeatList(list, repeatField) {
      if (list != null && list.length > 0) {
        //1.先用 map () 拿到所有重複的字段
        const codeArr = list.map(item => {
          return item[repeatField]
        })
        //2.再用 filter () 去過濾： 使用indexOf()拿到 codeArr 數組 每一項的下標 (重複的返回第一次遇見的下標) 恆等於沒篩選數組的下標 返回符合條件的所有數組 拿到篩選後去重的數組
        let filterAfterList = list.filter((item, index) => {
          return codeArr.indexOf(item[repeatField]) === index
        })
        return filterAfterList;
      }
    },
    repeatList(list, repeatField) {
      if (list != null && list.length > 0) {
        //1.先用 map () 拿到所有重複的字段
        let codeArr = list.map(item => {
          return item[repeatField]
        })
        return codeArr
      }
    },
    changeLogisticsMode() {
      this.dataForm.pickUpStorePosition = "";
      if (this.payForm.payMethods == 'SUPER_PAY_PICKUP') {
        this.logisticsModeList = this.logisticsModePaymentList;
      } else {
        this.logisticsModeList = this.logisticsModeNoList;
      }
    },
    // 取得訂單檔案
    getInfo() {
      this.loading = true
      this.$http({
        url: this.$http.adornUrl('/quickpay/quickpay/allData'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          quickPayPathCode: this.dataForm.quickPayPathCode,
          userQuickPayPathCode: ''
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.payOrderInfo = data.body;
          this.loading = false
          this.mainLoading = false
          this.slideImgList = data.body.masterAndResourceDto.resourceIdList; //商品圖片
          this.$nextTick(() => {
            this.$refs.slideImg.initSwiper();
          })
          //是否顯示付款咨詢
          this.needPayer = data.body.payerDataDto.needPayer;
          if (this.needPayer == '0') {
            this.dataForm.creditCardEmail = data.body.email;
          }
          //物流
          this.logisticsModePaymentList = [];
          this.logisticsModeNoList = [];
          if (data.body.logisticsDataDto != null && data.body.logisticsDataDto.logisticsModeCodeStatus != null) {
            this.logisticsList = data.body.logisticsDataDto.logisticsModeCodeStatus;
            this.logisticsList.forEach((item) => {
              if (item.shipmentType == '0') { //0 取貨付款  1取貨bu付款
                this.logisticsModePaymentList.push(item);
              } else {
                this.logisticsModeNoList.push(item);
              }
            })
          }
          this.changeLogisticsMode();
          if (data.body.logisticsDataDto != null && data.body.logisticsDataDto.matchingLogistics != null) {
            this.matchingLogistics = data.body.logisticsDataDto.matchingLogistics;
          }


          // this.logisticsModeList = this.filterRepeatList(this.logisticsList,'modeCode');
          // let count = 0;
          // let codeArray = this.repeatList(this.logisticsList,'modeCode');
          // if(this.logisticsModeList!=null && this.logisticsModeList.length>0){
          // 	for (var i = 0; i < this.logisticsModeList.length; i++) {
          // 		for (var j = 0; j < codeArray.length; j++) {
          // 			if(this.logisticsModeList[i].modeCode == codeArray[j]){
          // 				count++;
          // 			}
          // 		}
          // 		if(count>1){
          // 			this.logisticsModeList[i].shipmentType = '01';
          // 		}
          // 		count = 0;
          // 	}
          // }
          console.log(this.logisticsModeList);


          // 设置付款方式
          this.modeCodeList = data.body.codeDataDto.modeCodeList;

          if (this.modeCodeList.find(item => item.modeCode === 'DOMESTIC_CREDIT_CARD')) {
            this.payForm.payMethods = 'DOMESTIC_CREDIT_CARD';
            this.dataForm.cardCategory = '0'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_MARKET_CODE')) {
            this.payForm.payMethods = 'SUPER_MARKET_CODE'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_MARKET_BAR')) {
            this.payForm.payMethods = 'SUPER_MARKET_BAR'
          } else if (this.modeCodeList.find(item => item.modeCode === 'VIRTUAL_ACCOUNT')) {
            this.payForm.payMethods = 'VIRTUAL_ACCOUNT'
          } else if (this.modeCodeList.find(item => item.modeCode === 'UNION_CARD')) {
            this.payForm.payMethods = 'UNION_CARD'
            this.dataForm.cardCategory = '1'
          } else if (this.modeCodeList.find(item => item.modeCode === 'SUPER_PAY_PICKUP')) {
            this.payForm.payMethods = 'SUPER_PAY_PICKUP'
          }

          this.dataForm.orderId = data.body.merchantOrderDataDto.orderId;
          this.dataForm.productName = data.body.masterAndResourceDto.goodsName;
          this.dataForm.productPrice = data.body.merchantOrderDataDto.tradeAmount;
          this.dataForm.productQuantity = '1';
          this.dataForm.merchantCode = data.body.merchantDataDto.merchantCode;
          this.dataForm.invoiceShowFlg = data.body.invoiceDataDto.invoiceShowFlg;
          this.dataForm.installmentsFlag = data.body.codeDataDto.installmentsFlag;
          this.dataForm.installmentsList = data.body.codeDataDto.installmentsList;//分期
          this.dataForm.creditCardUserName = data.body.sna;
          //this.dataForm.invoicePaper = data.body.invoiceDataDto.invoicePaper;
          this.invoicePaperShow = data.body.invoiceDataDto.invoicePaper;
          this.dataForm.invoicePrintMode = data.body.invoiceDataDto.invoicePrintMode;
          this.dataForm.quickpayId = data.body.quickpayId
          if (this.payOrderInfo.merchantOrderDataDto.tradeAmount == 0) {
            this.tradeAmountFlg = 0
            this.dataForm.transactionAmount = '';
          } else {
            this.tradeAmountFlg = 1;
            this.dataForm.transactionAmount = data.body.merchantOrderDataDto.tradeAmount;
            //是否需要身分驗證【0 不需要】【1 需要】
            this.dataForm.verificationFlag = data.body.verificationFlag;
            if (this.dataForm.verificationFlag == '1') {
              this.isExistIdentity = true;
            } else {
              this.isExistIdentity = false;
            }
          }
          this.dataForm.orderInfo = data.body.masterAndResourceDto.goodsName;
          this.dataForm.cellphoneNumber = this.dataForm.creditCardPhone ? this.dataForm.creditCardPhone : '';
          this.dataForm.verificationCode = '';
          if (this.dataForm.cellphoneNumber) {
            this.getVerificationType();
          }
          // this.cellphoneNumberCheck(this.dataForm.cellphoneNumber);

          return false;


          if (data.body.logoResourceId) {
            this.getImageLogo(data.body.logoResourceId)
          }
          this.dataForm.mn = data.body.mn
          this.dataForm.transactionAmount = data.body.mn
          this.dataForm.creditCardUserName = data.body.sna
          this.dataForm.creditCardPhone = data.body.sdt
          this.dataForm.merchantCode = data.body.web
          this.dataForm.web = data.body.web
          // this.dueDate =  data.body.dueDate
          this.dueDate = data.body.createTime
          this.merchantName = data.body.merchantName
          this.dataForm.installmentsFlag = data.body.installmentsFlag
          this.productNameList = data.body.productNameList.map(item => {
            return item.productName
          })
          this.orderName = this.productNameList[0]
          //信用卡token
          this.dataForm.saveCardToken = data.body.saveCardToken
          //是否顯示保存選項
          this.dataForm.merchantSaveCard = data.body.merchantSaveCard
          this.dataForm.invoiceShowFlg = data.body.invoiceShowFlg
          //已保存信用卡
          this.dataForm.cardNumber = data.body.cardNumber
          if (this.global.isEmpty(this.dataForm.cardNumber)) {
            this.dataForm.saveCard = '0'
          } else {
            this.dataForm.saveCard = '1'
          }
          this.dataForm.number = data.body.number
          this.dataForm.creditCardNumber = data.body.number
          if (!this.global.isEmpty(data.body.creditCardValidFrom)) {
            this.dataForm.effectiveDate1 = data.body.creditCardValidFrom.substr(2, 2);
            this.dataForm.effectiveDate2 = data.body.creditCardValidFrom.substr(0, 2);
            this.dataForm.effectiveDate = data.body.creditCardValidFrom.substr(2, 4) + "/" + data.body.creditCardValidFrom.substr(0, 2);
          }

          // this.dataForm.orderInfo = data.body.orderInfo

        } else {
          this.$router.push({name: 'orderfinish', query: {msg: data.resultMessage}})
          // this.$message.error(data.resultMessage)
        }
        this.loading = false
        this.mainLoading = false
      })
    },
    getImageLogo(id) {
      this.$http({
        url: this.$http.adornUrl('/merchant/url/readPartImage/' + id),
        method: 'get',
        responseType: 'blob'
      }).then(({data}) => {
        let blob = new Blob([data]);
        const URL = window.URL || window.webkitURL;
        const fileUrl = URL.createObjectURL(blob);
        this.logoImgUrl = fileUrl
      })
    },
    changePaperCheck() {
      if (this.invoicePaperChecked) { //紙本發票選中
        this.dataForm.invoicePaper = '1';
      } else {
        this.dataForm.invoicePaper = '0';
        this.dataForm.city = '';
        this.dataForm.area = '';
        this.dataForm.address = '';
      }
    },

    /**
     * 下一步
     */
    nextStep() {
      // this.goPage('super-code-info')
      if (this.dataForm.invoicePaper == '1' && (this.payForm.invoice == 0 || this.payForm.invoice == 3)) {
        if (!this.dataForm.city || !this.dataForm.area || !this.dataForm.address) {
          return
        }
      }
      //判斷付款人手機號格式
      if (this.phoneError) {
        return
      }
      if (this.matchingLogistics && this.recipientPhoneErr) {
        return
      }
      //手機條碼//捐贈
      if (this.payForm.invoice == 2 || this.payForm.invoice == 4) {
        this.dataForm.invoicePaper = '0';
      }
      let invoiceAddress = ''
      if (this.dataForm.city != '') {
        this.dataForm.invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName
      }
      if (this.dataForm.city != '' && this.dataForm.area != '') {
        this.dataForm.invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == this.dataForm.area
        }).areaName + this.dataForm.address
      }
      this.dataForm.invoiceStatus = this.payForm.invoice;
      //發證時間
      if (this.isExistIdentity && this.verificationType == 1) {
        if (this.selectedYear && this.selectedMonth && this.selectedDay) {
          this.dataForm.issuanceDate = this.selectedYear + this.selectedMonth + this.selectedDay;
          this.issuanceDateIsEmpty = '-';
        } else {
          this.issuanceDateIsEmpty = '';
          return
        }

      }
      //當前分期數  不可選
      if (this.errTerms) {
        return
      }
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.payForm.payMethods == 'DOMESTIC_CREDIT_CARD') {
            this.dataForm.cardCategory = '0'
          }
          if (this.payForm.payMethods == 'UNION_CARD') {
            this.dataForm.cardCategory = '1'
          }
          this.checkedPaymentMethod();
          //this.openConfirmDialog(this.dataForm)
        }
      })

    },
    checkedPaymentMethod() {
      this.loading = true
      this.mainLoading = true
      this.$http({
        url: this.$http.adornUrl('/quickPay/quickPayOrder/checkPaymentMethodStatus'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          modeCode: this.payForm.payMethods,
          logisticsModeCode: this.dataForm.logisticsModeCode
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          if (this.payForm.payMethods != 'SUPER_PAY_PICKUP') {
            this.checkedAmount();
          } else {
            this.openConfirmDialog(this.dataForm)
            // this.loading = false
            // this.mainLoading= false
          }
        } else {
          this.$message.error(data.resultMessage);
          this.loading = false
          this.mainLoading = false
        }
      })
    },
    checkedAmount() {
      this.$http({
        url: this.$http.adornUrl('/quickPay/quickPayOrder/checkAmount'),
        method: 'get',
        params: this.$http.adornParams({
          quickPayUrl: this.dataForm.quickPayUrl,
          modeCode: this.payForm.payMethods,
          amount: this.dataForm.transactionAmount,
          quickPayPathCode: this.dataForm.quickPayPathCode,
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.openConfirmDialog(this.dataForm)
          // this.loading = false
          // this.mainLoading= false
        } else {
          this.$message.error(data.resultMessage);
          this.loading = false
          this.mainLoading = false
        }
      })
    },
    scrollViewToError() {
      this.$nextTick(() => {
        let isError = document.getElementsByClassName('is-error')
        if (isError) {
          isError[0].scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          })
        }
      })
    },
    /**
     * 填寫驗證碼
     */
    openConfirmDialog(row) {
      this.$nextTick(() => {
        this.$refs['verCodeDialog'].init(row)
      })
    },
    dialogClosed() {
      console.log('dialogClosed')
      this.loading = false
      this.mainLoading = false
    },
    goPay(form) {
      console.log(form)
      this.dataForm.masterId = form.masterId;
      this.routerName = 'init-no-logistics'
      // 超商代碼繳費
      if (this.payForm.payMethods == 'SUPER_MARKET_CODE') {
        this.$router.push({
          name: 'super-code-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo
          }
        })
      } else if (this.payForm.payMethods == 'SUPER_MARKET_BAR') {
        // 超商條碼繳費
        this.$router.push({
          name: 'super-bar-code-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo,
            creditCardUserName: this.dataForm.creditCardUserName
          }
        })
      } else if (this.payForm.payMethods == 'VIRTUAL_ACCOUNT') {
        // 虛擬帳號轉帳
        this.$router.push({
          name: 'virtual-account-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo,
            creditCardUserName: this.dataForm.creditCardUserName
          }
        })
      } else if (this.payForm.payMethods == 'SUPER_PAY_PICKUP') {
        //超商取貨
        this.$router.push({
          name: 'super-pay-pickup-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo
          }
        })
      } else if (this.payForm.payMethods == 'GOOGLE_PAY') {
        // google pay
        this.submitFormGooglePay()
      } else if (this.payForm.payMethods == 'APPLE_PAY') {
        // apple pay
        this.submitFormApplePay()
      } else {
        // 信用卡繳費：走saveCreditCardData接口，
        // 多傳兩個參數 captcha驗證碼  uuid驗證碼UUID
        this.dataForm.captcha = form.captcha
        this.dataForm.uuid = form.uuid
        this.submitForm()
      }
      // iphone Pay或Google Pay或台灣Pay
    },
    //applePay支付前處理相關數據
    submitFormApplePay() {
      let invoiceAddress = ''
      if (this.dataForm.city != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName
      }
      if (this.dataForm.city != '' && this.dataForm.area != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == this.dataForm.area
        }).areaName + this.dataForm.address
      }
      let form = {
        'masterId': this.dataForm.masterId,
        'creditCardUserName': this.dataForm.creditCardUserName,
        'creditCardPhone': this.dataForm.creditCardPhone,
        'creditCardEmail': this.dataForm.creditCardEmail,
        'invoiceAddress': invoiceAddress,
        'carrierId': this.dataForm.carrierId,
        'buyerIdentifier': this.dataForm.buyerIdentifier,
        'poban': this.dataForm.poban,
        'invoiceStatus': this.payForm.invoice,
        'invoicePrintMode': this.dataForm.invoicePrintMode,
        'invoicePaper': this.dataForm.invoicePaper,
        'invoiceHead': this.dataForm.invoiceHead,
        'orderSource': '09'
      }
      this.$http({
        url: this.$http.adornUrl(`order/applepay/submitForm`),
        method: 'post',
        data: this.$http.adornData(form)
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          // 開啓收銀台apple pay支付畫面
          let newWin = window.open('', '_self')
          newWin.document.write(data.body)
          newWin.document.close()
        } else {
          console.log('[submitFormApplePay]=>fail')
        }
      })
    },
    //googlePay支付前處理相關數據
    submitFormGooglePay() {
      let invoiceAddress = ''
      if (this.dataForm.city != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName
      }
      if (this.dataForm.city != '' && this.dataForm.area != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == this.dataForm.area
        }).areaName + this.dataForm.address
      }
      let form = {
        'masterId': this.dataForm.masterId,
        'creditCardUserName': this.dataForm.creditCardUserName,
        'creditCardPhone': this.dataForm.creditCardPhone,
        'creditCardEmail': this.dataForm.creditCardEmail,
        'invoiceAddress': invoiceAddress,
        'carrierId': this.dataForm.carrierId,
        'buyerIdentifier': this.dataForm.buyerIdentifier,
        'poban': this.dataForm.poban,
        'invoiceStatus': this.payForm.invoice,
        'invoicePrintMode': this.dataForm.invoicePrintMode,
        'invoicePaper': this.dataForm.invoicePaper,
        'invoiceHead': this.dataForm.invoiceHead,
        'orderSource': '10'
      }
      this.$http({
        url: this.$http.adornUrl(`order/googlepay/submitForm`),
        method: 'post',
        data: this.$http.adornData(form)
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          // 開啓收銀台google pay支付畫面
          let newWin = window.open('', '_self')
          newWin.document.write(data.body)
          newWin.document.close()
        } else {
          console.log('[submitFormGooglePay]=>fail')
        }
      })
    },
    submitForm() {
      let number = ''
      if (!this.global.isEmpty(this.dataForm.creditCardNumber)) {
        number = this.dataForm.creditCardNumber.replace(/\s*/g, '').replace(/-/g, '')
      }
      console.log(this.dataForm.effectiveDate)
      debugger
      let invoiceAddress = ''
      if (this.dataForm.city != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName
      }
      if (this.dataForm.city != '' && this.dataForm.area != '') {
        invoiceAddress = this.cityList.find(item => {
          return item.cityId == this.dataForm.city
        }).cityName + this.areaList.find(item => {
          return item.areaId == this.dataForm.area
        }).areaName + this.dataForm.address
      }
      let creditCardValidFrom = '';
      if (this.dataForm.effectiveDate && this.dataForm.effectiveDate.length >= 5) {
        creditCardValidFrom = this.dataForm.effectiveDate.substr(3, 5) + this.dataForm.effectiveDate.substr(0, 2);
      }
      let form = {
        'masterId': this.dataForm.masterId,
        'web': this.dataForm.merchantCode,
        'mn': this.dataForm.transactionAmount,
        'creditCardNumber': number,
        'creditCardValidFrom': creditCardValidFrom,
        // 'creditCardValidFrom': this.dataForm.effectiveDate2 + this.dataForm.effectiveDate1,
        'creditCardSecurityCode': this.dataForm.creditCardSecurityCode,
        'creditCardUserName': this.dataForm.creditCardUserName,
        'creditCardPhone': this.dataForm.creditCardPhone,
        'creditCardEmail': this.dataForm.creditCardEmail,
        'cardCategory': this.dataForm.cardCategory,
        'creditCardInstallment': this.dataForm.creditCardInstallment,
        'captcha': this.dataForm.captcha,
        'uuid': this.dataForm.uuid,
        'saveCard': this.dataForm.saveCard,
        'changeCard': this.dataForm.changeCard,
        'saveCardToken': this.dataForm.saveCardToken,
        // 'orderInfo': this.dataForm.orderInfo
        'invoiceAddress': invoiceAddress,
        'carrierId': this.dataForm.carrierId,
        'buyerIdentifier': this.dataForm.buyerIdentifier,
        'poban': this.dataForm.poban,
        'invoiceStatus': this.payForm.invoice,
        'remark': this.dataForm.desc,
        'orderInfo': this.dataForm.orderInfo,
        'invoicePrintMode': this.dataForm.invoicePrintMode,
        'invoicePaper': this.dataForm.invoicePaper,
        'invoiceHead': this.dataForm.invoiceHead,
        'recipientName': this.dataForm.recipientName,
        'recipientPhoneNumber': this.dataForm.recipientPhoneNumber,
        'recipientEmail': this.dataForm.recipientEmail,
        'pickUpStoreName': this.dataForm.pickUpStoreName,
        'pickUpAddress': this.dataForm.pickUpAddress,
        'pickUpStoreCode': this.dataForm.pickUpStoreCode,
        'shippingMethod': this.dataForm.shippingMethod,
        'logisticsModeCode': this.dataForm.logisticsModeCode,
        'cardholderCellphoneNumber': this.dataForm.cellphoneNumber,
        'smsVerificationCode': this.dataForm.verificationCode,
        'idCardNumber': this.dataForm.idCardNumber,
        'issuanceLocation': this.dataForm.issuanceLocation,
        'issuanceDate': this.dataForm.issuanceDate,
        'issuanceType': this.dataForm.issuanceType,
        'verificationFlag': this.dataForm.verificationFlag,
        'idCardPhotoFrontResourceId': this.dataForm.idCardPhotoFrontResourceId,
        'idCardPhotoBackResourceId': this.dataForm.idCardPhotoBackResourceId,
        'idCardNumberSecurityCode': this.dataForm.idCardNumberSecurityCode
      }
      this.$http({
        url: this.$http.adornUrl(`/order/saveCreditCardData`),
        method: 'post',
        data: this.$http.adornData(form)
      }).then(({data}) => {
        this.preventSubmitFlag = false;
        this.verificationCodeErr = false;
        this.verificationIDErr = false;
        this.verificationIDThreeErr = false;
        if (data && data.resultCode == 200) {
          console.log(data.body)
          if (data.body) {
            let newWin = window.open('', '_self')
            newWin.document.write(data.body)
            newWin.document.close()
          } else {
            // 非3D授權
            // this.$router.push({name: 'success'})
            // 處理支付結果
            if (this.successAllPath) {
              this.noticePaymentResultToMerchant(this.successAllPath)
            } else {
              this.gotoSelfPaymentResult(1)
            }
          }
        } else if (data.resultCode == 98) { //驗證碼錯誤1(或2)次
          this.$message.error(data.resultMessage);
          this.errCount++;
          this.verificationCodeErr = true;
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 97) {  //驗證碼錯誤3次，交易失敗
          this.$message.error(data.resultMessage);
          this.gotoSelfPaymentResult(0)
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 96) {  //身分驗證錯誤1(或2)次
          this.$message.error(data.resultMessage);
          this.verificationIDErr = true;
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 95) {  //身分證末三碼錯誤
          this.$message.error(data.resultMessage);
          this.verificationIDThreeErr = true;
          this.loading = false
          this.mainLoading = false
        } else if (data.resultCode == 94) {  //身分驗證錯誤3次，交易失敗
          this.$message.error(data.resultMessage);
          this.gotoSelfPaymentResult(0)
          this.loading = false
          this.mainLoading = false
        } else {
          this.loading = false
          this.mainLoading = false
          // this.$message.error(data.resultMessage)
          if ('驗證碼錯誤，請重新輸入' == data.resultMessage) {
            // this.getCaptcha()
            this.$message.error(data.resultMessage)
          } else {
            if (this.failAllPath) {
              this.noticePaymentResultToMerchant(this.failAllPath)
            } else {
              // TODO 待處理
              this.gotoSelfPaymentResult(0)
            }
          }
        }
      })
    },
    // 獲取驗證碼
    // getCaptcha () {
    //   this.dataForm.uuid = getUUID()
    //   this.captchaPath = this.$http.adornUrl(`/order/captcha.jpg?uuid=${this.dataForm.uuid}`)
    // },
    noticePaymentResultToMerchant(url) {
      this.$http({
        url: this.$http.adornUrl('/receive/return/data'),
        method: 'post',
        data: this.$http.adornData({
          // 訂單ID
          masterId: this.dataForm.masterId
        })
      }).then(({data}) => {
        if (data && data.resultCode == 200) {
          this.sendForm(url, data.body)
        } else {
          this.$message.error(data.resultMessage)
        }
      })
    },
    sendForm: function (url, data) {
      let newWin = window.open('', '_self')
      let text = '<!DOCTYPE HTML>'
      text = text + '<html lang="en">'
      text = text + '<head>'
      text = text + '  <meta charset="UTF-8">'
      text = text + '  <meta name="viewport" content="width=device-width, initila-scale=1.0">'
      text = text + '  <title></title>'
      text = text + '</head>'
      text = text + '<script type="text/javascript">'
      text = text + '    function autoSubmit(){'
      text = text + '      document.getElementById("sendForm").submit();'
      text = text + '    }'
      text = text + ' <\/script>'
      text = text + '<body onload="autoSubmit();">'
      text = text + '<form action="'
      text = text + url
      text = text + '" method="post" id="sendForm">'
      text = text + '  <input type="hidden" id="web" name="web" value="' + data.web + '"/>'
      text = text + '  <input type="hidden" id="send_time" name="send_time" value="' + data.sendTime + '"/>'
      text = text + '  <input type="hidden" id="rsamsg" name="rsamsg" value="' + data.rsaMsg + '"/>'
      text = text + '  <input type="hidden" id="check_value" name="check_value" value="' + data.checkValue + '"/>'
      text = text + '</form>'
      text = text + '</body>'
      text = text + '</html>'
      newWin.document.write(text)
      newWin.document.close()
    },
    setPaymentResult() {
      this.$http({
        url: this.$http.adornUrl('/quickpay/quickpay/allData'),
        method: 'get',
        params: {
          // 訂單ID
          quickPayUrl: this.dataForm.quickPayUrl,
          quickPayPathCode: this.dataForm.quickPayPathCode,
          userQuickPayPathCode: ''
        }
      }).then(({data}) => {
        console.log('merchant/url/info', data)
        if (data && data.resultCode == 200) {
          this.successAllPath = data.body.successAllPath
          this.failAllPath = data.body.failAllPath
        }
      })
    },
    gotoSelfPaymentResult(errorFlag) {
      if (errorFlag == 1) {
        this.$router.push({
          name: 'card-info',
          query: {
            masterId: this.dataForm.masterId,
            transactionAmount: this.dataForm.transactionAmount,
            // orderInfo: this.dataForm.orderInfo,
            uuid: this.dataForm.uuid
          }
        })
      } else {
        this.$router.push({name: 'error', query: {id: this.dataForm.masterId}})
      }
    },
    scrollInto(id) {
      const yOffset = -20
      const element = document.getElementById(id)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({top: y, behavior: 'smooth'})
    },
    /**
     * 切換付款方式
     */
    changePayMethods(val) {
      this.$nextTick(() => {
        this.$forceUpdate()

        // if (this.$refs['dataForm']) {
        //   this.$refs['dataForm'].resetFields()
        // }
        // if (this.$refs['dataForm1']) {
        //   this.$refs['dataForm1'].resetFields()
        // }
        this.cardNo = ['', '', '', '']
        this.dataForm.effectiveDate1 = ''
        this.dataForm.effectiveDate2 = ''
        this.dataForm.creditCardSecurityCode = ''
        this.dataForm.cardCategory = ''
        this.dataForm.creditCardNumber = ''
        // 付款方式：選擇信用卡
        if (this.payForm.payMethods == 'DOMESTIC_CREDIT_CARD') {
          // 信用卡類別默認  信用卡
          this.$set(this.dataForm, 'cardCategory', '0')
        }
      })
      // 信用卡號是否顯示驗證信息
      this.showCardNoErrorText = false
    },
    /**
     * 切換信用卡類別
     */
    changeBankType(val) {
      this.$nextTick(() => {
        if (this.$refs['dataForm']) {
          this.$refs['dataForm'].resetFields()
        }
        if (this.$refs['effectiveDate1']) {
          this.$refs['effectiveDate1'].resetField()
        }
        if (this.$refs['effectiveDate2']) {
          this.$refs['effectiveDate2'].resetField()
        }
        if (this.$refs['creditCardSecurityCode']) {
          this.$refs['creditCardSecurityCode'].resetField()
        }
        if (this.$refs['creditCardNumber']) {
          this.$refs['creditCardNumber'].resetField()
        }
        this.cardNo = ['', '', '', '']
        this.dataForm.creditCardInstallment = ''
        this.dataForm.effectiveDate1 = ''
        this.dataForm.effectiveDate2 = ''
        this.dataForm.effectiveDate = ''
        this.dataForm.creditCardSecurityCode = ''
        this.dataForm.creditCardNumber = ''
      })
      // 信用卡號是否顯示驗證信息
      this.showCardNoErrorText = false
    },
    changeAddType(val) {
      this.$refs.dataForm1.resetFields("creditCardNumber");
      if (val == '0') {
        this.dataForm.creditCardNumber = this.dataForm.number;
      } else {
        this.dataForm.creditCardNumber = ''
      }
    },
    handleOpen() {
      if (this.global.isEmpty(this.dataForm.logisticsModeCode)) {
        this.modeCodeisEmpty = true;
      } else {
        this.modeCodeisEmpty = false;
        this.getStoreMap();
      }

    },
    handleChange(val) {
      console.log(val)
      this.dataForm.effectiveDate1 = val[0]
      this.dataForm.effectiveDate2 = val[1]
    },
    effectiveDateCheck(val) {
      let jss = val.replace(
          /[^0-9]/g, '' // To allow only numbers
      ).replace(
          /^([2-9])$/g, '0$1' // To handle 3 > 03
      ).replace(
          /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
      ).replace(
          /^0{1,}/g, '0' // To handle 00 > 0
      ).replace(
          /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
      );

      this.dataForm.effectiveDate = jss
      console.log()

      this.EffectiveDateErMsg = ''

      if (val.length < 5) {
        console.log(val.length)
        this.validateEffectiveDate = (rule, value, callback) => {
          this.EffectiveDateErMsg = val.length > 0 ? '請輸入正確有效年月' : '必填欄位不得為空白 Field required'
          callback(new Error())
        }
      } else {
        if (val[0] + val[1] > 12) {
          this.validateEffectiveDate = (rule, value, callback) => {
            this.EffectiveDateErMsg = '請輸入正確有效年月'
            callback(new Error())
          }
        } else {
          this.validateEffectiveDate = (rule, value, callback) => {
            callback()
          }
        }
      }
    },
    checkRecipientPhone() {
      this.recipientPhoneMsg = '必填欄位不得為空白 Field required';
      if (!this.global.isEmpty(this.dataForm.recipientPhoneNumber)) {
        // 台灣手機號碼正則表達式
        const regex = /^09\d{8}$/;
        if (!regex.test(this.dataForm.recipientPhoneNumber)) {
          this.recipientPhoneMsg = '請輸入正確的手機號碼';
          this.recipientPhoneErr = true;
        } else {
          this.recipientPhoneErr = false;
        }
      } else {
        this.recipientPhoneMsg = '必填欄位不得為空白 Field required';
      }
    },

    // 驗證卡號
    creditCardNumberCheck(val) {
      this.creditCardNumberMsg = '必填欄位不得為空白 Field required'
      if (val.indexOf(" ") == -1 && val.length == 16) {
        this.validateCreditCardNumber = (rule, value, callback) => {
          callback()
        }
        return
      }
      if (val.length != 19) {
        this.validateCreditCardNumber = (rule, value, callback) => {
          if (val.length == 0) {
            this.creditCardNumberMsg = '必填欄位不得為空白 Field required'
          } else {
            this.creditCardNumberMsg = '卡號輸入不完整'
          }
          callback(new Error())
        }
      } else {
        this.validateCreditCardNumber = (rule, value, callback) => {
          callback()
        }
      }
    },
    nextOrderName(type) {
      if (this.productNameList.length == 0) {
        return
      }
      let index = this.productNameList.indexOf(this.orderName)
      if (type == 0) {
        if (index > 0) {
          this.orderName = this.productNameList[index - 1]
        }
      }
      if (type == 1) {
        if (index != -1 && index < this.productNameList.length - 1) {
          this.orderName = this.productNameList[index + 1]
        }
      }
      if (type == 2) {
        this.orderName = this.productNameList[0]
      }
    },
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      // 增加20分鐘
      var min = new Date(date.getTime() + 1000 * 60 * 20)
      const formatObj = {
        y: min.getFullYear(),
        m: min.getMonth() + 1,
        d: min.getDate(),
        h: min.getHours(),
        i: min.getMinutes(),
        s: min.getSeconds(),
        a: min.getDay()
      }
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
          return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return time_str
    },
    //縣市名稱
    getCityCodeName() {
      this.$http({
        url: this.$http.adornUrl(`/order/getCityName`),
        method: 'get'
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          this.cityList = data.body;
        } else {
          this.cityList = [];
        }
      }).catch((e) => {
      })
    },
    //獲取縣市名稱下面區域名
    choseCity(cityId) {
      if (cityId == '') {
        return
      }
      this.$http({
        url: this.$http.adornUrl('/order/getAreaName/' + cityId),
        method: 'get'
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          this.areaList = data.body;
        } else {
          this.areaList = [];
        }
      }).catch((e) => {
      })
    },
    //清空城市區域下來選
    clearSelect(form, model) {
      this[form][model] = ""
      this.dataForm.city = ''
      this.areaList = []
    },
    //發票radio改變
    changeInvoiceStatus(val) {
      this.dataForm.city = '';
      this.dataForm.area = '';
      this.dataForm.buyerIdentifier = '';
      this.dataForm.carrierId = '';
      this.dataForm.poban = '';
      this.dataForm.address = '';
      this.areaList = [];
      this.dataForm.invoiceHead = '';
    },
    // 獲取捐贈號碼數據列表
    getPobanDataList() {
      this.$http({
        url: this.$http.adornUrl('/sys/donateinvoice/getList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.resultCode === 200) {
          if (data.body) {
            this.pobanList = data.body;
          } else {
            this.pobanList = [];
          }
        }
      })
    },
    getStoreMap() {
      let storeType = '';
      let serviceType = '';
      if (this.dataForm.logisticsModeCode == 'OK_PU_CODE') {
        storeType = '4'
      } else if (this.dataForm.logisticsModeCode == 'LYLFU_PU_CODE') {
        storeType = '3'
      } else if (this.dataForm.logisticsModeCode == 'FAMILY_PU_CODE') {
        storeType = '2'
      } else if (this.dataForm.logisticsModeCode == '7-ELEVEN_PU_CODE') {
        storeType = '1'
      }
      //信用卡和現金（取貨不付款）  超商取貨（取貨付款）
      if ((this.payForm.payMethods == 'DOMESTIC_CREDIT_CARD' || this.payForm.payMethods == 'UNION_CARD'
          || this.payForm.payMethods == 'SUPER_MARKET_CODE' || this.payForm.payMethods == 'SUPER_MARKET_BAR'
          || this.payForm.payMethods == 'VIRTUAL_ACCOUNT')) {
        serviceType = '2'
      } else if (this.payForm.payMethods == 'SUPER_PAY_PICKUP') {
        serviceType = '1'
      }
      let reqData = [
        {key: 'storeType', val: storeType},    //取貨超商 1:7-11 2:全家 3:萊爾富 4:OK
        {key: 'serviceType', val: serviceType}, //付款類型 1：取貨付款 2：取貨不付款
        {key: 'device', val: 'pc'}     //使用裝置 pc OR mobile
      ]
      // 打開新視窗
      var newWindow = window.open("", '_blank', 'width=900,height=700');
      // 在新窗口中生成表單
      var form = newWindow.document.createElement('form');
      form.action = 'https://freight.sunpay.com.tw/web/StoreMap';
      form.method = 'post';
      newWindow.document.body.appendChild(form);
      reqData.forEach((item) => {
        var input = newWindow.document.createElement('input');
        input.type = 'hidden';
        input.name = item.key;
        input.value = item.val;
        form.appendChild(input);
      })
      form.submit();
      //監聽新窗口事件
      var _that = this;
      window.addEventListener('message', function (event) {
        if (event.data["resultCode"] == "00") {
          let resultCodeData = event.data["data"];

          console.log(resultCodeData);
          _that.dataForm.pickUpStoreName = resultCodeData.receiverStoreName;
          _that.dataForm.pickUpAddress = resultCodeData.receiverStoreAddress;
          _that.dataForm.pickUpStoreCode = resultCodeData.receiverStoreid;
          _that.dataForm.pickUpStorePosition = _that.dataForm.pickUpStoreName + " " + _that.dataForm.pickUpAddress;

        } else if (event.data["resultCode"] !== undefined && event.data["resultCode"] !== "00") {
          Swal.fire('發生錯誤', event.data["resultMessage"], 'error')
        }
      });
    },
    icCardFrontSuccess(response, file, fileList) {
      this.dataForm.idCardPhotoFrontResourceId = response.body.resourceId;
      this.global.getBlob(response.body.resourceId, (result) => {
        this.idCard1 = result;
      })
    },
    icCardBackSuccess(response, file, fileList) {
      this.dataForm.idCardPhotoBackResourceId = response.body.resourceId;
      this.global.getBlob(response.body.resourceId, (result) => {
        this.idCard2 = result;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/payment.scss";

.card-form-line.el-divider.el-divider--horizontal {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}

::v-deep .el-input {
  .el-input-group__prepend {
    border: 0px;
    color: #525252;
    font-size: 16px;
    font-weight: 400;
  }
}

::v-deep .el-form--label-top {
  .el-form-item {
    margin-top: 10px !important;

    .el-input__inner {
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }

    .el-textarea__inner {
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }

    &__label {
      line-height: 23.12px !important;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.9px;
      padding: 0 0 5px !important;
      color: #525252 !important;

    }

    .el-form-item__error {
      color: #FF0000 !important
    }
  }
}

::v-deep .ol-el-input {
  .el-input__inner {
    text-align: center;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }

  .el-textarea__inner {
    text-align: center;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }
}

::v-deep .le-el-input {
  .el-input__inner {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }

  .el-textarea__inner {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }
}


::v-deep .right-image-input {
  .el-input__inner {
    padding-right: 60px;
  }

}

::v-deep .selet-drop {
  .el-input__suffix {
    display: none;
  }

  .el-select-dropdown__item.selected {
    color: #78B689 !important;
    font-weight: 700;
  }
}

::v-deep .el-cascader .el-input .el-icon-arrow-down {
  display: none;
}

.card-image {
  width: 20%;
  height: 10%;
  margin-top: 11px;
  margin-left: 5px;
  //position: absolute;
  //right: 13px;
  //top: 6px;
}

.right-image {
  width: 38px;
  position: absolute;
  right: 13px;
  top: 6px;
}

.order-txt {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.pay-rad-txt {
  font-weight: 600;
  color: #2A2A2A;
  font-size: 16px;
  line-height: 42px;
}

.box-txt-bl-s {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 20px;
}

.box-txt-bl-b {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  line-height: 25px;
}

.box-txt-rd {
  font-weight: 600;
  color: #8F0304;
  font-size: 16px;
  line-height: 20px;
}

.error-msg {
  display: flex;
  flex-direction: row;
  line-height: 20px;
  position: absolute;

  .img {
    width: 16px;
    height: 16px;
    margin-top: 3px;
  }

  .txt {
    margin-left: 5px;
    color: #FF0000;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
  }
}

.pay-form-radio ::v-deep .el-radio__inner {
  border: 2px solid #949494;
  width: 15px;
  height: 15px;
}

.pay-form-radio ::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #1DB145 !important;
  border: 2px solid #1DB145 !important;
  background: #ffffff !important;
}

.pay-form-radio ::v-deep .el-radio__inner::after {
  width: 6px;
  height: 6px;
  background-color: #1DB145 !important;
}

.pay-form-radio ::v-deep .el-radio__input {
  color: #2A2A2A;
}

.pay-form-radio ::v-deep .el-radio__label {
  color: #2A2A2A;
  font-size: 20px !important;
  font-weight: bold;
}

.pay-form-radio ::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #2A2A2A;
  font-size: 20px !important;
  font-weight: bold;
}

.pay-form-check ::v-deep .el-checkbox {
  color: #2A2A2A !important;
}

.pay-form-check ::v-deep .el-checkbox__label {
  font-size: 20px !important;
  font-weight: bold;
}

.pay-form-check ::v-deep .el-checkbox__inner {
  border: 1px solid #606060 !important;
  width: 16px !important;
  height: 16px !important;
}

.pay-form-check ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #606060 !important;
}

.pay-form-check ::v-deep .el-checkbox__inner:hover {
  // border: 1px solid #606060;
  border-color: #606060 !important;
}

.pay-form-check ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #2A2A2A;
  font-size: 20px !important;
}

.pay-form-check ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #1DB145;
  border-color: #1DB145;
}

.pay-form-check ::v-deep .el-checkbox__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  border: 2px solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 1px;
  width: 3px;
  -webkit-transition: -webkit-transform .15s ease-in .05s;
  transition: -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.information-form-check ::v-deep .el-checkbox {
  color: #8F0304 !important;
}

.information-form-check ::v-deep .el-checkbox__label {
  font-size: 16px !important;
}

.information-form-check ::v-deep .el-checkbox__inner {
  border: 1px solid #8F0304 !important;
  width: 16px !important;
  height: 16px !important;
}

.information-form-check ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #8F0304 !important;
}

.information-form-check ::v-deep .el-checkbox__inner:hover {
  // border: 1px solid #606060;
  border-color: #8F0304 !important;
}

.information-form-check ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #8F0304;
  font-size: 16px !important;
}

.information-form-check ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #8F0304;
  border-color: #8F0304;
}

.information-form-check ::v-deep .el-checkbox__inner::after {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  border: 2px solid #FFF;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 1px;
  width: 3px;
  -webkit-transition: -webkit-transform .15s ease-in .05s;
  transition: -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.next-btn {
  line-height: 25px;
  background: #8F0304;
  font-weight: 600;
  font-size: 26px;
  height: 64px;
  width: 320px;
}

.next-btn-dis {
  line-height: 25px;
  background: #a6a6a6;
  border: #a6a6a6;
  font-weight: 600;
  font-size: 26px;
  height: 64px;
  width: 320px;
}

.next-btn-dis ::v-deep .el-button--danger.is-disabled, .el-button--danger.is-disabled:active, .el-button--danger.is-disabled:focus, .el-button--danger.is-disabled:hover {
  color: #FFF;
  background-color: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
}

.box-payForm-card {
  margin-left: -15px;

  .txt {
    font-size: 8px;
    font-weight: 400;
    color: #525252;
    width: 200px;
    margin-left: -5px;
    margin-top: 10px
  }

  .img {
    width: 200px;
    margin-left: -5px
  }
}

.box-payForm-card ::v-deep .el-checkbox {
  color: #525252 !important;
}

.box-payForm-card ::v-deep .el-checkbox__inner {
  border: 1px solid #606060 !important;
}

.box-payForm-card ::v-deep .el-checkbox__input {
  margin-top: -45px;
}

.box-payForm-card ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #606060 !important;
}

.box-payForm-card ::v-deep .el-checkbox__inner:hover {
  // border: 1px solid #606060;
  border-color: #606060 !important;
}

.box-payForm-card ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #525252;
}

.box-payForm-card ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #606060;
  border-color: #606060;
}

.g-b {
  padding-top: 50px;
  padding-bottom: 0px;
}

.w-b {
  //padding: 0 140px !important;
  border-radius: 38px 38px 0 0;
}

.top-logo {
  width: 100%;
  //height: 250px;
  //margin-top: -30px;
  text-align: center;
}

.payment-valid-until {
  display: inline-block;
  vertical-align: middle;
  color: #9D2323;
  font-size: 16px;
  font-weight: 600;
}

.clock-img {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin: 1px 5px;
}

.tb-txt {
  display: inline-block;
  vertical-align: middle;
  color: #8F918E;
  font-size: 16px;
  font-weight: 600;
}

.or-information {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .title-24 {
    font-size: 24px;
    font-weight: 600;
    color: #03328D;
  }

  .title-16 {
    font-size: 16px;
    font-weight: 600;
    color: #03328D;
    line-height: 42px;
    margin-left: 10px;
  }

  .bk-img {
    width: 252px;
    height: 24px;
    margin-top: 16px;
    margin-left: 12px;
    position: absolute;
  }

  .mer-name-l {
    color: #8F918E;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    width: 210px;
  }

  .mer-name-r {
    color: #8F918E;
    font-size: 16px;
    font-weight: 400;
    margin-left: -10px;
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 210px)
  }

  .order-name {
    color: #2A2A2A;
    font-weight: 600;
    font-size: 26px;
    margin-top: 10px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    // width: calc(100% - 200px);
    width: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  .order-amount-l {
    color: #8F918E;
    font-weight: 400;
    font-size: 16px;
    padding-top: 18px;
    border-top: 2px dashed #E3E3E3;
    width: 83%;
  }

  .order-amount-r {
    font-weight: 600
  }

  .pay-amount {
    color: #9D2323;
    font-weight: 600;
    font-size: 24px;
    margin-top: 10px;
  }
}

.pay-method {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .tit-area {
    display: flex;
  }

  .tit-cn {
    font-size: 24px;
    font-weight: 600;
    color: #03328D;
  }

  .tit-en {
    font-size: 16px;
    font-weight: 600;
    color: #03328D;
    line-height: 42px;
    margin-left: 10px;
  }

  .tit-bk {
    width: 292px;
    height: 24px;
    margin-top: 16px;
    margin-left: 12px;
    position: absolute;
  }

  .cre-card-img-1 {
    width: 18px;
    height: 18px;
    margin-left: 5px;
    top: 3px;
  }

  .cre-card-img-2 {
    width: 26px;
    height: 25px;
    margin-left: 1px;
    top: 7px;
  }

  .cre-card-img-3 {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }

  .gg-img {
    width: 50px;
    height: 26px;
    margin-left: 3px;
    top: 5px;
  }

  .ap-img {
    width: 50px;
    height: 19px;
    margin-left: 3px;
    top: 5px;
  }

  .su-img {
    width: 50px;
    height: 22px;
    margin-left: 3px;
    top: 6px;
  }

  .un-card {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }

  .piiluu {
    width: 18px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }

  .n-card {
    width: 140px;
    height: 18px;
    margin-left: 3px;
    top: 3px;
  }
}

.pay-information {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .tit-area {
    display: flex
  }

  .tit-cn {
    font-size: 24px;
    font-weight: 600;
    color: #03328D;
  }

  .tit-en {
    font-size: 16px;
    font-weight: 600;
    color: #03328D;
    line-height: 42px;
    margin-left: 10px;
  }

  .tit-bg {
    width: 275px;
    height: 24px;
    margin-top: 16px;
    margin-left: 12px;
    position: absolute;
  }
}

@font-face {
  font-family: 'myFont';
  src: url('../assets/font/jost-black.ttf');
}

.self-font {
  font-family: 'myFont', serif;
  font-size: 16px;
}

.tit-no {
  font-size: 16px;
  font-weight: 600;
  color: #C0C0C0;
  margin-top: 10px;
  position: absolute;
  right: 49%;
}

@media(max-width: 990px) {
  .tit-no {
    position: relative;
    right: 0;
    text-align: center;
  }
}


::v-deep .el-radio__label {
  font-size: 14px !important;
}

.paymentMethod {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

.order-txt-center {
  width: calc(100% - 80px);
}

@media(max-width: 767px) {
  .paymentMethod {
    display: block;
    width: 100%;
  }
  .or-information .order-name {
    width: calc(100% - 100px) !important;
  }
  .order-txt-center {
    width: calc(100% - 40px);
  }
}

.paymentFormItem {
  display: inline-block;
  vertical-align: top;
  width: 48%;
}

.paymentFormItem2 {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin-top: 12px;
}

.paymentFormItem ~ .paymentFormItem {
  margin-left: 2%;
}

@media(max-width: 991px) {
  .paymentFormItem {
    display: block;
    width: 100%;
  }
  .paymentFormItem2 {
    display: block;
    width: 100%;
  }
  .paymentFormItem ~ .paymentFormItem {
    margin-left: 0;
  }
  .recipientInfoChecked {
    margin-top: 20px !important;
  }
}

.information-form-check ::v-deep .box-txt-bl-b {
  white-space: break-spaces;
  vertical-align: top;
}

.information-form-check ::v-deep .el-checkbox__input {
  vertical-align: top;
  margin-top: 4px;
}

.invoiceItem {
  display: inline-block;
  vertical-align: top;
  width: 48%;
}

.slide-box {
  width: 80%;
  margin: 30px auto;
  text-align: center;
}

.slide-box ::v-deep .el-carousel {
  &__button {
    height: 16px;
    width: 16px;
    border-radius: 10px;
    background-color: #999999;
  }

  &__indicator.is-active {
    .el-carousel__button {
      background-color: #9D2323;
    }
  }

  &__indicator--horizontal {
    padding: 20px 4px 12px 4px;
  }

  &__arrow {
    display: none !important;
  }

  &__item {
    background-color: #dddddd;
  }
}

.order-number-box {
  padding-bottom: 18px;

}

.order-number-title {
  font-size: 16px;
  color: #8F918E;
  margin-top: 10px;

  & .redText {
    color: #FF0000;
  }
}

::v-deep .order-number-input {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  height: 40px;
  background-color: #EDEDED;
  border-radius: 8px;
  padding: 0px 48px;
  box-sizing: border-box;
  position: relative;
  margin-top: 3px;

  & input {
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    background: transparent;
  }

  .el-input .el-input__inner {
    padding: 0;
    border: 0;
    background: transparent;
    line-height: 40px;
    height: 40px !important;
    text-align: center;
  }
}

.num-sub {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 12px;
  top: 8px;
}

.num-add {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 12px;
  top: 8px;
}

.inventory-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  color: #C0C0C0;
  margin-left: 20px;
  margin-top: 3px;

}

.goods-desc {
  color: #525252;
  font-size: 16px;
  height: 36px;
  overflow: hidden;
}

::v-deep .priceInput {
  display: inline-block;
  width: 200px;

  input {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #525252;
    background-color: #EDEDED;
    border-color: #EDEDED;
  }
}

.identity-tips {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin: 20px 0px;
  line-height: 25px;
}

.identity-title {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 25px;
}

.identity-title2 {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 25px;
}
</style>
