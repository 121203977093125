<template>
  <!--一開始進入畫面 無物流-->
  <div class="payment grey-bg g-b" v-loading="mainLoading">
    <!--白色區域 -->
    <el-form ref="dataForm" :model="dataForm" :rules="rules" label-position="top">
    <div class="container box w-b" style="border-bottom-left-radius:0;border-bottom-right-radius:0;">
      <div class="top-logo">
        <el-image style="width: 80%; max-width:400px;  height: auto;" :src="logoImgUrl"></el-image>
      </div>
        <!-- 付款有效時間 -->
        <!-- 來源類型【0商店後台】【1API】 -->
        <div v-if="dataForm.sourceType=='1'">
            <div style="text-align:center;margin-top:20px;">
                <div class="payment-valid-until">
                    付款有效時間至 Payment valid until
                    <span style="white-space: nowrap;">{{ dataForm.dueDate }}</span>
                </div>
                <div style="display: inline-block; vertical-align: middle; white-space: nowrap;">
                    <el-image class="clock-img" :src="clockImgUrl"></el-image>
                    <div class="tb-txt">台北 (GMT+8)</div>
                </div>
            </div>
        </div>
      <!-- 订单咨询 -->
      <div class="or-information">
        <div style="display: flex;">
          <div class="title-24">訂單資訊</div>
          <div class="title-16">Order Information</div>
        </div>
        <el-image class="bk-img" :src="rectangleOiUrl"></el-image>
		  <!-- 定期定額-->
		  <div>
		    <div class="mer-name-l">商店名稱 Merchant Name：</div>
		    <div class="mer-name-r">{{ dataForm.merchantName }}</div>
		    <div class="order-name">{{ dataForm.productName }}</div>
		    <div class="quota-style">
		      <div class="paymentFormItem0">
		        <div class="quota-info">授權資訊 Authorization Info</div>
		        <div style="padding-left: 20px;">
		            <el-form-item label="授權期數:" prop="totalTerm">
		              <div>
						  <el-input v-if="dataForm.paymentTermType=='1'" v-model="dataForm.totalTerm" prop="totalTerm" style="width: 100px;"
						  @input="(v) => (dataForm.totalTerm = v.replace(/[^0-9.]/g,''))" @blur="getSumAmount()">期</el-input>
						  <span style="margin-left: 20px;" v-else>{{dataForm.totalTerm}}期</span>
						  <!-- <span slot="error">
						    <div class="error-msg">
						      <el-image :src="annotationAlertImgUrl" class="img"/>
						      <span class="txt">{{ transactionAmountMsg }}</span>
						    </div>
						  </span> -->
					  </div>
		            </el-form-item>
		            <el-form-item label="授權金額:" prop="paymentAmount">
					<div>
						<el-input v-if="dataForm.paymentAmountType=='1'" v-model="dataForm.paymentAmount" prop="paymentAmount"  style="width: 100px;"
						 @input="(v) => (dataForm.paymentAmount = v.replace(/[^0-9.]/g,''))" @change="checkedIsVerification()" @blur="getSumAmount()"
						></el-input>
						<span style="margin-left: 20px;" v-format="'#,##0'" v-else>{{dataForm.paymentAmount}}</span>
						<!-- <span slot="error">
						  <div class="error-msg">
						    <el-image :src="annotationAlertImgUrl" class="img"/>
						    <span class="txt">{{ transactionAmountMsg }}</span>
						  </div>
						</span> -->
					</div>

		            </el-form-item>
		            <el-form-item label="授權區間:">
						<span style="margin-left: 20px;">{{ global.filterObj(paymentPeriodList, 'code', dataForm.paymentPeriod).name }}</span>
					</el-form-item>
		            <el-form-item label="授權日期:">
<!--                        <span style="margin-left: 20px;">{{dataForm.paymentDate}}</span>-->
                        <span style="margin-left: 20px;">{{dataForm.paymentDateShow}}</span>
					</el-form-item>
		        </div>
		      </div>
                <div class="paymentFormItem1">
                    <div class="quota-amount-title">總額：</div>
                    <!-- 指定日首期 & 商店設定金額-->
                    <div v-if="dataForm.initialSettingType=='1' && dataForm.paymentAmountType=='0'">
                        <div class="quota-amount-a">NT $<span v-format="'#,##0'">{{ sumAmount }}</span></div>
                        <div class="quota-amount-b">
                            <el-button type="primary">每期金額：NT $<span
                                    v-format="'#,##0'">{{ dataForm.eachIssueAmount }}</span>
                            </el-button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="quota-amount-a">NT $<span v-format="'#,##0'">{{ sumAmount }}</span></div>
                        <div class="quota-amount-b">
                            <el-button type="primary">每期金額：NT $<span
                                    v-format="'#,##0'">{{ dataForm.paymentAmount }}</span>
                            </el-button>
                        </div>
                    </div>
                </div>
		    </div>
		  </div>
          <div v-if="dataForm.initialSettingType=='1'">
              <div class="mer-name-span">※定期定額為確保付款安全及資訊正確，將發動一筆10元之授權交易，授權成功之後將立即取消授權，信用卡帳單上不會出現此筆授權交易紀錄</div>
          </div>
      </div>
        <!-- 付款方式 -->
      <!-- <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity">Step. 1 of 4</div>
	  <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity">Step. 1 of 2</div>
	  <div class="tit-no" v-else>Step. 1 of 3</div> -->
	  <div class="tit-no">Step. 1 of 2</div>
      <div class="pay-method">
        <div class="tit-area">
          <div class="tit-cn">選擇付款方式</div>
          <div class="tit-en">Payment Method</div>
        </div>
        <el-image class="tit-bk" :src="rectangleOiUrl"></el-image>
        <div class="pay-form-radio">
          <el-radio-group v-model="payMethod" style="width: 100%;">
              <div class="paymentMethod">
                  <el-radio label="DOMESTIC_CREDIT_CARD" class="pay-rad-txt  self-font">
                    信用卡 Credit Card
                    <el-image class="cre-card-img-1" :src="masterImgUrl"></el-image>
                    <el-image class="cre-card-img-2" :src="visaImgUrl"></el-image>
                    <el-image class="cre-card-img-3" :src="jcbImgUrl"></el-image>
                  </el-radio>
              </div>
          </el-radio-group>
        </div>
      </div>
      <!-- 付款咨询 -->
      <!-- <div class="tit-no" v-if="matchingLogistics==1 && isExistIdentity" style="margin-top: 30px;">Step. 2 of 4</div>
	  <div class="tit-no" v-else-if="matchingLogistics!=1 && !isExistIdentity" style="margin-top: 30px;">Step. 2 of 2</div>
	  <div class="tit-no" v-else style="margin-top: 30px;">Step. 2 of 3</div> -->
	  <div class="tit-no" style="margin-top: 30px;">Step. 2 of 2</div>
      <div class="pay-information" >
        <div class="tit-area">
          <div class="tit-cn">付款資訊</div>
          <div class="tit-en">Payment Information</div>
        </div>
        <el-image class="tit-bg" :src="rectangleOiUrl"></el-image>
        <div style="margin-top: 10px;">
              <div>
                <div class="paymentFormItem">
                  <el-row>
                    <el-form-item
                      class=""
                      style="font-weight: 400;"
                      label="信用卡卡號 Card Number"
                      prop="creditCardNumber"
                      :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                        { validator: validateCreditCardNumber}
                      ]">
                      <div style="display: flex;">
                          <el-input
                            v-model="dataForm.creditCardNumber"
                            maxlength="19"
                            type="text"
                            @input="changeCreditCardNumber($event,'creditCardNumber')"
                            class="right-image-input"/>
                          <el-image :src="dataForm.creditCardNumber ? cardImgUrl : cardRImgUrl" class="right-image"/>
                      </div>
                      <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ creditCardNumberMsg }}</span>
                        </div>
                      </span>
                    </el-form-item>
                  </el-row>
                  <el-row :gutter="30">
                    <el-col :sm="12" :xs="24">
                      <el-form-item
                        style="font-weight: 400;"
                        label="有效月年 Expry Date"
                        prop="effectiveDate"
                        :rules="[
                          // { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                          { required: true, message: EffectiveDateErMsg, trigger: 'blur' },
                          { validator: validateEffectiveDate}
                        ]">
                        <!-- <div style="display: flex;">
                          <el-cascader
                            placeholder=""
                            class="right-image-input"
                            v-model="dataForm.effectiveDate"
                            :options="optMYList"
                            @change="handleChange"></el-cascader>
                          <el-image :src="calendarTickImgUrl" class="right-image"/>
                      </div> -->
                      <div style="display: flex;">
                          <el-input
                            placeholder="mm / yy"
                            v-model="dataForm.effectiveDate"
                            maxlength="5"
                            @input="effectiveDateCheck"

                            class="right-image-input"/>
                          <el-image :src="dataForm.effectiveDate ? calendarTickImgUrl : calendarTickRImgUrl"  class="right-image"/>
                        </div>

                      <span slot="error">
                      <div class="error-msg">
                        <el-image :src="annotationAlertImgUrl" class="img"/>
                        <span class="txt">{{ EffectiveDateErMsg }}</span>
                      </div>
                    </span>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="12" :xs="24">
                      <el-form-item
                        style="font-weight: 400;"
                        label="末三碼 CVV/CVC"
                        prop="creditCardSecurityCode"
                        :rules="[
                          { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                        ]">
                        <div style="display: flex;">
                          <el-input
                            v-model="dataForm.creditCardSecurityCode"
                            maxlength="3"
                            @input="(v) => (dataForm.creditCardSecurityCode = v.replace(/[^\d]/g,''))"
                            class="right-image-input"/>
                          <el-image :src="dataForm.creditCardSecurityCode ? frameImgUrl : frameRImgUrl" style="width: 33px; margin-top: 3px" class="right-image"/>
                        </div>
                        <span slot="error">
                          <div class="error-msg">
                            <el-image :src="annotationAlertImgUrl" class="img"/>
                            <span class="txt">{{ formErrorMsg }}</span>
                          </div>
                        </span>
                      </el-form-item>
                      <!-- <el-col :span="24" class="box-payForm-card" v-if="dataForm.merchantSaveCard == '1'">
                        <el-checkbox v-model="dataForm.saveCard" false-label="0" true-label="1" tabindex="0">
                          <div class="txt">同意記住此結帳資訊，以利下次支付</div>
                          <el-image class="img" :src="rememberTxtImgUrl"></el-image>
                        </el-checkbox>
                      </el-col> -->
                    </el-col>
                  </el-row>
                </div>

                <div class="paymentFormItem">
                  <el-row>
                    <el-form-item
                      style="font-weight: 400;"
                      label="付款人姓名 Payer Name"
                      prop="cardholderName"
                      :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                      ]">
                      <div style="display: flex;">
                          <el-input v-model="dataForm.cardholderName" class="right-image-input"/>
                          <el-image :src="dataForm.cardholderName ? profileTickImgUrl : profileTickRImgUrl" class="right-image"/>
                      </div>
                      <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item
                      style="font-weight: 400;"
                      label="行動電話 Mobile Number"
                      prop="contactNumber"
                      >
                      <div style="display: flex;">
                          <el-input
                            @input="(v) => (dataForm.contactNumber = v.replace(/[^\d]/g,''))"
                            @change="validatePhoneNumber()"
                            v-model="dataForm.contactNumber"
							maxlength="10"
							:rules="[
							  { validator: validateCellphoneNumber}
							]"
                            class="right-image-input">
<!--							<template slot="prepend">+886</template>-->
                          </el-input>
                          <el-image :src="dataForm.contactNumber ? componentImgUrl : componentRImgUrl" class="right-image"/>

                      </div>
					  <span slot="error">
					    <div class="error-msg">
					      <el-image :src="annotationAlertImgUrl" class="img"/>
					      <span class="txt">{{ cellphoneNumberMsg }}</span>
					    </div>
					  </span>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item
                      style="font-weight: 400;"
                      label="信箱 Email"
                      prop="email"
                      :rules="[
                        { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                        { type: 'email', message: '請輸入正確的信箱', trigger: ['blur', 'change'] }
                      ]">
                      <div style="position: absolute; top: -25px; right: 0px;">
                        <div style="display: flex; flex-direction: row; line-height: 12px; position: absolute; right: 0px;">
                          <el-image style="width: 14px; height: 14px;" :src="annotationCheckImgUrl"/>
                          <!-- <el-image style="width: 230px;" :src="emailTxtCNImgUrl"/> -->
                          <div style="color: #1DB145; font-size: 0.6rem; font-weight: 400; margin-left: 2px; ">紅陽科技將會寄發交易結果通知信至此Email</div>
                        </div>

                        <div style="line-height: 8px; text-align: end; margin-top: 14px;">
                          <div style="color: #1DB145; font-size: 0.6rem; font-weight: 400; margin-left: 2px;">SUNPAY will Send a Payment Notification to this mail</div>
                        </div>
                      </div>
                      <div style="display: flex;">
                          <el-input v-model="dataForm.email" class="right-image-input"/>
                          <el-image :src="dataForm.email ? mailImgUrl : mailRImgUrl" class="right-image"/>
                      </div>
                      <span slot="error" v-if="!dataForm.email">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                      <span slot="error" v-else>
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">請輸入正確的信箱</span>
                        </div>
                      </span>
                    </el-form-item>
                  </el-row>
                </div>
              </div>

            <!-- 發票 invoiceType發票開立狀態【0不開立】【1開立】-->
           <div v-if="dataForm.invoiceType == '1'">
                <el-row :gutter="50" style="top: -10px;">
                    <el-col :span="24">
                        <el-form-item
                                style="font-weight: 400;"
                                label="發票 Invoice"
                                prop="payMethods"
                        >
                            <div class="pay-form-radio">
                                <el-radio-group style="width: 100%;" v-model="dataForm.invoiceStatus" @change="changeInvoiceStatus">
                                    <el-radio label="0" :checked="dataForm.invoiceStatus == 0" class="pay-rad-txt ">個人發票</el-radio>
                                    <el-radio label="2" :checked="dataForm.invoiceStatus == 2" class="pay-rad-txt ">手機條碼</el-radio>
                                    <el-radio label="1" :checked="dataForm.invoiceStatus == 1" class="pay-rad-txt ">自然人憑證條碼</el-radio>
                                    <el-radio label="3" :checked="dataForm.invoiceStatus == 3" class="pay-rad-txt ">公司統編</el-radio>
                                    <el-radio label="4" :checked="dataForm.invoiceStatus == 4" class="pay-rad-txt ">捐贈</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="dataForm.invoiceStatus == 0 || dataForm.invoiceStatus == 3" class="pay-form-check">

                                <el-form-item label="" v-if="invoicePaperShow == '1'">
                                    <el-checkbox v-model="invoicePaperChecked" @change="changePaperCheck()" style="font-weight: 400;">索取紙本發票</el-checkbox>
                                </el-form-item>

                                <div style="margin-top: -10px;" v-if="dataForm.invoiceStatus == 0 && dataForm.invoiceShowFlg == 2">
                                    <el-form-item
                                            style="font-weight: 400;"
                                            label="若無勾選索取紙本發票，個人發票預設存入會員載具，若中獎將另行通知"
                                    >
                                    </el-form-item>
                                </div>

                                <div style="margin-top: 0px;" v-if="dataForm.invoiceStatus == 3">
                                    <el-form-item
                                            style="font-weight: 400;"
                                            label="紅陽科技將寄發電子發票開立通知至您的Mail，可點擊信件内明細連結執行發票列印"
                                    >
                                    </el-form-item>
                                    <div style="display: flex; flex-direction: row;">
                                        <el-form-item
                                                style="font-weight: 400; width: 140px;"
                                                label=""
                                                label-position="left"
                                        >
                                            <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>發票抬頭</div>
                                        </el-form-item>
                                        <el-form-item
                                                prop="invoiceHead"
                                                style="width:420px;margin-top: 20px!important;"
                                        >
                                            <el-input class="ol-el-input" style="max-width: 420px" maxlength="60" v-model="dataForm.invoiceHead"></el-input>
                                            <span slot="error" v-if="!dataForm.invoiceHead">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                                        </el-form-item>
                                    </div>
                                    <div style="display: flex; flex-direction: row;">
                                        <el-form-item
                                                style="font-weight: 400; width: 140px;"
                                                label=""
                                                label-position="left"
                                        >
                                            <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>買受人統編</div>
                                        </el-form-item>
                                        <el-form-item
                                                prop="identifier"
                                                style="width:420px;margin-top: 20px!important;"
                                        >
                                            <el-input class="ol-el-input" style="max-width: 420px" maxlength="8" v-model="dataForm.identifier"></el-input>
                                            <span slot="error" v-if="!dataForm.identifier">
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                              </div>
                            </span>
                                            <span slot="error" v-else>
                              <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">請輸入正確買受人統編格式</span>
                              </div>
                            </span>
                                        </el-form-item>
                                    </div>
                                </div>

                                <div style="display: flex; flex-direction: row;" v-if="invoicePaperChecked && invoicePaperShow == '1'">
                                    <el-form-item
                                            style="font-weight: 400; width: 140px; margin-top: 12px;"
                                            label=""
                                            label-position="left"
                                    >
                                        <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>發票地址</div>
                                        <span  v-if="!dataForm.city || !dataForm.area || !dataForm.address">
                              <div class="error-msg">
                                <!-- <el-image :src="annotationAlertImgUrl" class="img"/> -->
                                <span class="txt">必填欄位不能為空</span>
                              </div>
                            </span>
                                    </el-form-item>
                                    <div style="width: 100%; max-width: 420px;margin-top: 20px;">
                                        <div class="invoiceItem">
                                            <el-select v-model="dataForm.city" clearable placeholder="選擇城市" filterable
                                                       @clear="clearSelect('dataForm','area')"
                                                       @change="choseCity($event)">
                                                <el-option v-for="(item,index) in cityList" :key="item.cityId" :label="item.cityName"
                                                           :value="item.cityId"></el-option>
                                            </el-select>
                                        </div>
                                        <div class="invoiceItem" style="margin-left: 10px;">
                                            <el-select v-model="dataForm.area" clearable filterable placeholder="選擇地區">
                                                <el-option v-for="(item,index) in areaList" :key="item.areaId" :label="item.areaName"
                                                           :value="item.areaId"></el-option>
                                            </el-select>
                                        </div>
                                        <el-input v-model="dataForm.address" type="text" style="margin-top: 20px;"></el-input>
                                    </div>
                                </div>

                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
			<div v-if="dataForm.invoiceStatus">

                <div style="margin-top: 0px;" v-if="dataForm.invoiceStatus == 1">
                    <!-- 1自然人憑證條碼 -->
                    <div style="display: flex; flex-direction: row;">
                        <el-form-item
                                style="font-weight: 400; width: 140px;"
                                label=""
                                label-position="left"
                        >
                            <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>載具號碼</div>
                        </el-form-item>
                        <el-form-item
                                prop="carrierIdPerson"
                                style="margin-top: 0!important;width:420px;"
                        >
                            <el-input class="ol-el-input" style="max-width: 420px;" :maxlength="16" v-model="dataForm.carrierIdPerson"></el-input>

                            <span slot="error" v-if="!dataForm.carrierIdPerson">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                            <span slot="error" v-else>
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">請輸入正確載具號碼格式</span>
                        </div>
                      </span>
                        </el-form-item>
                    </div>
                </div>

                <div style="margin-top: 0px;" v-if="dataForm.invoiceStatus == 2">
                    <!-- 2手機條碼 -->
                    <div style="display: flex; flex-direction: row;">
                        <el-form-item
                                style="font-weight: 400; width: 140px;"
                                label=""
                                label-position="left"
                        >
                            <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>載具號碼</div>
                        </el-form-item>
                        <el-form-item
                                prop="carrierIdMobile"
                                style="margin-top: 0!important;width:420px;"
                        >
                            <el-input class="ol-el-input" style="max-width: 420px;" :maxlength="8" v-model="dataForm.carrierIdMobile"></el-input>

                            <span slot="error" v-if="!dataForm.carrierIdMobile">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                            <span slot="error" v-else>
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">請輸入正確載具號碼格式</span>
                        </div>
                      </span>
                        </el-form-item>
                    </div>
                </div>

                <div style="margin-top: 0px;" v-if="dataForm.invoiceStatus == 4">
                    <!-- 4捐贈碼 -->
                    <div style="display: flex; flex-direction: row;">
                        <el-form-item
                                style="font-weight: 400; width: 140px;"
                                label=""
                                label-position="left"
                        >
                            <div style="font-size: 16px; color: #525252;"><span style="color:#D41831 !important;margin-right: 4px;">*</span>捐贈號碼</div>
                        </el-form-item>
                        <el-form-item
                                prop="poban"
                                style="width: 420px;"
                        >
                            <el-select clearable filterable v-model="dataForm.poban" style="width:420px;">
                                <el-option :key="item.poban" :label='item.poban+"-"+item.pobanName'
                                           :value=item.poban
                                           v-for="(item,index) in pobanList"></el-option>
                            </el-select>
                            <span slot="error">
                        <div class="error-msg">
                          <el-image :src="annotationAlertImgUrl" class="img"/>
                          <span class="txt">{{ formErrorMsg }}</span>
                        </div>
                      </span>
                        </el-form-item>
                    </div>
                </div>
            </div>

        </div>
      </div>

      <div v-if="isExistIdentity" style="display: flex;flex-direction: column; margin-top: 20px;">
        <div class="tit-no">Step. 3 of 3</div>
        <div class="pay-method">
          <div class="tit-area" style="display: flex;">
            <div style="font-size: 24px; font-weight: 600; color: #03328D;">身分驗證資訊</div>
            <div style="font-size: 16px; font-weight: 600; color: #03328D; line-height: 42px; margin-left: 10px;">Identity Verification</div>
          </div>
          <el-image style="width: 296px; height: 24px; margin-top: 16px; margin-left: 12px; position: absolute;" :src="rectangleOiUrl"></el-image>
          <div>
            <div class="identity-tips">依「第三方支付服務業防制洗錢及打擊資恐辦法」之規定，訂單已達法定金額，請完成身分驗證 In accordance with the “Regulations Governing Anti-Money Laundering and Countering the Financing of Terrorism for the Third-Party Payment Enterprises", the order has reached the legal amount, please complete the identity verification.</div>
            <div class="identity-tips">您的身分證資料僅用於內政部戶役司之查驗作業，不會用於其他目的或提供給第三方 Your ID information will only be used for verification purposes by the Ministry of the Interior, Household Registration Office, and will not be used for any other purposes or provided to third parties.</div>
            <div class="identity-title">手機號碼驗證(OTP)　Cellphone Number Verification</div>
            <div style="margin-bottom: 20px;" class="paymentFormItem">
              <el-row>
                <el-form-item
                    style="font-weight: 400;"
                    label="持卡人手機 Cardholder Cellphone Number"
                    prop="cellphoneNumber"
                    :rules="isExistIdentity?[
                         { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                         { validator: validateCellphoneNumber}

                       ]:''"
                >
                  <div style="display: flex;">
                    <el-input
                        v-model="dataForm.cellphoneNumber"
                        @input="changeCellphoneNumber($event)"
                        maxlength="10"
                        class="right-image-input"/>
                    <el-image :src="dataForm.cellphoneNumber.length==10 ? componentImgUrl : componentRImgUrl" class="right-image"/>
                  </div>
                  <span slot="error">
                         <div class="error-msg">
                           <el-image :src="annotationAlertImgUrl" class="img"/>
                           <span class="txt">{{ cellphoneNumberMsg }}</span>
                         </div>
                       </span>
                </el-form-item>
                <div style="text-align: end;" v-if="verificationType == 1">
                  <!-- sendCodeStatus:0默認可點 1xx秒後不可點  2重發-可點 3次數已過-->
                  <el-button style="background: #8F0304;padding: 14px 10px;border-radius: 10px;color:white;"
                             @click="getVerificationCode()" v-if="sendCodeStatus!='3'" :disabled = "dataForm.cellphoneNumber==''|| checkPhone(dataForm.cellphoneNumber) || sendCodeStatus=='1' || cellphoneNumberErr">
                    {{sendCodeStatus=='0'?'發送驗證碼 Send Verification Code':sendCodeStatus=='1'?count+'秒後重新發送 Resend in '+count+' Sec':'重新發送驗證碼 Resend verification Code'}}</el-button>
                  <el-button v-else style="color: #8F0304;background: transparent;padding: 14px 10px;border: 0px;font-weight: 600;" disabled>發送次數已滿 Verification limit reached</el-button>
                </div>
              </el-row>
            </div>
            <template  v-if="verificationType == 1">
              <div style="margin-bottom: 20px;" class="paymentFormItem">
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="簡訊驗證碼 SMS Verification Code"
                      prop="verificationCode"
                      :rules="isExistIdentity?[
                             { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                             { validator: validateVerificationCode}
                           ]:''"
                  >
                    <div style="display: flex;">
                      <el-input
                          @input="verificationCodeCheck($event)"
                          maxlength="6"
                          v-model="dataForm.verificationCode"
                          class="right-image-input"/>
                      <el-image :src="dataForm.verificationCode.length==6 ? smsVerificationImgUrl : smsNoVerificationImgUrl" class="right-image"/>
                    </div>
                    <span v-if="verificationCodeErr">
                             <div class="error-msg">
                               <el-image :src="annotationAlertImgUrl" class="img"/>
                               <span class="txt">驗證碼錯誤{{errCount}}次，累計錯誤3次此筆交易將無效</br>
                                 Verification failed once, if there are 3 times in total, the transaction will be invalid.</span>
                             </div>
                           </span>
                    <span slot="error" v-else>
                             <div class="error-msg">
                               <el-image :src="annotationAlertImgUrl" class="img"/>
                               <span class="txt">{{ verificationCodeMsg }}</span>
                             </div>
                           </span>
                  </el-form-item>
                </el-row>
              </div>
              <div class="identity-title2">身分驗證　ID Verification</div>
              <div class="error-msg" v-if="verificationIDErr">
                <el-image :src="annotationAlertImgUrl" class="img"/>
                <span class="txt">身份驗證失敗，請重新確認 Identity verification failed, please reconfirm.</span>
              </div>
              <div class="paymentFormItem2">
                <el-row>
                  <el-form-item
                      class=""
                      style="font-weight: 400;"
                      label="身分證字號 ID Card Number"
                      prop="idCardNumber"
                      :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                           { validator: validateIdCardNumber}
                         ]:''">
                    <div style="display: flex;">
                      <el-input
                          v-model="dataForm.idCardNumber"
                          maxlength="10"
                          @input="idCardNumberCheck($event)"
                          class="right-image-input"/>
                      <el-image :src="dataForm.idCardNumber.length==10 ? cardImgUrl : cardRImgUrl" class="right-image"/>
                    </div>
                    <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ idCardNumberMsg }}</span>
                           </div>
                         </span>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="發證日期 Issuance Date"
                      prop="issuanceDate"
                      :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }
                         ]:''"
                  >
                    <div  style="display: flex;">
                      <el-select v-model="selectedYear" placeholder="年Year" @change="changeYear()">
                        <el-option
                            v-for="(item,index) in yearOptions"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                      </el-select><span>/</span>
                      <el-select v-model="selectedMonth" clearable placeholder="月Month" @change="mothChange(dataForm.selectedMonth)">
                        <el-option v-for="(item,index) in monthList" :key="item.value" :label="item.label"
                                   :value="item.value" :disabled="item.disable"></el-option>
                      </el-select><span>/</span>
                      <el-select v-model="selectedDay" clearable placeholder="日Day">
                        <el-option v-for="(item,index) in dayList" :key="item.value" :label="item.label"
                                   :value="item.value" :disabled="item.disable"></el-option>
                      </el-select>
                    </div>
                    <span v-if="issuanceDateIsEmpty==''">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>

                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="身分證正反面照片 ID Card Photo"
                      prop="idCardPhotoFrontResourceId"
                      :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }
                         ]:''"
                  >
                    <div style="font-size: 16px;font-weight: 400;letter-spacing: 0.9px;">正面 Front</div>
                    <div  style="background-color: #EDEDED; border-radius: 8px; width: 100%; text-align: center;">
                      <el-image :src="idCard1" style="width: 80%; margin: 0 auto;" v-if="idCard1"></el-image>
                      <el-upload
                          class="upload-demo"
                          :action="$http.adornUrl('/common/resource/uploadResource')"
                          :on-success="icCardFrontSuccess"
                          accept=".jpg,.png,.jpeg"
                          multiple>
                        <el-button size="small" type="danger" style="background-color: #8F0304" v-if="idCard1">變更 Change</el-button>
                        <el-button size="small" type="danger" style="background-color: #8F0304; margin: 100px 0 90px 0;" v-else>上傳 Upload</el-button>
                        <div slot="file" slot-scope="{file}"></div>
                      </el-upload>
                    </div>
                    <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                  </el-form-item>
                </el-row>
              </div>
              <div class="paymentFormItem" style="margin-top: 12px;">
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="發證地點 Issuance Location"
                      prop="issuanceLocation"
                      :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                         ]:''">
                    <div style="display: flex;">
                      <el-select v-model="dataForm.issuanceLocation" placeholder="" style="width: 100%;">
                        <el-option
                            v-for="item in issuanceLocationList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                      </el-select>
                    </div>
                    <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="領補換類別 Issuance Type"
                      prop="issuanceType"
                      :rules="isExistIdentity?[
                         { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                       ]:''"
                  >
                    <div style="display: flex;">
                      <el-select v-model="dataForm.issuanceType" placeholder="" style="width: 100%;">
                        <el-option
                            v-for="item in issuanceTypeList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code">
                        </el-option>
                      </el-select>
                    </div>
                    <span slot="error">
                         <div class="error-msg">
                           <el-image :src="annotationAlertImgUrl" class="img"/>
                           <span class="txt">{{ formErrorMsg }}</span>
                         </div>
                       </span>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label=""
                      prop="idCardPhotoBackResourceId"
                      :rules="isExistIdentity?[
                           { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' }
                         ]:''"
                  >
                    <div style="font-size: 16px;font-weight: 400;letter-spacing: 0.9px; margin-top: 28px;">反面 Back</div>
                    <div  style="background-color: #EDEDED; border-radius: 8px; width: 100%; text-align: center;">
                      <el-image :src="idCard2" style="width: 80%; margin: 0 auto;" v-if="idCard2"></el-image>
                      <el-upload
                          class="upload-demo"
                          :action="$http.adornUrl('/common/resource/uploadResource')"
                          :on-success="icCardBackSuccess"
                          accept=".jpg,.png,.jpeg"
                          multiple>
                        <el-button size="small" type="danger" style="background-color: #8F0304" v-if="idCard2">變更 Change</el-button>
                        <el-button size="small" type="danger" style="background-color: #8F0304; margin: 100px 0 90px 0;" v-else>上傳 Upload</el-button>
                        <div slot="file" slot-scope="{file}"></div>
                      </el-upload>
                    </div>
                    <span slot="error">
                           <div class="error-msg">
                             <el-image :src="annotationAlertImgUrl" class="img"/>
                             <span class="txt">{{ formErrorMsg }}</span>
                           </div>
                         </span>
                  </el-form-item>
                </el-row>
              </div>
            </template>
            <template v-if="verificationType == 2">
              <div style="margin-bottom: 20px;" class="paymentFormItem">
                <el-row>
                  <el-form-item
                      style="font-weight: 400;"
                      label="持卡人身分證字號末三碼 Last three digits of ID"
                      prop="idCardNumberSecurityCode"
                      :rules="isExistIdentity?[
                             { required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur' },
                           ]:''"
                  >
                    <div style="display: flex;">
                      <el-input
                          @input="verificationCodeCheck($event)"
                          maxlength="3"
                          v-model="dataForm.idCardNumberSecurityCode"
                          class="right-image-input"/>
                      <el-image :src="dataForm.creditCardNumber ? cardImgUrl : cardRImgUrl" class="right-image" />
                    </div>
                    <span slot="error">
                             <div class="error-msg">
                                <el-image :src="annotationAlertImgUrl" class="img"/>
                                <span class="txt">{{ formErrorMsg }}</span>
                             </div>
                         </span>
                    <div class="error-msg" v-if="verificationIDThreeErr">
                      <el-image :src="annotationAlertImgUrl" class="img"/>
                      <span class="txt">身分證資訊錯誤 ID information incorrect</span>
                    </div>
                  </el-form-item>
                </el-row>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- 服務條款 -->
      <div>
        <div style="border-radius:10px; background: #FAF0CA;margin: 30px 0;padding: 20px;">
          <div type="text" @click="()=>{ agreement1Box = !agreement1Box}" class="box-txt-bl-s" >一、個人資料提供同意書(Notification Letter of Collection, Processing and User of Personal Information)</div>
          <agreement1 v-if="agreement1Box"></agreement1>

          <div type="text" @click="()=>{ agreement2Box = !agreement2Box}" class="box-txt-bl-s" style="margin-top: 10px ;margin-left: 0px !important;">二、紅陽科技網路代理收付服務契約(SunTech Online Payment Processing Service Agreement)</div>
          <agreement2 v-if="agreement2Box"></agreement2>

           <div type="text" @click="()=>{ agreement3Box = !agreement3Box}" class="box-txt-bl-s" style="margin-top: 10px ;margin-left: 0px !important;">三、消費者個資移轉商店使用同意書(Consumer Personal Informations Transfer Store Use Consent Form)</div>
           <agreement3 v-if="agreement3Box"></agreement3>

          <div class="box-txt-rd" style="margin-top: 10px;">請主動求證付款資訊來源，切勿將個人資料提供給來路不明的第三方，若發現有不明管道之訊息可撥打165反詐騙諮詢專線。</div>
          <div class="box-txt-rd">Please proactively verify the source of payment information and refrain from providing personal information to unknown third parties. If you suspect you could be victim of a scam, protect your rights by calling the “165 Anti-Scam hotline”.</div>
          <div class="information-form-check">
          <el-checkbox v-model="informationCk" style="margin-top: 20px;">
            <div class="box-txt-bl-b" style="">本人已理解上揭個資法、服務條款及個資轉移使用，並同意其內容。</div>
          </el-checkbox>
          </div>
          <div class="box-txt-bl-b" style="margin-left: 25px">I agree to the terms of Privacy Statment and Service Terms and Conditions.</div>
        </div>
        <div style="text-align: center;">
          <el-button type="danger" :disabled="!informationCk" :class="informationCk ? 'next-btn' : 'next-btn-dis'"
            @click="nextStep">確認送出 Confirm</el-button>
        </div>
      </div>
      <div>
        <div style="text-align: center;">
          <el-image style="max-width: 700px; margin-top: 20px;" :src="bannerImgUrl"></el-image>
        </div>
        <div style="text-align: center;">
          <el-image style="max-width: 900px; width: 100%; margin: 20px -30px" :src="footerImgUrl"></el-image>
        </div>
      </div>
    </div>

    <div style="width: 100%; height: 40px; background: #8F0304;"></div>

    <!--填寫驗證碼-->
    <new-ver-code-dialog ref="verCodeDialog"
                   :orderAmount="dataForm.paymentAmount"
                   @goPay="goPay" @dialogClosed="dialogClosed"></new-ver-code-dialog>


    </el-form>
  </div>
</template>

<script>
import newVerCodeDialog from './compontents/periodic-ver-code-dialog'
// import imgSlide from './compontents/img-slide'
import agreement1 from './agreement/agreement1'
import agreement2 from './agreement/agreement2'
import agreement3 from './agreement/agreement3'

import {
    getCurrentMonth,
    getCurrentYear
  } from '@/api/credit'

import logoImg from '../assets/img/new-payment/logo-sunpay.png'
import rectangleOi from '../assets/img/new-payment/rectangle-oi.png'
import clockImg from '../assets/img/new-payment/clock-01.png'
import commodityBgImg from '../assets/img/new-payment/commodity-bg.png'
import dividerImg from '../assets/img/new-payment/divider.png'
import bannerImg from '../assets/img/new-payment/banner.png'
import masterImg from '../assets/img/new-payment/master.png'
import visaImg from '../assets/img/new-payment/visa.png'
import jcbImg from '../assets/img/new-payment/jcb.png'
import unionPayImg from '../assets/img/new-payment/unionPay.png'
import piluPayImg from '../assets/img/new-payment/piluPay.png'
import monthlPayImg from '../assets/img/new-payment/monthlPay.png'
import googlePayImg from '../assets/img/new-payment/google-pay.png'
import applePayImg from '../assets/img/new-payment/apple-pay.png'
import sumsungPayImg from '../assets/img/new-payment/sumsung-pay.png'

import inputProfileTickImg from '../assets/img/new-payment/input-profile-tick.png'
import annotationCheckImg from '../assets/img/new-payment/annotation-check.png'
import emailTxtImg from '../assets/img/new-payment/email-txt.png'
import emailTxtCNImg from '../assets/img/new-payment/email-txt-cn.png'
import productTxtImg from '../assets/img/new-payment/product-txt.png'
import annotationAlertImg from '../assets/img/new-payment/annotation-alert.png'

import footerImg from '../assets/img/new-payment/footer.png'
import footerFtImg from '../assets/img/new-payment/footer-first.png'
import footerKgiImg from '../assets/img/new-payment/footer-kgi.png'
import footerTsImg from '../assets/img/new-payment/footer-ts.png'
import footerUbImg from '../assets/img/new-payment/footer-ub.png'
import rememberTxtImg from '../assets/img/new-payment/remember-txt.png'

import arrowDownImg from '../assets/img/new-payment/input/arrow-down.png'
import arrowDownRImg from '../assets/img/new-payment/input/arrow-down-r.png'
import calendarTickImg from '../assets/img/new-payment/input/calendar-tick.png'
import calendarTickRImg from '../assets/img/new-payment/input/calendar-tick-r.png'
import componentImg from '../assets/img/new-payment/input/component.png'
import componentRImg from '../assets/img/new-payment/input/component-r.png'
import frameImg from '../assets/img/new-payment/input/frame.png'
import frameRImg from '../assets/img/new-payment/input/frame-r.png'
import mailImg from '../assets/img/new-payment/input/mail.png'
import mailRImg from '../assets/img/new-payment/input/mail-r.png'
import mapImg from '../assets/img/new-payment/input/map.png'
import mapRImg from '../assets/img/new-payment/input/map-r.png'
import profileTickImg from '../assets/img/new-payment/input/profile-tick.png'
import profileTickRImg from '../assets/img/new-payment/input/profile-tick-r.png'
import cardRImg from '../assets/img/new-payment/input/card-r.png'
import cardImg from '../assets/img/new-payment/input/card.png'
import carRImg from '../assets/img/new-payment/input/car-r.png'
import carImg from '../assets/img/new-payment/input/car.png'

import arrowLeftImg from '../assets/img/new-payment/arrow-left.png'
import arrowRightImg from '../assets/img/new-payment/arrow-right.png'
import refreshImg from '../assets/img/new-payment/refresh.png'

import smsNoVerificationImg from '../assets/img/new-payment/input/shield-check.png'
import smsVerificationImg from '../assets/img/new-payment/input/shieldVerificatin-check.png'

export default {
    name: 'init-no-logistics',
    components: {
      newVerCodeDialog,
      // imgSlide,
      agreement1,
      agreement2,
      agreement3
    },
    data () {
      var validateCreditCardNumber = (rule, value, callback) => {
        if (this.global.isEmpty(this.dataForm.cardNumber) || this.dataForm.changeCard == '1') {
          if (value == '' || value == undefined || value == null) {
            callback(new Error('此欄位為必填！'))
          } else {
            let val = value.replace(/\s*/g, '');
            // 信用卡類別為信用卡
            if (this.dataForm.cardCategory == '0' && val.length !== 16) {
              callback(new Error('請輸入16位卡號'))
            } else if (this.dataForm.cardCategory == '1' && (val.length > 19 || val.length < 16)) {
              callback(new Error('請輸入16位-19位卡號'))
            }
          }
        }
        callback()
      }
        const checkCarrierId = (rule, value, callback) =>{
            if(this.dataForm.invoiceStatus == 2){ //手機載具
                let rg = /^\/[0-9A-Z.\-\+]{7}$/;
                if(rg.test(value)){
                    callback()
                }else{
                    callback(new Error('"請輸入正確載具號碼格式！'))
                }
            }else{
                let rg = /[A-Z]{2}[0-9]{14}$/;
                if(rg.test(value)){
                    callback()
                }else{
                    callback(new Error('"請輸入正確載具號碼格式！'))
                }
            }
        }
      return {
		//付款方式
		payMethod:'DOMESTIC_CREDIT_CARD',
		paymentPeriodList:[{
          code:'D',
          name:'固定天期',
        },{
          code:'W',
          name:'每週',
        },{
          code:'M',
          name:'每月',
        },{
          code:'Y',
          name:'每年',
        }],
        //捐贈號碼列表
        pobanList:[],
        mainLoading: false,
        payHeaderIcon: require('../assets/img/payment/pay-icon-5.png'),
        twIcon: require('../assets/img/payment/pay-icon-4.png'),
        cardIcon1: require('../assets/img/payment/pay-icon-2.jpg'),
        cardIcon2: require('../assets/img/payment/pay-icon-1.jpg'),
        titleIcon: require('../assets/img/payment/pay-icon-3.png'),
        labelPosition: 'top',

		secondCode:'',
		threeCode:'',
		sumAmount:'', //總金額
        invoicePaperShow: 0,
        verificationType: 0,
        dataForm: {
          masterId:'',
          invoicePaper:'',
          city:'',
          area :'',
          address: '',
          invoiceAddress:'',
          //身份驗證資訊
          //持卡人手機
          cellphoneNumber:'',
          //驗證碼
          verificationCode:'',
          //身份證
          idCardNumber:'',
          //發證地點
          issuanceLocation:'',
          //發證日期
          issuanceDate:'',
          //補換類別
          issuanceType:'',
          verificationFlag:'',
          idCardPhotoFrontResourceId: '',
          idCardPhotoBackResourceId: '',
          idCardNumberSecurityCode:'',
          successAllPath: '',
          failAllPath: '',
        },
		//紙本發票選中狀態
		invoicePaperChecked:false,
        rules: {
          carrierIdPerson:[
            {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
            {validator: checkCarrierId, }
          ],
          carrierIdMobile:[
            {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
            {validator: checkCarrierId, }
          ],
          //買受人統編
          identifier:[
            {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
            {pattern: /^\d{8}$/, message: "請輸入正確買受人統編格式"}
          ],
          poban:[
            {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'}
          ],
          invoiceHead:[
		    {required: true, message: '必填欄位不得為空白 Field required', trigger: 'blur'},
            {pattern: /^[^\s]+/, message: "請輸入正確發票抬頭格式"}
		  ],
        },
        preventSubmitFlag: false,
        // 信用卡號是否顯示驗證信息
        showCardNoErrorText: false,
        // 校驗是否通過
        validatePass: false,
        binCode: '',
        agreeList: [false, false, false],
        agreeComponents: ['agreement1', 'agreement2', 'agreement3'],
        addCardType:'1',
        clockImgUrl: clockImg,
        logoImgUrl: logoImg,
        rectangleOiUrl: rectangleOi,
        commodityBgImgUrl: commodityBgImg,
        dividerImgUrl: dividerImg,
        bannerImgUrl: bannerImg,
        masterImgUrl: masterImg,
        visaImgUrl: visaImg,
        jcbImgUrl: jcbImg,
        unionPayImgUrl: unionPayImg,
        piluPayImgUrl: piluPayImg,
        monthlPayImgUrl: monthlPayImg,
        googlePayImgUrl: googlePayImg,
        applePayImgUrl: applePayImg,
        sumsungPayImgUrl: sumsungPayImg,
        idCard1: '',
        idCard2: '',

        arrowDownImgUrl: arrowDownImg,
        arrowDownRImgUrl: arrowDownRImg,
        calendarTickImgUrl: calendarTickImg,
        calendarTickRImgUrl: calendarTickRImg,
        componentImgUrl: componentImg,
        componentRImgUrl: componentRImg,
        frameImgUrl: frameImg,
        frameRImgUrl: frameRImg,
        mailImgUrl: mailImg,
        mailRImgUrl: mailRImg,
        mapImgUrl: mapImg,
        mapRImgUrl: mapRImg,
        profileTickImgUrl: profileTickImg,
        profileTickRImgUrl: profileTickRImg,
        cardRImgUrl: cardRImg,
        cardImgUrl: cardImg,
        carRImgUrl: carRImg,
        carImgUrl: carImg,
		smsNoVerificationImgUrl:smsNoVerificationImg,
		smsVerificationImgUrl:smsVerificationImg,

        inputProfileTickImgUrl: inputProfileTickImg,
        // calendarTickImgUrl: calendarTickImg,
        // frameImgUrl: frameImg,
        annotationCheckImgUrl: annotationCheckImg,
        emailTxtCNImgUrl: emailTxtCNImg,
        emailTxtImgUrl: emailTxtImg,
        productTxtImgUrl: productTxtImg,
        annotationAlertImgUrl: annotationAlertImg,

        footerImgUrl: footerImg,
        footerFtImgUrl: footerFtImg,
        footerKgiImgUrl: footerKgiImg,
        footerTsImgUrl: footerTsImg,
        footerUbImgUrl: footerUbImg,
        rememberTxtImgUrl: rememberTxtImg,

        formErrorMsg: '必填欄位不得為空白 Field required',

        checked: false,
        informationCk: false,

        dueDate: '', // 付款有效時間
        merchantName: '', //商店名稱
        orderName: '', //訂單名稱

        optMYList: [],

        validateEffectiveDate: undefined,
        validateCreditCardNumber: undefined,
		validateCellphoneNumber: undefined,
		validateVerificationCode: undefined,
		validateIdCardNumber: undefined,
        EffectiveDateErMsg: '必填欄位不得為空白 Field required',
        creditCardNumberMsg: '必填欄位不得為空白 Field required',
        transactionAmountMsg: '必填欄位不得為空白 Field required',
		recipientPhoneMsg:'必填欄位不得為空白 Field required',
		idCardNumberMsg:'必填欄位不得為空白 Field required',
		cellphoneNumberMsg: '', //11111
		verificationCodeMsg: '必填欄位不得為空白 Field required',
		recipientPhoneErr:false,

        agreement1Box: false,
        agreement2Box: false,
        agreement3Box: false,

        productNameList: [],

        arrowLeftImgUrl: arrowLeftImg,
        arrowRightImgUrl: arrowRightImg,
        refreshImgUrl: refreshImg,

        cityList: [],
        areaList: [],
         //是否存在身份驗證
        isExistIdentity:false,
        selectedYear:'',
        selectedMonth:'',
        selectedDay:'',
        yearOptions: [],
        monthList:[],
        dayList:[],
        issuanceLocationList:[],
        issuanceTypeList: [],
        //驗證碼倒計時
        count:'',
        //發送驗證碼的狀態 0默認可點 1xx秒後不可點  2重發-可點 3次數已過
        sendCodeStatus:'0',
        clickCount:0,
        verificationCodeErr:false,
        verificationIDErr:false,
        verificationIDThreeErr: false,
        errCount:0,
        cellphoneNumberErr:false,
      }
    },
    computed: {
      // 跳轉頁面
      routerName: {
        get () {
          return this.$store.state.credit.routerName
        },
        set (val) {
          this.$store.commit('credit/updateRouterName', val)
        }
      },
    },
    mounted () {
      // 獲取捐贈碼列表
      this.getPobanDataList();
	  // 獲取城市下拉選
	  this.getCityCodeName();
	  let path = this.$route.path;
	  let pathList = [];
	  if(!this.global.isEmpty(path)){
		  pathList = path.split('/');
		  this.secondCode = pathList[2];
		  this.threeCode = pathList[3];
	  }
	  this.getInfo();
      this.initSelectYearMonth();
      this.getLocationList();
      this.getIssuanceTypeList();
    },
    methods: {
      initSelectYearMonth() {
        let year = new Date().getFullYear();
        year = year - 1911;
        for (var i = year; i >= 0; i--) {
          this.yearOptions.push(i);
        }

        for (var i = 1; i <= 12; i++) {
          if (i < 10) {
            this.monthList.push({
              value: '0' + i,
              lable: '0' + i,
              disable: false
            });
          } else {
            this.monthList.push({
              value: i,
              lable: i,
              disable: false
            });
          }
        }
      },
      changeYear() {
        this.selectedMonth = '';
        this.selectedDay = '';
        let nowYear = new Date().getFullYear();
        let nowMonth = new Date().getMonth() + 1;
        let nowDay = new Date().getDate();
        if (this.selectedYear && this.selectedYear == nowYear - 1911) {
          this.monthList.forEach((item) => {
            item.disable = false;
            if (parseInt(item.value) > nowMonth) {
              item.disable = true;
            } else {
              item.disable = false;
            }
          })
        } else {
          this.monthList.forEach((item) => {
            item.disable = false;
          })
        }
      },
      mothChange(moth) {
        let daysInMonth = new Date(this.selectedYear + 1911, this.selectedMonth, 0).getDate();
        let days = [];
        for (var i = 1; i <= daysInMonth; i++) {
          if (i < 10) {
            days.push({
              value: '0' + i,
              lable: '0' + i,
              disable: false
            });
          } else {
            days.push({
              value: i,
              lable: i,
              disable: false
            });
          }
        }
        this.selectedDay = '';
        this.dayList = days;
        let nowYear = new Date().getFullYear();
        let nowMonth = new Date().getMonth() + 1;
        let nowDay = new Date().getDate();
        if (this.selectedYear + 1911 == nowYear && parseInt(this.selectedMonth) == nowMonth) {
          this.dayList.forEach((item) => {
            item.disable = false;
            if (parseInt(item.value) > nowDay) {
              item.disable = true;
            } else {
              item.disable = false;
            }
          })
        } else {
          this.dayList.forEach((item) => {
            item.disable = false;
          })
        }
      },
      getLocationList() {
        this.$http({
          url: this.$http.adornUrl('verify/issuanceLocationList'),
          method: 'get',
          params: this.$http.adornParams({})
        }).then(({
                   data
                 }) => {
          if (data && data.resultCode == 200) {
            this.issuanceLocationList = data.body;
          }
        })
      },
      getIssuanceTypeList() {
        this.$http({
          url: this.$http.adornUrl('verify/issuanceTypeList'),
          method: 'get',
          params: this.$http.adornParams({})
        }).then(({
                   data
                 }) => {
          if (data && data.resultCode == 200) {
            this.issuanceTypeList = data.body;
          }
        })
      },
		//計算總額
		getSumAmount(){
		    //指定日首期 & 商店設定金額
            if (this.dataForm.initialSettingType == '1' && this.dataForm.paymentAmountType == '0' && !this.global.isEmpty(this.dataForm.totalTerm)) {
                this.sumAmount = parseInt(this.dataForm.eachIssueAmount) * parseInt(this.dataForm.totalTerm);
            }
            //1.指定日首期 & 消費者定 2.立即首期
            else if (!this.global.isEmpty(this.dataForm.paymentAmount) && !this.global.isEmpty(this.dataForm.totalTerm)) {
                this.sumAmount = parseInt(this.dataForm.paymentAmount) * parseInt(this.dataForm.totalTerm);
            }
		},
      checkedIsVerification(){
        if(parseInt(this.dataForm.paymentAmount) >= parseInt(this.dataForm.verificationAmount)){
          this.dataForm.verificationFlag = 1;
          this.isExistIdentity = true
        }else{
          this.dataForm.verificationFlag = 0;
          this.isExistIdentity = false
        }
      },
	  changeCellphoneNumber(val) {
        this.dataForm.verificationCode = '';
		    this.cellphoneNumberCheck(this.dataForm.cellphoneNumber);
		  },
      getVerificationType(){
        this.$http({
          url: this.$http.adornUrl('order/verificationInfo'),
          method: 'post',
          data: this.$http.adornData({
            phoneNumber: this.dataForm.cellphoneNumber
          })
        }).then(({data}) => {
          if (data && data.resultCode == 200) {
            this.verificationType = data.body.verificationType
          }
        })
      },
      checkPhone(phone){
        let regex = /^09\d{8}$/;
        if(!regex.test(phone)){
          return true
        }else{
          return false
        }
      },
      cellphoneNumberCheck(val) {
        let rg = /^09\d{8}$/;
        if (this.global.isEmpty(val)) {
          this.validateCellphoneNumber = (rule, value, callback) => {
            this.cellphoneNumberMsg = '必填欄位不得為空白 Field required';
            callback(new Error())
          }
        } else {
          this.dataForm.cellphoneNumber = val.replace(/[^\d]/g, '');
          if (!rg.test(val)) {
            this.validateCellphoneNumber = (rule, value, callback) => {
              this.cellphoneNumberMsg = '手機號碼格式錯誤 Wrong mobile number';
              this.cellphoneNumberErr = true;
              callback(new Error())
            }
          } else {
            this.validateCellphoneNumber = (rule, value, callback) => {
              this.cellphoneNumberErr = false;
              this.getVerificationType();
              callback()
            }
          }
        }
        return
      },
      verificationCodeCheck(val) {
        if (this.global.isEmpty(val)) {
          this.validateVerificationCode = (rule, value, callback) => {
            this.verificationCodeMsg = '必填欄位不得為空白 Field required';
            callback(new Error())
          }
        } else {
          this.dataForm.verificationCode = val.replace(/[^\d]/g, '');
          if (val.length != 6) {
            this.validateVerificationCode = (rule, value, callback) => {
              this.verificationCodeMsg = '請輸入完整驗證碼 Please enter correct Verification Code';
              callback(new Error())
            }
          } else {
            this.validateVerificationCode = (rule, value, callback) => {
              callback()
            }
          }
        }
        return
      },
      idCardNumberCheck(val) {
        let reg = /^[A-Z]\d{9}$/;
        if (this.global.isEmpty(val)) {
          this.validateIdCardNumber = (rule, value, callback) => {
            this.idCardNumberMsg = '必填欄位不得為空白 Field required';
            callback(new Error())
          }
        } else {
          if (!reg.test(val)) {
            this.validateIdCardNumber = (rule, value, callback) => {
              this.idCardNumberMsg = '身分證字號錯誤 ID card number is incorrect';
              callback(new Error())
            }
          } else {
            this.validateIdCardNumber = (rule, value, callback) => {
              callback()
            }
          }
        }
      },
      //獲取短信驗證碼
      getVerificationCode(){
        this.$http({
          url: this.$http.adornUrl('verify/code/phone'),
          method: 'get',
          params: this.$http.adornParams({
            account: this.dataForm.cellphoneNumber,
            merchantCode: this.dataForm.merchantCode,
            merchantOrderId: this.dataForm.merchantOrderId,
            amount: this.dataForm.paymentAmount
          })
        }).then(({data}) => {
          if (data && data.resultCode == 200) {
            this.sendCodeStatus = '1';
            let TIME_COUNT = 60;
            this.clickCount++;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.timer = setInterval(() => {
                if (this.count > 1 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  if(this.clickCount == 1){
                    this.sendCodeStatus = '2';
                  }else {
                    this.sendCodeStatus = '3';
                  }
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
        })
      },
      //身份驗證 持卡人手機同步付款人電話
      validatePhoneNumber () {
        if (this.dataForm.contactNumber && this.isExistIdentity) {
          this.dataForm.cellphoneNumber = this.dataForm.contactNumber;
          this.dataForm.verificationCode = '';
          this.getVerificationType();
        }
        if(!this.global.isEmpty(this.dataForm.contactNumber)){
          // 台灣手機號碼正則表達式
          const regex = /^09\d{8}$/;
          if(!regex.test(this.dataForm.contactNumber)){
            this.phoneError = true;
          }else{
            this.phoneError = false;
          }
        }else{
          this.phoneError = false;
        }
      },
      changeAgree (index) {
        // 协议子组件默认初始化显示中文
        this.agreeComponents.forEach((agreeItemRef, aIndex) => {
          if (this.$refs[agreeItemRef]) {
            this.$refs[agreeItemRef].init()
          }
        })
        // 第几个协议展开
        this.agreeList[index] = !this.agreeList[index]
        // 当前未点击的协议收起
        this.agreeList.forEach((item, i) => {
          if (index !== i) {
            this.agreeList[i] = false
          }
        })
        this.$forceUpdate()
      },

      changeCreditCardNumber (val, model) {
        if(model == 'creditCardNumber'){
          this.creditCardNumberCheck(val)
        }
        let cardNumber = val.replace(/\s/g, '').replace(/[^\d]/g, '').replace(/(\d{19})(?=\d)/g, '$1 ')
        cardNumber = cardNumber.replace(/\D+/g, '').replace(/(\d{4})/g, '$1-').replace(/-$/, '')
		this.dataForm[model] = cardNumber

      },
	  //判斷是否需要身分驗證
	  checkAmountShowOtp(amount){
		  this.$http({
			url: this.$http.adornUrl('/verify/verificationFlag'),
			method: 'get',
			params: this.$http.adornParams({
				amount:amount
			})
		  }).then(({data}) => {
			if (data && data.resultCode == 200) {
				//是否需要身分驗證【0 不需要】【1 需要】
				this.dataForm.verificationFlag = data.body.verificationFlag;
				if (this.dataForm.verificationFlag == '1') {
				  this.isExistIdentity = true;
				} else {
				  this.isExistIdentity = false;
				}
			}
		  })
	  },

      getInfo () {
        this.loading = true
        this.$http({
          //url: 'http://192.168.18.53:8090/cashier-service/fixedTermAmountMaster/periodicAllData',
          url: this.$http.adornUrl('/fixedTermAmountMaster/periodicAllData'),
          method: 'post',
          data: this.$http.adornData({
            threeCode: this.threeCode,
          })
        }).then(({data}) => {
          if (data && data.resultCode == 200) {
			  this.dataForm = {
          ...this.dataForm,
				  ...data.body
			  }
        this.isExistIdentity = this.dataForm.verificationFlag == 1?true:false;
			  this.getSumAmount();
          } else {
              this.$router.push({name: 'orderfinish', query: {msg: data.resultMessage}})
          }
          this.loading = false
          this.mainLoading= false
        })
      },
	  changePaperCheck(){
		  if(this.invoicePaperChecked){ //紙本發票選中
			this.dataForm.invoicePaper = '1';
		  }else{
			this.dataForm.invoicePaper = '0';
			this.dataForm.city = '';
			this.dataForm.area = '';
			this.dataForm.address = '';
		  }
		},

      /**
       * 下一步
       */
      nextStep () {

		if(this.dataForm.invoicePaper == '1' && (this.dataForm.invoiceStatus == 0 || this.dataForm.invoiceStatus == 3)){
		  if(!this.dataForm.city || !this.dataForm.area || !this.dataForm.address){
			return
		  }
		}
		// //判斷付款人手機號格式
		// if(this.phoneError){
		// 	return
		// }
		// if(this.matchingLogistics && this.recipientPhoneErr){
		// 	return
		// }
		//手機條碼//捐贈
		if(this.dataForm.invoiceStatus == 2 || this.dataForm.invoiceStatus == 4){
		  this.dataForm.invoicePaper = '0';
		}
        if(this.dataForm.city != ''){
          this.dataForm.invoiceAddress = this.cityList.find(item => {return item.cityId == this.dataForm.city} ).cityName
        }
        if(this.dataForm.city != '' &&  this.dataForm.area != ''){
          this.dataForm.invoiceAddress = this.cityList.find(item => {return item.cityId == this.dataForm.city} ).cityName + this.areaList.find(item => {return item.areaId == this.dataForm.area} ).areaName + this.dataForm.address
        }
		// //發證時間
		// if(this.isExistIdentity){
		// 	if(this.selectedYear && this.selectedMonth && this.selectedDay){
		// 	  this.dataForm.issuanceDate = this.selectedYear + this.selectedMonth + this.selectedDay;
		// 	  this.issuanceDateIsEmpty = '-';
		// 	}else{
		// 	  this.issuanceDateIsEmpty = '';
		// 	  return
		// 	}

		// }
        //發證時間
        if (this.isExistIdentity && this.verificationType == 1) {
          if (this.selectedYear && this.selectedMonth && this.selectedDay) {
            this.dataForm.issuanceDate = this.selectedYear + this.selectedMonth + this.selectedDay;
            this.issuanceDateIsEmpty = '-';
          } else {
            this.issuanceDateIsEmpty = '';
            return
          }
        }
        this.dataForm.smsVerificationCode = this.dataForm.verificationCode;
        this.dataForm.cardholderCellphoneNumber = this.dataForm.cellphoneNumber;

        this.$refs['dataForm'].validate((valid) => {
          if(valid){
            this.checkedPaymentMethod();
          }
        })

      },
      checkedPaymentMethod(){
        this.loading = true
        this.mainLoading= true
        this.$http({
            url: this.$http.adornUrl('/quickPay/quickPayOrder/checkPaymentMethodStatus'),
			// url:'http://192.168.18.53:8090/cashier-service/quickPay/quickPayOrder/checkPaymentMethodStatus',
            method: 'get',
            params: this.$http.adornParams({
                quickPayUrl: this.secondCode,
                modeCode: this.payMethod,
				logisticsModeCode:'',
            })
        }).then(({data}) => {
          if (data && data.resultCode == 200) {
			      this.checkedAmount();
          } else {
            this.$message.error(data.resultMessage);
            this.loading = false
            this.mainLoading= false
          }
        })
      },
      checkedAmount(){
        this.$http({
            url: this.$http.adornUrl('/quickPay/quickPayOrder/checkAmount'),
			// url:'http://192.168.18.53:8090/cashier-service/quickPay/quickPayOrder/checkAmount',
            method: 'get',
            params: this.$http.adornParams({
                quickPayUrl: this.secondCode,  //第二段url
                modeCode: this.payMethod,
                amount: this.dataForm.paymentAmount,
                periodFlag: '1'  //交易來源標識 [空或0 網址付] [1定期定額]
            })
        }).then(({data}) => {
          if (data && data.resultCode == 200) {
            this.openConfirmDialog(this.dataForm)
            // this.loading = false
            // this.mainLoading= false
          } else {
            this.$message.error(data.resultMessage);
            this.loading = false
            this.mainLoading= false
          }
        })
      },
      /**
       * 填寫驗證碼
       */
      openConfirmDialog (row) {
		console.log(row)
		debugger
        this.$nextTick(() => {
          this.$refs['verCodeDialog'].init(row)
        })
      },
      dialogClosed(){
        console.log('dialogClosed')
        this.loading = false
        this.mainLoading= false
      },
      goPay (form) {
          console.log(form)
		  this.dataForm.masterId = form.masterId;
          this.submitForm();
      },
      submitForm () {
		let formData = this.global.formatObj(this.dataForm);
        if (!this.global.isEmpty(formData.creditCardNumber)){
          formData.creditCardNumber = formData.creditCardNumber.replace(/\s*/g, '').replace(/-/g, '')
        }
		if(formData.city != ''){
		   formData.invoiceAddress = this.cityList.find(item => {return item.cityId == formData.city} ).cityName
		}
		if(formData.city != '' &&  this.dataForm.area != ''){
		   formData.invoiceAddress = this.cityList.find(item => {return item.cityId == formData.city} ).cityName + this.areaList.find(item => {return item.areaId == formData.area} ).areaName + formData.address
		}

		formData.creditCardValidFrom = formData.effectiveDate.substr(3,5) + formData.effectiveDate.substr(0,2);
        console.log(formData)
        debugger

        this.$http({
          url: this.$http.adornUrl(`fixedTermAmountMaster/periodicPaymentConfirm`),    //信用卡--驗證碼接口
		  // url: `http://192.168.18.53:8090/cashier-service/fixedTermAmountMaster/periodicPaymentConfirm`,
          method: 'post',
          data: this.$http.adornData(formData)
        }).then(({data}) => {
          this.verificationCodeErr = false;
          this.verificationIDErr = false;
          this.verificationIDThreeErr = false;
          if (data && data.resultCode == 200) {
            console.log(data.body)
            if (data.body) {
              //3D授權
              let newWin = window.open('', '_self')
              newWin.document.write(data.body)
              newWin.document.close()
            } else {
              //非3D授權
              if (this.dataForm.successAllPath) {
                let newWin = window.open('', '_self')
                newWin.document.write(this.dataForm.successAllPath)
                newWin.document.close()
              } else {
                this.gotoSelfPaymentResult(1)
              }
            }
          } else if( data.resultCode == 98 ){ //驗證碼錯誤1(或2)次
            this.$message.error(data.resultMessage);
            this.errCount++;
            this.verificationCodeErr = true;
            this.loading = false
            this.mainLoading= false
          } else if( data.resultCode == 97 ){  //驗證碼錯誤3次，交易失敗
            this.$message.error(data.resultMessage);
            this.gotoSelfPaymentResult(0)
            this.loading = false
            this.mainLoading= false
          } else if( data.resultCode == 96 ){  //身分驗證失敗，請重新確認
            this.$message.error(data.resultMessage);
            this.verificationIDErr = true;
            this.loading = false
            this.mainLoading= false
          } else if(data.resultCode == 95){ // 身分證末三碼錯誤
            this.$message.error(data.resultMessage);
            this.verificationIDThreeErr = true;
            this.loading = false
            this.mainLoading= false
          } else {
            this.loading = false
            this.mainLoading= false
            if ('驗證碼錯誤，請重新輸入' == data.resultMessage) {
              this.$message.error(data.resultMessage)
            } else {
              if (this.dataForm.failAllPath) {
                let newWin = window.open('', '_self')
                newWin.document.write(this.dataForm.failAllPath)
                newWin.document.close()
              } else {
                this.gotoSelfPaymentResult(0)
              }
            }
          }
        })
      },
      gotoSelfPaymentResult (errorFlag) {
        if (errorFlag == 1) {  //信用卡-支付成功頁面
			this.$router.push({
				name: 'super-quato-info',
				query: {
				  masterId: this.dataForm.masterId,
				  transactionAmount: this.dataForm.paymentAmount,
				  // orderInfo: this.dataForm.orderInfo,
				  uuid: this.dataForm.uuid
				}
			  })
        } else {
          this.$router.push({name: 'error',query: {id:this.dataForm.masterId}})
        }
      },
      effectiveDateCheck(val) {
        let jss =  val.replace(
            /[^0-9]/g, '' // To allow only numbers
        ).replace(
            /^([2-9])$/g, '0$1' // To handle 3 > 03
        ).replace(
            /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
        ).replace(
            /^0{1,}/g, '0' // To handle 00 > 0
        ).replace(
            /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
        );

        this.dataForm.effectiveDate = jss
        console.log()

        this.EffectiveDateErMsg = ''

        if(val.length < 5) {
          console.log(val.length)
          this.validateEffectiveDate = (rule, value, callback) => {
            this.EffectiveDateErMsg = val.length > 0 ? '請輸入正確有效年月' : '必填欄位不得為空白 Field required'
            callback(new Error())
          }
        } else {
          if(val[0]+val[1] > 12){
            this.validateEffectiveDate = (rule, value, callback) => {
              this.EffectiveDateErMsg = '請輸入正確有效年月'
              callback(new Error())
            }
          } else {
            this.validateEffectiveDate = (rule, value, callback) => {
              callback()
            }
          }
        }
      },
      creditCardNumberCheck(val) {
        this.creditCardNumberMsg = '必填欄位不得為空白 Field required'
        if(val.indexOf(" ") == -1 && val.length == 16){
          this.validateCreditCardNumber = (rule, value, callback) => {
            callback()
          }
          return
        }
        if(val.length != 19){
          this.validateCreditCardNumber = (rule, value, callback) => {
            if(val.length == 0) {
              this.creditCardNumberMsg = '必填欄位不得為空白 Field required'
            } else {
              this.creditCardNumberMsg = '卡號輸入不完整'
            }
            callback(new Error())
          }
        } else {
          this.validateCreditCardNumber = (rule, value, callback) => {
            callback()
          }
        }
      },
      //縣市名稱
      getCityCodeName() {
        this.$http({
          url: this.$http.adornUrl(`/order/getCityName`),
          method: 'get'
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.cityList = data.body;
          } else {
            this.cityList = [];
          }
        }).catch((e) => {
        })
      },
      //獲取縣市名稱下面區域名
      choseCity(cityId){
        if(cityId == ''){
          return
        }
        this.$http({
          url: this.$http.adornUrl('/order/getAreaName/'+cityId),
          method: 'get'
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.areaList = data.body;
          } else {
            this.areaList = [];
          }
        }).catch((e) => {
        })
      },
      //清空城市區域下來選
      clearSelect(form, model){
        this[form][model] = ""
        this.dataForm.city = ''
        this.areaList = []
      },
      //發票radio改變
      changeInvoiceStatus(val){
		//自然人憑證條碼
        this.dataForm.carrierIdPerson = '';
        //手機條碼
        this.dataForm.carrierIdMobile = '';
		//統一編號
        this.dataForm.identifier = '';
		//捐贈碼
        this.dataForm.poban = '';
		this.dataForm.invoiceHead = '';
      },
        // 獲取捐贈號碼數據列表
        getPobanDataList () {
            this.$http({
                url: this.$http.adornUrl('/sys/donateinvoice/getList'),
                method: 'get',
                params: this.$http.adornParams()
            }).then(({data}) => {
                if (data && data.resultCode === 200) {
                    if(data.body){
                        this.pobanList = data.body;
                    }else{
                        this.pobanList = [];
                    }
                }
            })
        },
      icCardFrontSuccess(response, file, fileList){
        this.dataForm.idCardPhotoFrontResourceId = response.body.resourceId;
        this.global.getBlob(response.body.resourceId, (result) => {
          this.idCard1 = result;
        })
      },
      icCardBackSuccess(response, file, fileList){
        this.dataForm.idCardPhotoBackResourceId = response.body.resourceId;
        this.global.getBlob(response.body.resourceId, (result) => {
          this.idCard2 = result;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/payment.scss";

  .card-form-line.el-divider.el-divider--horizontal {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
  }
  ::v-deep .el-input{
  	  .el-input-group__prepend{
  	  	border:0px;
  		color:#525252;
  		font-size: 16px;
  		font-weight: 400;
  	  }
  }

  ::v-deep .el-form--label-top {
    .el-form-item {
      margin-top: 10px !important;
      .el-input__inner{
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #525252;
        background-color: #EDEDED;
        border-color: #EDEDED;
      }
      .el-textarea__inner{
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #525252;
        background-color: #EDEDED;
        border-color: #EDEDED;
      }

      &__label {
        line-height: 23.12px !important;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.9px;
        padding: 0 0 5px !important;
        color: #525252 !important;

      }

      .el-form-item__error {
        color: #FF0000 !important
      }
    }
  }

  ::v-deep .ol-el-input {
    .el-input__inner {
      text-align: center;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }
    .el-textarea__inner{
      text-align: center;
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }
  }

  ::v-deep .le-el-input {
    .el-input__inner {
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }
    .el-textarea__inner{
      font-size: 16px !important;
      font-weight: 400 !important;
      color: #525252;
      background-color: #EDEDED;
      border-color: #EDEDED;
    }
  }



  ::v-deep .right-image-input {
    .el-input__inner {
      padding-right: 60px;
    }

  }

  ::v-deep .selet-drop {
    .el-input__suffix{
      display: none;
    }
    .el-select-dropdown__item.selected {
      color: #78B689 !important;
      font-weight: 700;
    }
  }

  ::v-deep .el-cascader .el-input .el-icon-arrow-down {
    display: none;
  }

  .right-image {
    width: 38px;
    position: absolute;
    right: 13px;
    top: 6px;
  }

  .order-txt {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .pay-rad-txt {
    font-weight: 600;
    color: #2A2A2A;
    font-size: 16px;
    line-height: 42px;
  }

  .box-txt-bl-s {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 20px;
  }

  .box-txt-bl-b {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 25px;
  }

  .box-txt-rd {
    font-weight: 600;
    color: #8F0304;
    font-size: 16px;
    line-height: 20px;
  }

  .error-msg {
    display: flex;
    flex-direction: row;
    line-height: 20px;
    position: absolute;
    .img {
      width: 16px;
      height: 16px;
      margin-top: 3px;
    }
    .txt {
      margin-left: 5px;
      color: #FF0000;
      font-size: 12px;
      font-weight: 400;
      width: 100%;
    }
  }

  .pay-form-radio ::v-deep .el-radio__inner {
    border: 2px solid #949494;
    width: 15px;
    height: 15px;
  }
  .pay-form-radio ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #1DB145 !important;
    border: 2px solid #1DB145 !important;
    background: #ffffff !important;
  }
  .pay-form-radio ::v-deep .el-radio__inner::after {
    width: 6px;
    height: 6px;
    background-color:#1DB145 !important;
  }

  .pay-form-radio ::v-deep .el-radio__input {
    color: #2A2A2A;
  }
  .pay-form-radio ::v-deep .el-radio__label {
    color: #2A2A2A;
    font-size: 20px !important;
    font-weight: bold;
  }
  .pay-form-radio ::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #2A2A2A;
    font-size: 20px !important;
    font-weight: bold;
  }
  .pay-form-check ::v-deep .el-checkbox {
    color: #2A2A2A !important;
  }
  .pay-form-check ::v-deep .el-checkbox__label {
    font-size: 20px !important;
	font-weight: bold;
  }
  .pay-form-check ::v-deep .el-checkbox__inner {
    border: 1px solid #606060 !important;
    width: 16px !important;
    height: 16px !important;
  }
  .pay-form-check ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #606060 !important;
  }
  .pay-form-check ::v-deep .el-checkbox__inner:hover {
    // border: 1px solid #606060;
    border-color: #606060 !important;
  }
  .pay-form-check ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #2A2A2A;
    font-size: 20px !important;
  }
  .pay-form-check ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1DB145;
    border-color: #1DB145;
  }
  .pay-form-check ::v-deep .el-checkbox__inner::after {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    content: "";
    border: 2px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 5px;
    position: absolute;
    top: 1px;
    width: 3px;
    -webkit-transition: -webkit-transform .15s ease-in .05s;
    transition: -webkit-transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s,-webkit-transform .15s ease-in .05s;
    -webkit-transform-origin: center;
    transform-origin: center;
  }

  .information-form-check ::v-deep .el-checkbox {
    color: #8F0304 !important;
  }
  .information-form-check ::v-deep .el-checkbox__label {
    font-size: 16px !important;
  }
  .information-form-check ::v-deep .el-checkbox__inner {
    border: 1px solid #8F0304 !important;
    width: 16px !important;
    height: 16px !important;
  }
  .information-form-check ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #8F0304 !important;
  }
  .information-form-check ::v-deep .el-checkbox__inner:hover {
    // border: 1px solid #606060;
    border-color: #8F0304 !important;
  }
  .information-form-check ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #8F0304;
    font-size: 16px !important;
  }
  .information-form-check ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #8F0304;
    border-color: #8F0304;
  }
  .information-form-check ::v-deep .el-checkbox__inner::after {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    content: "";
    border: 2px solid #FFF;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 5px;
    position: absolute;
    top: 1px;
    width: 3px;
    -webkit-transition: -webkit-transform .15s ease-in .05s;
    transition: -webkit-transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s, -webkit-transform .15s ease-in .05s;
    transition: transform .15s ease-in .05s,-webkit-transform .15s ease-in .05s;
    -webkit-transform-origin: center;
    transform-origin: center;
  }

  .next-btn {
    line-height: 25px;
    background: #8F0304;
    font-weight: 600;
    font-size: 26px;
    height: 64px;
    width: 320px;
  }
   .next-btn-dis {
    line-height: 25px;
    background: #a6a6a6;
    border: #a6a6a6;
    font-weight: 600;
    font-size: 26px;
    height: 64px;
    width: 320px;
  }
  .next-btn-dis ::v-deep .el-button--danger.is-disabled, .el-button--danger.is-disabled:active, .el-button--danger.is-disabled:focus, .el-button--danger.is-disabled:hover {
      color: #FFF;
      background-color: #a6a6a6 !important;
      border-color: #a6a6a6 !important;
  }

  .box-payForm-card {
    margin-left: -15px;
    .txt {
      font-size: 8px;
      font-weight: 400;
      color: #525252;
      width: 200px;
      margin-left: -5px;
      margin-top: 10px
    }
    .img {
      width: 200px;
      margin-left: -5px
    }
  }
  .box-payForm-card ::v-deep .el-checkbox {
    color: #525252 !important;
  }
  .box-payForm-card ::v-deep .el-checkbox__inner {
    border: 1px solid #606060 !important;
  }
  .box-payForm-card ::v-deep .el-checkbox__input {
    margin-top: -45px;
  }
  .box-payForm-card ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #606060 !important;
  }
  .box-payForm-card ::v-deep .el-checkbox__inner:hover {
    // border: 1px solid #606060;
    border-color: #606060 !important;
  }
  .box-payForm-card ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #525252;
  }
  .box-payForm-card ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #606060;
    border-color: #606060;
  }

  .g-b {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  .w-b {
    //padding: 0 140px !important;
    border-radius: 38px  38px 0 0;
  }
  .top-logo {
    width: 100%;
    //height: 250px;
    //margin-top: -30px;
    text-align: center;
  }
  .payment-valid-until {
    display: inline-block;
    vertical-align: middle;
    color: #9D2323;
    font-size: 16px;
    font-weight: 600;
  }
  .clock-img {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin: 1px 5px;
  }
  .tb-txt {
    display: inline-block;
    vertical-align: middle;
    color: #8F918E;
    font-size: 16px;
    font-weight: 600;
  }
  .or-information {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .title-24 {
      font-size: 24px;
      font-weight: 600;
      color: #03328D;
    }
    .title-16 {
      font-size: 16px;
      font-weight: 600;
      color: #03328D;
      line-height: 42px;
      margin-left: 10px;
    }
    .bk-img {
      width: 252px;
      height: 24px;
      margin-top: 16px;
      margin-left: 12px;
      position: absolute;
    }
    .mer-name-l{
      color: #8F918E;
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
      vertical-align: middle;
      width: 210px;
      margin-top: 20px;
    }
    .mer-name-span{
      color: #ff1400;
      font-size: 16px;
      font-weight: 400;
      display: inline-block;
      vertical-align: middle;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    .mer-name-r{
      color: #8F918E;
      font-size: 16px;
      font-weight: 400;
      margin-left: -10px;
      display: inline-block;
      vertical-align: middle;
      margin-top: 20px;
      width: calc(100% - 210px)
    }
    .order-name {
      color: #2A2A2A;
      font-weight: 600;
      font-size: 26px;
      margin-top: 20px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      // width: calc(100% - 200px);
      width: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    .order-amount-l {
      color: #8F918E;
      font-weight: 400;
      font-size: 16px;
      padding-top: 18px;
      border-top: 2px dashed #E3E3E3;
      width: 83%;
    }
    .order-amount-r {
      font-weight: 600
    }
    .pay-amount {
      color: #9D2323;
      font-weight: 600;
      font-size: 24px;
      margin-top: 10px;
    }
	.quota-info{
	  color: #8F918E;
	  font-weight: 600;
	  font-size: 16px;
	  margin-bottom: 10px;
	}
	.quota-amount-title{
	  color: #8F918E;
	  font-weight: 600;
	  font-size: 24px;
	  padding-left: 50px;
	}
	.quota-amount-a{
	  color: #9D2323;
	  font-weight: 800;
	  font-size: 30px;
	  margin-top: 23px;
	  display: flex;
	  justify-content: center;
	}
	.quota-amount-b{
	  margin-top: 30px;
	  display: flex;
	  justify-content: center;

	}
  }
  ::v-deep.quota-style{
    margin-top: 20px;
	// display: flex;
	// justify-content: center;
	border: 2px solid #e0e5ff;
	border-radius: 10px;
	width: 90%;

      .el-form-item {
        margin-bottom: 0px;
		margin-top: 0px !important;
		display: flex;
		align-items: center;
		&__label {
			color: #241d53 !important;
			font-weight: 700;
		}
      }
  }

  .pay-method {
    display: flex;
    flex-direction: column;
	margin-top: 20px;
    .tit-area {
      display: flex;
    }
    .tit-cn {
      font-size: 24px;
      font-weight: 600;
      color: #03328D;
    }
    .tit-en {
      font-size: 16px;
      font-weight: 600;
      color: #03328D;
      line-height: 42px;
      margin-left: 10px;
    }
    .tit-bk {
      width: 292px;
      height: 24px;
      margin-top: 16px;
      margin-left: 12px;
      position: absolute;
    }
    .cre-card-img-1 {
      width: 18px;
      height: 18px;
      margin-left: 5px;
      top: 3px;
    }

    .cre-card-img-2 {
      width: 26px;
      height: 25px;
      margin-left: 1px;
      top: 7px;
    }
    .cre-card-img-3 {
      width: 18px;
      height: 18px;
      margin-left: 3px;
      top: 3px;
    }
    .gg-img {
      width: 50px;
      height: 19px;
      margin-left: 3px;
      top: 5px;
    }
    .ap-img {
      width: 50px;
      height: 19px;
      margin-left: 3px;
      top: 5px;
    }
    .su-img {
      width: 50px;
      height: 22px;
      margin-left: 3px;
      top: 6px;
    }
    .un-card {
      width: 18px;
      height: 18px;
      margin-left: 3px;
      top: 3px;
    }
    .piiluu {
      width: 18px;
      height: 18px;
      margin-left: 3px;
      top: 3px;
    }
    .n-card {
      width: 140px;
      height: 18px;
      margin-left: 3px;
      top: 3px;
    }
  }
  .pay-information {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .tit-area {
      display: flex
    }
    .tit-cn {
      font-size: 24px;
      font-weight: 600;
      color: #03328D;
    }
    .tit-en {
      font-size: 16px;
      font-weight: 600;
      color: #03328D;
      line-height: 42px;
      margin-left: 10px;
    }
    .tit-bg {
      width: 275px;
      height: 24px;
      margin-top: 16px;
      margin-left: 12px;
      position: absolute;
    }
  }

  @font-face{
    font-family: 'myFont';
    src: url('../assets/font/jost-black.ttf');
  }

  .self-font {
    font-family: 'myFont', serif;
    font-size: 16px;
  }

  .tit-no {
    font-size: 16px;
    font-weight: 600;
    color: #C0C0C0;
    margin-top: 10px;
    position: absolute;
    right: 49%;
  }
  @media(max-width: 990px){
    .tit-no{
      position: relative;
      right: 0;
      text-align: center;
    }
  }



  ::v-deep .el-radio__label {
    font-size: 14px !important;
}

.paymentMethod{
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.order-txt-center{
  width: calc(100% - 80px);
}
@media(max-width: 767px){
  .paymentMethod{
    display: block;
    width: 100%;
  }
  .or-information .order-name{
    width: calc(100% - 100px) !important;
  }
  .order-txt-center{
    width: calc(100% - 40px);
  }
}
.paymentFormItem0{
  display: inline-block;
  vertical-align: top;
  background-color: #f1f3ff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  width: 40%;
}
.paymentFormItem1{
  display: inline-block;
  vertical-align: top;
  // border: 2px solid #e0e5ff;
  // border-bottom-right-radius: 10px;
  // border-top-right-radius: 10px;
  padding: 10px;
  align-content: center;
  width: 50%;
}
.paymentFormItem{
  display: inline-block;
  vertical-align: top;
  width: 48%;
}
.paymentFormItem2 {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin-top: 12px;
  }
.paymentFormItem ~ .paymentFormItem{
  margin-left: 2%;
}
@media(max-width: 991px){
	.quota-style{
	  margin-top: 20px;
		// display: flex;
		// justify-content: center;
		border: 2px solid #e0e5ff;
		border-radius: 10px;
		width: 100%;

	    .el-form-item {
	      margin-bottom: 0px;
		  margin-top: 0px !important;
			display: flex;
			align-items: center;
			&__label {
				color: #241d53 !important;
				font-weight: 700;
			}
	    }
	}

  .paymentFormItem{
    display: block;
    width: 100%;
  }
  .paymentFormItem0{
    display: block;
    width: 100%;
  }
  .paymentFormItem1{
	  display: block;
	  width: 100%;
  }
  .paymentFormItem2{
    display: block;
    width: 100%;
  }
  .paymentFormItem ~ .paymentFormItem{
    margin-left: 0;
  }
}
.information-form-check ::v-deep .box-txt-bl-b{
      white-space: break-spaces;
      vertical-align: top;
}
.information-form-check ::v-deep .el-checkbox__input{
  vertical-align: top;
  margin-top: 4px;
}
.invoiceItem{
  display: inline-block;
  vertical-align: top;
  width: 48%;
}

.slide-box{
  width: 80%;
  margin: 30px auto;
  text-align: center;
}
.slide-box ::v-deep .el-carousel{
    &__button{
      height: 16px;
      width: 16px;
      border-radius: 10px;
      background-color: #999999;
  }
  &__indicator.is-active{
    .el-carousel__button{
        background-color: #9D2323;
     }
  }
  &__indicator--horizontal{
    padding: 20px 4px 12px 4px;
  }
  &__arrow{
    display: none !important;
  }
  &__item{
      background-color: #dddddd;
  }
}

.order-number-box{
    padding-bottom: 18px;

}
.order-number-title{
    font-size: 16px;
    color: #8F918E;
    margin-top: 10px;
    & .redText{
        color: #FF0000;
    }
}
::v-deep .order-number-input{
    display: inline-block;
    vertical-align: middle;
    width: 180px;
    height: 40px;
    background-color: #EDEDED;
    border-radius: 8px;
    padding: 0px 48px;
    box-sizing: border-box;
    position: relative;
    margin-top: 3px;
    & input{
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        background: transparent;
    }
    .el-input .el-input__inner{
        padding: 0;
        border: 0;
        background: transparent;
        line-height: 40px;
        height: 40px !important;
        text-align: center;
    }
}
.num-sub{
    position: absolute;
    width: 24px;
    height: 24px;
    left: 12px;
    top: 8px;
}
.num-add{
    position: absolute;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 8px;
}
.inventory-text{
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    color: #C0C0C0;
    margin-left: 20px;
    margin-top: 3px;

}
.goods-desc{
    color: #525252;
    font-size: 16px;
    height: 36px;
    overflow: hidden;
}
::v-deep .priceInput{
    display: inline-block;
    width: 200px;

    input{
        font-size: 16px !important;
        font-weight: 400 !important;
        color: #525252;
        background-color: #EDEDED;
        border-color: #EDEDED;
    }
}
.identity-tips {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin: 20px 0px;
  line-height: 25px;
}
.identity-title {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 25px;
}
.identity-title2{
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 25px;
}
</style>
