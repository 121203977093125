<template>
  <!--超商條碼繳費-->
  <div class="payment grey-bg" style="padding-top: 1px;" ref="content" v-loading="mainLoading">
    <div class="container box" style="position: relative; z-index: 9;">
      <el-row class="txt-cen">
        <el-col>
          <el-image class="top-logo" :src="successIconImgUrl"></el-image>
        </el-col>
      </el-row>
      <el-row class="m-top-20 txt-cen">
        <el-col>
          <span class="top-txt">訂單開立成功</span>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-15">
        <el-col>
          <span class="top-txt">Order Success!</span>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-25">
        <el-col>
          <span class="transactionAmount">NT <span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></span>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-20">
        <el-col>
          <el-image class="line" :src="lineImgUrl"></el-image>
        </el-col>
      </el-row>
      <el-row class="txt-cen m-top-20">
        <el-col>
          <el-image style="width: 200px;" :src="storLogoImgUrl"></el-image>
        </el-col>
      </el-row>
      <el-row class="m-top-20 txt-cen">
        <el-col>
          <span class="left-txt-r">超商繳費條碼 Barcode </span>
        </el-col>
      </el-row>
      <el-row class="txt-cen">
        <el-col>
          <barcode class="marCode" v-if="dataForm.marketBarCodeOne !== null && dataForm.marketBarCodeOne !== ''" :value="dataForm.marketBarCodeOne" :options="option"></barcode>
        </el-col>
      </el-row>
      <el-row class="txt-cen">
        <el-col>
          <barcode class="marCode" v-if="dataForm.marketBarCodeTwo !== null && dataForm.marketBarCodeTwo !== ''" :value="dataForm.marketBarCodeTwo" :options="option"></barcode>
        </el-col>
      </el-row>
      <el-row class="txt-cen">
        <el-col>
          <barcode class="marCode" v-if="dataForm.marketBarCodeThree !== null && dataForm.marketBarCodeThree !== ''" :value="dataForm.marketBarCodeThree" :options="option"></barcode>
        </el-col>
      </el-row>
      <div class="account-box">
        <div class="account-title">
            繳款截止時間<span>Expiry Time</span>
        </div>
        <div class="account-value" style="color: #121212">{{dataForm.createOrderTime}}</div>
      </div>
      <el-row class="txt-cen m-top-20">
        <el-col>
          <el-image class="line" :src="lineImgUrl"></el-image>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20">
        <el-col :span="12">
          <span class="bot-txt-g">特店名稱 Merchant Name</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.merchantName}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" >
        <el-col :span="12">
          <span class="bot-txt-g">紅陽交易編號 Sunpay Transaction No.</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.orderId}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" >
        <el-col :span="12">
          <span class="bot-txt-g">特店訂單編號 Merchant Order No.</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.merchantOrderId}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" >
        <el-col :span="12">
          <span class="bot-txt-g">交易時間 Payment Time</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.paymentDeadLine}}</span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20">
        <el-col :span="12">
          <span class="bot-txt-g">交易金額 Payment Amount</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">NT<span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></span>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" class="m-top-20" v-if = 'dataForm.storeAddress'>
        <el-col :span="12">
          <span class="bot-txt-g">取貨門市地址 Store Address</span>
        </el-col>
        <el-col :span="12" class="txt-end">
          <span class="bot-txt-b">{{dataForm.storeAddress}}</span>
        </el-col>
      </el-row>
<!--	  <el-row type="flex" justify="space-between" class="m-top-20" >-->
<!--	    <el-col :span="12">-->
<!--	      <span class="bot-txt-g">寄件代碼 Shipping code</span>-->
<!--	    </el-col>-->
<!--	    <el-col :span="12" class="txt-end">-->
<!--	      <span class="bot-txt-b">{{dataForm.shippingCode}}</span>-->
<!--	    </el-col>-->
<!--	  </el-row>-->

      <el-row class="m-top-20 txt-cen">
        <el-col>
          <el-image class="line" :src="sLineImgUrl"></el-image>
        </el-col>
      </el-row>
      <!-- 服務條款 -->
        <div class="notice">
          <div class="box-txt-bl-s">注意事項 Notice</div>
          <div class="box-txt-bl-s">一、請紀錄您的繳費資訊，並於繳費期限內完成繳費，超過繳費時間該繳費條碼將會失效</div>
          <div class="box-txt-bl-w">Please remember your payment information and complete the payment within the expiry time, otherwise, the barcode will become invalid.</div>
          <div class="box-txt-bl-s">二、您可持條碼繳費單至四大超商櫃檯以現金繳費</div>
          <div class="box-txt-bl-w">You can print this payment slip and complete the payment at the cashier of the convenience store(7-ELEVEN/FamilyMart/OK Mart/Hi-Life)by cash.</div>
          <div class="box-txt-bl-s">三、您完成繳費後，商店約需3~4個工作天確認繳費成功</div>
          <div class="box-txt-bl-w">It takes about 3~4 working days for the merchant to confirm the payment after completed the payment.</div>
          <div class="box-txt-rd">請主動求證付款資訊來源，切勿將個人資料提供給來路不明的第三方，若發現有不明管道之訊息可撥打165反詐騙諮詢專線。</div>
          <div class="box-txt-rd-w">Please proactively verify the source of payment information and refrain from providing personal information to unknown third parties. If you suspect you could be victim of a scam, protect your rights by calling the “165 Anti-Scam hotline”.</div>
        </div>
        <div class="txt-cen">
          <el-button type="danger"  class="next-btn" @click="showDoc">
            <template>
              <!-- <el-image class="img" :src="showDoc"></el-image> -->
              <el-image class="img" :src="imageDownImgUrl"></el-image>
              <span class="txt" >下載繳款單 Payment Slip</span>
            </template>
          </el-button>
        </div>

        <!-- <div class="back-btn" @click="goPage()" style="width: 140px; margin: 20px auto;">回到商店</div> -->

        <el-row class="m-top-40 txt-cen">
          <el-col>
            <el-image style="width: 200px;" :src="sLogImgUrl"></el-image>
          </el-col>
        </el-row>

      <div v-if="false">
        <div class="show-body-inner">
          <div class="show-body-title">超商條碼繳費</div>
          <div class="show-body-amount">交易金額(NT$)/Amount：<span v-format="'$#,##0'">{{dataForm.transactionAmount}}</span></div>
          <div class="show-body-business">商店名稱/Business Name：{{dataForm.merchantName}}</div>
        </div>
        <el-divider class="line"></el-divider>
        <div class="show-body-inner body-sec">
          <div class="show-body-bottom mb-2">繳款期限/Due Date：{{dataForm.createOrderTime}}</div>
  <!--        <div class="show-body-bottom">交易內容/Trading Details：{{dataForm.orderInfo}}</div>-->
        </div>
        <div class="show-body-inner show-body-btn mb-6">
          <!--下載-->
          <el-button type="info" @click="showDoc">下載繳款單/Payment Slip</el-button>
          <!--跳轉-->
          <el-button type="info" @click="showBarCode">顯示繳款條碼</el-button>
        </div>
        <div class="show-body-inner mb-8">
          <img :src="bottomIcon1" alt="" class="show-body-img mr-2">
          <img :src="bottomIcon2" alt="" class="show-body-img mr-2">
          <img :src="bottomIcon3" alt="" class="show-body-img mr-2">
          <img :src="bottomIcon4" alt="" class="show-body-img">
        </div>
        <div class="show-body-inner">
          <div class="show-body-back" @click="goPage()" v-if="!global.isEmpty(webSiteUrl)">回到商店</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import storLogoImg from '../../assets/img/new-payment/store-logo.png'
import successIconImg from '../../assets/img/new-payment/success-icon.png'
import lineImg from '../../assets/img/new-payment/line.png'
import sLogImg from '../../assets/img/new-payment/s-logo.png'
import imageDownImg from '../../assets/img/new-payment/image-down.png'
import sLineImg from '../../assets/img/new-payment/s-line.png'
import barcode from '@xkeshi/vue-barcode'

  export default {
    name: 'super-bar-code-info',
    components: {
      barcode
    },
    data () {
      return {
        mainLoading: false,
        titleIcon: require('../../assets/img/payment/pay-icon-3.png'),
        bottomIcon1: require('../../assets/img/info/info-icon-1.png'),
        bottomIcon2: require('../../assets/img/info/info-icon-2.png'),
        bottomIcon3: require('../../assets/img/info/info-icon-3.png'),
        bottomIcon4: require('../../assets/img/info/info-icon-4.png'),
        dataForm: {
          // 交易金額
          transactionAmount: '',
          // 商店名稱
          merchantName: '',
          // 繳款期限
          createOrderTime: '',
          // 交易內容
          orderInfo: '',
          // 條形碼
          marketBarCodeOne: '',
          marketBarCodeTwo: '',
          marketBarCodeThree: '',
          postOfficeBarCodeOne: '',
          postOfficeBarCodeTwo: '',
          postOfficeBarCodeThree: '',
		  //取貨門市地址
		  storeAddress:'',
		  //寄件代碼
		  shippingCode:'',
        },
        webSiteUrl: '',
        storLogoImgUrl: storLogoImg,
        successIconImgUrl: successIconImg,
        lineImgUrl: lineImg,
        sLogImgUrl: sLogImg,
        imageDownImgUrl: imageDownImg,
        sLineImgUrl: sLineImg,
        option: {
          format: 'CODE39',
          displayValue: true, // 是否默认显示条形码数据
          background: '#ffffff', // 条形码背景颜色
          height: '50px',
          fontSize: '14px',
          lineColor: '#606266'
        },
      }
    },
    computed: {
      // 跳轉頁面
      routerName: {
        get () {
          return this.$store.state.credit.routerName
        }
      }
    },
    mounted () {
      this.showInfo()
      this.getWebSiteUrl();
    },
    methods: {
      showInfo () {
        this.mainLoading = true
        let newForm = {
          masterId: this.$route.query.masterId
        }
        this.$http({
          url: this.$http.adornUrl(`/order/getOrderByMarketBarCode`),
          method: 'post',
          data: this.$http.adornData(newForm)
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.dataForm.masterId = this.$route.query.masterId
            this.dataForm.transactionAmount = this.$route.query.transactionAmount
            this.dataForm.orderInfo = this.$route.query.orderInfo
            this.dataForm.merchantName = data.body.merchantName
            this.dataForm.createOrderTime = data.body.paymentDeadLine
            this.dataForm.merchantOrderId = data.body.merchantOrderId
            this.dataForm.orderId = data.body.orderId
            this.dataForm.paymentDeadLine = data.body.createOrderTime
            this.dataForm.marketBarCodeOne = data.body.marketBarCodeOne
            this.dataForm.marketBarCodeTwo = data.body.marketBarCodeTwo
            this.dataForm.marketBarCodeThree = data.body.marketBarCodeThree
            this.dataForm.postOfficeBarCodeOne = data.body.postOfficeBarCodeOne
            this.dataForm.postOfficeBarCodeTwo = data.body.postOfficeBarCodeTwo
            this.dataForm.postOfficeBarCodeThree = data.body.postOfficeBarCodeThree
			this.dataForm.storeAddress = data.body.storeAddress
			this.dataForm.shippingCode = data.body.shippingCode
          }
          this.mainLoading = false
        })
      },
      /**
       * 顯示條碼
       */
      showBarCode () {
        this.$router.push({
          name: 'show-super-bar-code-info',
          query: {
            // 條形碼
            marketBarCodeOne: this.dataForm.marketBarCodeOne,
            marketBarCodeTwo: this.dataForm.marketBarCodeTwo,
            marketBarCodeThree: this.dataForm.marketBarCodeThree,
            transactionAmount: this.$route.query.transactionAmount,
            merchantName: this.dataForm.merchantName,
            createOrderTime: this.dataForm.createOrderTime
          }
        })
      },
      showDoc () {
        this.$router.push({
          name: 'contribution-doc',
          query: {
            masterId: this.dataForm.masterId,
            // 條形碼
            marketBarCodeOne: this.dataForm.marketBarCodeOne,
            marketBarCodeTwo: this.dataForm.marketBarCodeTwo,
            marketBarCodeThree: this.dataForm.marketBarCodeThree,
            // 金額
            transactionAmount: this.$route.query.transactionAmount,
            // 商店名稱
            merchantName: this.dataForm.merchantName,
            // 繳款期限
            createOrderTime: this.dataForm.createOrderTime,
            // 郵局
            postOfficeBarCodeOne: this.dataForm.postOfficeBarCodeOne,
            postOfficeBarCodeTwo: this.dataForm.postOfficeBarCodeTwo,
            postOfficeBarCodeThree: this.dataForm.postOfficeBarCodeThree,
            orderInfo: this.dataForm.orderInfo,
            creditCardUserName: this.$route.query.creditCardUserName
          }
        })
      },
      getWebSiteUrl(){
        this.$http({
          url: this.$http.adornUrl(`/order/getReturnStoreUrl`),
          method: 'get',
          params: this.$http.adornParams({
            orderId: this.$route.query.masterId
          })
        }).then(({data}) => {
          if (data && data.resultCode === 200) {
            this.webSiteUrl = data.body.webSite
          }
        })
      },
      goPage (page) {
        window.location.href = this.webSiteUrl
      },
      captrue() {
        const ref = this.$refs.content
        html2canvas(ref, {
          backgroundColor: '#e8f4ff',
          useCORS: true
        }).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png')
          const creatDom = document.createElement('a')
          document.body.appendChild(creatDom)
          creatDom.href = dataURL
          creatDom.download = 'image'
          creatDom.click()
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/payment.scss";
  .show-body{
    text-align: left!important;
    &-inner{
      width: 700px;
      margin:0 auto;
    }
    &-title{
      font-size:32px;
      color: #8e0303;
      margin-bottom:32px;
    }
    &-amount{
      font-size:32px;
      color: #707070;
      margin-bottom:14px;
      font-weight: bold;
    }
    &-business{
      font-size: 20px;
      color: #707070;
    }
    &-bottom{
      font-size:21px;
      color: #030303;
      margin-bottom:31px;
    }
    &-pcode{
      font-size: 32px;
      color: #030303;
    }
    &-img-box{
      margin-bottom:63px;
    }
    &-img{
      width:40px;
      height:40px;
    }
    &-back{
      font-size: 23px;
      color: #8e0303;
      font-weight: bold;
      border-bottom:2px solid #8e0303;
      width: 97px;
      padding: 2px;
      text-align: center;
      cursor: pointer;
    }
    &-btn{
      .el-button{
        padding: 17px 20px!important;
      }
    }
    .line.el-divider--horizontal {
      margin: 23px 0  53px 0 !important;
    }
    .body-sec{
      margin-bottom:48.7px;
    }
  }

  .txt-end {
   text-align: end;
  }
  .back-btn {
    font-size: 26px;
    color: #8F0304;
    font-weight: bold;
    border-bottom: 2px solid #8F0304;
    padding: 2px;
    cursor: pointer;
    text-align: center;
  }

  .next-btn {
    background: #8F0304;
    width: 400px;
    max-width: 100%;
    .img {
      width: 30px;
      top: 5px;
    }

    .txt {
      font-size: 26px;
      font-weight: 600;
      line-height: 45px;
      margin-left: 10px;
    }
  }

  .box-txt-bl-s {
    font-weight: 600;
    font-size: 12px;
    color: #000000;
    line-height: 20px;
  }

  .box-txt-bl-w {
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 25px;
  }

  .box-txt-rd {
    font-weight: 600;
    color: #8F0304;
    font-size: 12px;
    line-height: 20px;
  }

  .box-txt-rd-w {
    font-weight: 400;
    color: #8F0304;
    font-size: 12px;
    line-height: 20px;
  }

  .bot-txt-g {
    font-weight: 400;
    font-size: 18px;
    color: #707070;
  }

  .bot-txt-b {
    font-weight: 400;
    font-size: 18px;
    color: #121212;
  }


  .m-top-15 {
    margin-top: 15px;
  }
  .m-top-20 {
    margin-top: 20px;
  }
  .m-top-25 {
    margin-top: 25px;
  }
  .m-top-40 {
    margin-top: 40px;
  }

  .txt-cen {
    text-align: center;
  }

  .left-txt-g {
    font-weight: 600;
    font-size: 26px;
    color: #707070;
  }

  .left-txt-r {
    font-weight: 600;
    font-size: 26px;
    color: #8F0304;
  }

  .left-txt-b {
    font-weight: 600;
    font-size: 26px;
    color: #121212;
  }

  .line {
    width: 600px;
    height: 2px;
    max-width: 100%;
  }

  .notice {
    border-radius:10px;
    background: #FAF0CA;
    margin: 30px 0;
    padding: 20px;
  }

  .transactionAmount {
    font-weight: 600;
    font-size: 36px;
  }
  .top-txt {
    font-weight: 400;
    font-size: 22px;
  }
  .top-logo {
    width: 100px;
  }
  .w-b {
    padding: 60px 140px !important;
    margin-top: 20px;
    border-radius: 38px;
  }
  .account-title{
      padding-left: 180px;
  }
  @media(max-width: 1200px){
      .account-title{
          width: 60%;
          padding-left: 100px;
      }
      .account-value{
          width: 40%;
      }
  }
  @media(max-width: 991px){
      .account-title{
          padding-left: 50px;
      }
  }

  @media(max-width: 767px){
      .account-title{
          width: 60%;
          font-size: 20px;
          padding-left: 0;
      }
      .account-title span{
          display: inline-block;
          margin-left: 10px;
      }
      .account-value{
          font-size: 20px;
          width: 40%;
      }
  }
  @media(max-width: 450px){
      .account-title{
          width: 170px;
          font-size: 20px;
          padding-left: 0;
      }
      .account-title span{
          display: inline-block;
          margin-left: 0px;
      }
      .account-value{
          font-size: 20px;
          width: calc(100% - 170px);
      }
  }
  .marCode{
      max-width: 100%;
  }
</style>
